<div class="challenge-row d-flex align-items-center shadow-sm rounded-3 px-4 w-100 overflow-hidden " #challengeRef [ngClass]="{'locked bg-opacity-75':challenge.locked,'bg-opacity-100':!challenge.locked}" [style]="'--color:'+challenge.color" (click)="goTo()">
    <div class="w-100" style="z-index: 10;">
        <div class="row lab-details align-items-center h-100">
            <div class="col-7">
                <div class="d-flex gap-2">
                    <div class="challenge-image d-flex justify-content-center align-items-center rounded-4 overflow-hidden py-1" >
                        <div class="img" [style]="'background-image:url('+challenge.imgs?.badge+')'"></div>
                        <ng-container *ngIf="challenge.locked">
                            <div class="locked-icon d-flex justify-content-center align-items-center rounded-circle w-50px h-50px">
                                <fa-icon class="text-inverse fa-2x" [icon]="['fas','lock']"></fa-icon>
                            </div>
                        </ng-container>
                    </div>
                    <div class="challenge-details d-flex align-items-center py-1 " >
                        <span class="challenge-title lh-sm fw-bold fs-15px fw-400" [ngClass]="{'text-gray-400':challenge.locked}" [innerHTML]="challenge.name | noSanitize"></span>
                    </div>
                </div>
            </div>
            <div class="col-1 challenge-level">
                <div class="d-flex align-items-center justify-content-center gap-1 h-100">
                    <fa-icon class="text-inverse text-opacity-70" [icon]="['fas','clock']" [ngClass]="{'text-gray-400':challenge.locked}"></fa-icon>
                    <span class="text-inverse text-opacity-70 fw-400"  [ngClass]="{'text-gray-400':challenge.locked}" [innerHTML]="">{{getHr(challenge.duration!)}}</span>
                </div>
            </div>
            <div class="col-2 challenge-level">
                <div class="d-flex align-items-center justify-content-center gap-1 h-100">
                    <span class="text-inverse text-opacity-70 fw-400"  [ngClass]="{'text-gray-400':challenge.locked}" [innerHTML]="challenge.difficulty | noSanitize"></span>
                </div>
            </div>
            <div class="col-2">
                <div class="d-flex justify-content-center align-items-center gap-1 h-100" >
                    <ng-container *ngIf="!challenge.locked">
                        <ng-container *ngIf="!challenge.approved">
                            <ng-container *ngIf="challenge.statusSession">
                                <ng-container *ngIf="challenge.statusSession == 'In Review'">
                                    <div class="d-flex align-items-center gap-1">
                                        <fa-icon class="text-warning text-opacity-80" [icon]="['fas','spinner']"></fa-icon>
                                        <span class="text-opacity-70 text-warning fw-400">Em Revisão</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="challenge.statusSession == 'Awaiting Evaluation'">
                                    <div class="d-flex align-items-center gap-1">
                                        <fa-icon class="text-danger text-opacity-80" [icon]="['fas','pause']"></fa-icon>
                                        <span class="text-opacity-70 text-danger fw-400">Aguardando Revisão</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="challenge.statusSession == 'Initialing'">
                                    <div class="d-flex align-items-center gap-1">
                                        <fa-icon class="text-theme text-opacity-80" [icon]="['fas','spinner']"></fa-icon>
                                        <span class="text-opacity-70 text-theme fw-400">Iniciando</span>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!challenge.statusSession">
                                    <div class="d-flex align-items-center gap-1">
                                        <fa-icon class="text-blue-400 text-opacity-80" [icon]="['fas','check']"></fa-icon>
                                        <span class="text-opacity-70 text-blue-400 fw-400">Disponível</span>
                                    </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="challenge.approved">
                            <div class="d-flex align-items-center gap-1">
                                <fa-icon class="text-success text-opacity-80" [icon]="['fas','check']"></fa-icon>
                                <span class="text-opacity-70 text-success fw-400">Concluido</span>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="challenge.locked">
                        <div class="d-flex align-items-center gap-1">
                            <fa-icon class="text-gray-400 text-opacity-80" [icon]="['fas','lock']"></fa-icon>
                            <span class="text-opacity-70 text-gray-400 fw-400">Bloqueado</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>