<ng-scrollbar class="app-sidebar-content" data-scrollbar="true" data-height="100%">
	<div class="menu">
		<div class="menu-profile d-block d-md-none bg-altern-glow-10 py-2 mb-3">
			<div class="d-flex gap-2 px-3 align-items-center" (click)="toggleProfileSide($event,'profile-collase')">
				<ng-container *ngIf="profile.image">
					<div class="menu-profile-image rounded-circle" [style]="'background-image:url('+profile.image+')'"></div>
				</ng-container>
				<ng-container *ngIf="!profile.image">
					<div class="menu-profile-image d-flex justify-content-center align-items-center rounded-circle bg-altern-800 fs-4">{{getinitials()}}</div>

				</ng-container>
				<div class="d-flex flex-column justify-content-center h-100 ">
					<span class="fw-bold limit-line-1 fs-15px w-160px" [innerHTML]="profile.name | noSanitize"></span>
					<div class="limit-line-1 w-160px">
						<small class="opacity-75 " [innerHTML]="profile.email | noSanitize"></small>
					</div>
				</div>
			</div>
		</div>
		<ng-template ngFor let-menu [ngForOf]="menus">
			<ng-container *ngIf="menu">
				<div *ngIf="menu.is_header">
					<div class="menu-header">{{ menu.text }}</div>
				</div>
				<div *ngIf="menu.is_divider;">
					<div class="menu-divider"></div>
				</div>
				<div *ngIf="!menu.is_divider && !menu.is_header && !menu.locked" class="menu-item" [ngClass]="{ 'active': (menu.path && verifyRoute(menu.path)) || (menu.children && isChildActive(menu.children)), 'has-sub': menu.children }">
					<a [routerLink]="!menu.children ? menu.path : null" class="menu-link" (click)="menu.children ? handleExpandSubmenu($event) : null">
						<ng-container *ngIf="menu.icon">
							<span class="menu-icon">
								<fa-icon [icon]="['fas',menu.icon]"></fa-icon>
								<!-- <i [ngClass]="menu.icon"></i> -->
							</span>
						</ng-container>
						<span class="menu-text">{{ menu.text }}</span>
						<span class="menu-caret" *ngIf="menu.children"><b class="caret"></b></span>
					</a>
					<div class="menu-submenu" *ngIf="menu.children">
						<ng-template ngFor let-childmenu [ngForOf]="menu.children">
							<div class="menu-item" [ngClass]="{ 'active': verifyRoute(childmenu.path) }">
								<a [routerLink]="childmenu.path" class="menu-link">
									<span class="menu-text">{{ childmenu.text }}</span>
								</a>
							</div>
						</ng-template>
					</div>
				</div>
			</ng-container>
		</ng-template>
	</div>
	<div class="menu-profile-side menu position-absolute bg-altern-glow-10 w-100 h-100">
		<div class="menu-profile-side-header d-flex align-items-center gap-3 px-3 py-2 mb-3">
			<div>
				<button class="btn btn-transparent-dark h-100 " (click)="toggleProfileSide($event,'profile-collase')"><fa-icon class="d-flex text-inverse-altern" [icon]="['fas','chevron-left']"></fa-icon></button>
			</div>
			<span class="fw-bold fs-15px">Perfil</span>
		</div>
		<div class="d-flex flex-column px-3">
			<div class="menu-item d-flex" [ngClass]="{ 'active': verifyRoute('/perfil/meu-perfil') }">
				<a class="menu-link" routerLink="/perfil/meu-perfil" >
					<div class="menu-icon">
						<fa-icon class="" [icon]="['fas','user']"></fa-icon>
					</div>
					&nbsp;Meu Perfil
				</a>
			</div>
			<!-- <div class="menu-item d-flex" [ngClass]="{ 'active': verifyRoute('/perfil/comments') }">
				<a class="menu-link" routerLink="/perfil/comments" >
					<div class="menu-icon">
						<fa-icon class="" [icon]="['fas','comments']"></fa-icon>
					</div>
					&nbsp;Comentários
				</a>
			</div> -->
			<!-- <div class="menu-item d-flex" [ngClass]="{ 'active': verifyRoute('/perfil/certificado') }">
				<a class="menu-link" routerLink="/perfil/certificado" >
					<div class="menu-icon">
						<fa-icon class="" [icon]="['fas','certificate']"></fa-icon>
					</div>
					&nbsp;Certificados
				</a>
			</div> -->
			<div class="menu-item d-flex" [ngClass]="{ 'active': verifyRoute('/perfil/editar') }">
				<a class="menu-link" routerLink="/perfil/editar" >
					<div class="menu-icon">
						<i class="bi bi-gear"></i>
					</div>
					&nbsp;Meus Dados 
				</a>
			</div>
			<div class="menu-item d-flex" [ngClass]="{ 'active': verifyRoute('/suporte') }">
				<a class="menu-link" routerLink="/suporte" >
					<div class="menu-icon">
						<fa-icon class="" [icon]="['fas','life-ring']"></fa-icon>
					</div>
					&nbsp;Suporte 
				</a>
			</div>
			<hr>
			<div class="menu-item d-flex">
				<div class="menu-link" role="button" (click)="onLogout()">
					<div class="menu-icon">
						<fa-icon class="text-theme" [icon]="['fas','right-from-bracket']"></fa-icon>
					</div>
					&nbsp;Logout
				</div>
			</div>
		</div>
	</div>
</ng-scrollbar>
<div class="sidenav-close position-absolute">
	<div>
		<button class="btn btn-close-side rounded-3 p-3 " (click)="toggleSidenav($event, 'app-sidebar-mobile-toggled')"><fa-icon class="d-flex" [icon]="['fas','times']"></fa-icon></button>
	</div>
</div>