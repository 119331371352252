import { environment } from "@environments/environment"


export class AuthData {
    public token_type?: string
    public accessToken?: string
    public refreshToken?: string
    public id_token?: string
    public expires_in?: number = 0
    public expires_at?: Date;
    public production?: boolean = false //? Verificar se o usuário está em produção ou não

    constructor(config?: any) {
        if (config) {
            this.token_type = (config.token_type !== undefined) ? config.token_type : this.token_type;
            this.accessToken = (config.accessToken !== undefined) ? config.accessToken : this.accessToken;
            this.refreshToken = (config.refreshToken !== undefined) ? config.refreshToken : this.refreshToken;
            this.id_token = (config.id_token !== undefined) ? config.id_token : this.id_token;
            this.expires_in = (config.expires_in !== undefined) ? (config.expires_in - 300) : this.expires_in; //? Diminuir 5 minutos do tempo de expiração
            this.expires_at = (config.expires_at) ? new Date(config.expires_at) : new Date(Date.now() + ((this.expires_in ?? 0 - 60) * 1000));
            this.production = environment.production;

        }
    }
    public get is_valid(): boolean {
        return (this.expires_at !== undefined && Date.now() <= this.expires_at.getTime());
    }
}

export class Login {
    type!: keyof LoginTypes;
    deviceId!: string;
    data?: any = {} as any

    constructor(config?: Login) {
        if (config) {
            this.type = (config.type !== undefined) ? config.type : this.type;
            this.deviceId = (config.deviceId !== undefined) ? config.deviceId : this.deviceId;
            this.data = (config.data !== undefined) ? config.data : this.data
        }
    }
    // private initializeData(type: any): LoginTypes[keyof LoginTypes] {
    //     switch (this.type) {
    //         case 'login': return new loginData(type);
    //         case 'authMFA': return new AuthMFAData(type);
    //         case 'changePassword': return new ChangePasswordData(type);
    //     }
    // };
}

export type LoginTypes = {
    login: loginData,
    changePassword: ChangePasswordData,
    authMFA: AuthMFAData
}

export class loginData {
    user?: string
    password?: string
    constructor(config?: any) {
        if (config) {
            this.user = (config.user !== undefined) ? config.user : this.user;
            this.password = (config.password !== undefined) ? config.password : this.password;
        }
    }
}

export class ChangePasswordData {
    user?: string
    password?: string
    newPassword?: string
    session?: string

    constructor(config?: any) {
        if (config) {
            this.user = (config.user !== undefined) ? config.user : this.user;
            this.password = (config.password !== undefined) ? config.password : this.password;
            this.newPassword = (config.newPassword !== undefined) ? config.newPassword : this.newPassword;
            this.session = (config.session !== undefined) ? config.session : this.session;
        }
    }
}

export class AuthMFAData {
    user?: string
    session?: string
    typeMFA?: string
    code?: string
    constructor(config?: any) {
        if (config) {
            this.user = (config.user !== undefined) ? config.user : this.user;
            this.session = (config.session !== undefined) ? config.session : this.session;
            this.typeMFA = (config.typeMFA !== undefined) ? config.typeMFA : this.typeMFA;
            this.code = (config.code !== undefined) ? config.code : this.code;
        }
    }
}

export interface UserLogin {
    user: string;
    password: string;
    session?: string;
    remenberMe?: string;
}