import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Trail } from '@classes';

@Component({
  selector: 'app-trail-card-row',
  templateUrl: './trail-card-row.component.html',
  styleUrls: ['./trail-card-row.component.scss']
})
export class TrailCardRowComponent {

  @ViewChild('trailRef') __trailRef!: ElementRef
  @Input() trial!: Trail;

  constructor(
    private Router: Router
  ) { }

  public goTo() {
    if (!this.trial.locked) {
      this.Router.navigate([`/trilha/${this.trial.alias}`])
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.__trailRef.nativeElement) {
        this.__trailRef.nativeElement.addEventListener('mousemove', (ev: any) => {
          const X = ev.layerX + 20;
          const Y = ev.layerY;

          this.__trailRef.nativeElement.style.setProperty('--mouse-x', `${X}px`);
          this.__trailRef.nativeElement.style.setProperty('--mouse-y', `${Y}px`);
        })
        this.__trailRef.nativeElement.addEventListener('mouseenter', (ev: any) => {
          this.__trailRef.nativeElement.style.setProperty('--opacity', 1);
        })
        this.__trailRef.nativeElement.addEventListener('mouseleave', (ev: any) => {
          this.__trailRef.nativeElement.style.setProperty('--opacity', 0);
        })
      }
    }, 1)
  }
}
