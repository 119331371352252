<div class="d-flex gap-1">
    <ng-container *ngIf="stageStyle === 'default'">
        <ng-container *ngFor="let item of nivel_div;let index = index">
            <div class="nivel-circle rounded-circle " [style]="'--n:'+item+';--size:'+size" [ngClass]="{'bg-success':index <= 2,'bg-warning':index> 2 && index <= 4,'bg-danger':index >4}"></div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="stageStyle === 'gray'">
        <ng-container *ngFor="let item of nivel_div;let index = index">
            <div class="nivel-circle rounded-circle " [style]="'--n:'+item+';--size:'+size" [ngClass]="{'bg-gray-200':index <= 2,'bg-gray-400':index> 2 && index <= 4,'bg-gray-600':index >4}"></div>
        </ng-container>
    </ng-container>
</div>
