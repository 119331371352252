import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Challenge } from '@classes';

@Component({
  selector: 'app-challenge-card',
  templateUrl: './challenge-card.component.html',
  styleUrls: ['./challenge-card.component.scss']
})
export class ChallengeCardComponent {
  @ViewChild('challengeRef') _challengeRef!: ElementRef
  @Input() challenge!: Challenge;

  constructor(
    private Router: Router
  ) { }

  public goTo() {
    if (!this.challenge.locked) {
      this.Router.navigate([`/desafio/visao-geral/${this.challenge.alias}`])
    }
  }

  public getHr(ms: number): string {
    const SEC = Math.floor(ms / 1000);
    const MIN = Math.floor(SEC / 60);
    const HR = Math.floor(MIN / 60);
    if (HR > 0) {
      return `${HR}h ${(MIN % 60) > 0 ? (MIN % 60) + 'min' : ''}`;
    }
    else if (MIN > 0) {
      return `${MIN}min`;
    } else {
      return `${SEC}s`;
    }
  }
  ngOnInit(): void {
    setTimeout(() => {
      if (this._challengeRef.nativeElement) {
        this._challengeRef.nativeElement.addEventListener('mousemove', (ev: any) => {
          const X = ev.layerX + 20;
          const Y = ev.layerY;

          this._challengeRef.nativeElement.style.setProperty('--mouse-x', `${X}px`);
          this._challengeRef.nativeElement.style.setProperty('--mouse-y', `${Y}px`);
        })
        this._challengeRef.nativeElement.addEventListener('mouseenter', (ev: any) => {
          this._challengeRef.nativeElement.style.setProperty('--opacity', 1);
        })
        this._challengeRef.nativeElement.addEventListener('mouseleave', (ev: any) => {
          this._challengeRef.nativeElement.style.setProperty('--opacity', 0);
        })
      }
    }, 1)
  }
}
