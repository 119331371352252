import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '@modules/app-common/services';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpIntercepterService implements HttpInterceptor {

  private block_show_toast:boolean = false;

  constructor(
    private CommonService: CommonService,
    private ToastrService: ToastrService,
    private Router: Router
  ) { }
  private clearCache() {
    this.CommonService.delLocalStorage('user-data');
    this.CommonService.delLocalStorage('auth-data');
    this.CommonService.delSessionStorage('user-data');
    this.CommonService.delSessionStorage('auth-data');
    this.CommonService.delLocalStorage('lab-init');
    this.CommonService.delLocalStorage('lab-initialing');
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      switchMap((res)=>{
        return of(res);
      }),
      catchError((err) => {
        if (err.status == 401) {
          this.clearCache();
          if(!this.block_show_toast){
            this.ToastrService.error('Sessões simultâneas foram detectadas.', 'Erro');
            this.block_show_toast = true;
            setTimeout(()=>{
              this.block_show_toast = false;
            },10000)
          }
          setTimeout(() => {
            this.Router.navigate(['/auth']);
          }, 500)
        }
        return throwError(err);
      })
    );
  }
}
