import { Component, Input, OnInit } from '@angular/core';
import { IconName } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateComponent implements OnInit {
  @Input() rate: number = 3.5
  @Input() icon: IconName = 'star'
  @Input() color: string = 'warning'
  @Input() size: 'sm' | 'md' | 'sm' = 'md'
  public int_rate: number = 0;
  public decimal_rate: number = 0;
  constructor() { }

  ngOnInit() {
    this.int_rate = Math.floor(this.rate);
    this.decimal_rate = this.rate - this.int_rate;
  }
}
