import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {
  @Input() badges: any[] = [];
  @Input() index!: number;
  @Input() size: 'sm' | 'nm' | 'md' | 'lg' = 'md';
  @Input() carregando: boolean = false;
  @Input() imageAtribute: string = 'image';
  @Input() maxLenght?: number;
  @Input() inverse?: boolean;
  @Input() moreBadges: boolean = false;
  @Input() moreBadgesRoute?: string;
  @Output() moreBadgeClick:EventEmitter<any> = new EventEmitter<any>();
  public badge: any;
  constructor() {}

  public onBadgeClick(){
    this.moreBadgeClick.emit(this.badge);
  }

  ngOnInit(): void {
    this.badge = this.badges[this.index];
  }

}
