import { MockService } from './mock.service';
import { AppMenuService } from './app-menus.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Course, CourseModule, Lab, LabAtributesAgrouping, LabInitialingCache, LabSession, Profile, Trail, LabsTotals, Challenge, ChallengeSession, LabFilterOptions, Practice, PracticeFilterOptions, PracticeAtributesAgrouping, MockType } from '../classes';
import { BehaviorSubject, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { HttpRequestsService } from './http-requests.service';
import { CommonService } from './common.service';
import * as moment from 'moment-timezone';
import { s } from '@fullcalendar/core/internal-common';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public data_alias_label?: string; //? alias do atual lab( caso não esteja em um lab essa variável será undefined )
  public fileUri?: any; //? arquivo zip para upload (desafios)
  constructor(
    private HttpRequestsService: HttpRequestsService,
    private CommonService: CommonService,
    private ToastrService: ToastrService,
    private Router: Router,
    private AppMenuService: AppMenuService,
    private MockService: MockService
  ) { }
  public lab_agrouping_atributes: LabAtributesAgrouping[] = [
    //? Atributos do filtro dos laboratórios
    {
      name: "Curso",
      attribute: 'courseTitle',
      order: []
    },
    {
      name: "Dificuldade",
      attribute: "difficulty",
      order: ['Iniciante', 'Intermediário', 'Avançado']
    },
    {
      name: "Level",
      attribute: "level",
      order: ['Practitioner', 'Associate']
    },
  ]
  public practice_agrouping_atributes: PracticeAtributesAgrouping[] = [
    //? Atributos do filtro dos simulados
    {
      name: "Curso",
      attribute: 'courseTitle',
      order: []
    },
    {
      name: "Level",
      attribute: "level",
      order: ['Practitioner', 'Associate']
    },
  ]
  public lab_filter_options: BehaviorSubject<LabFilterOptions[] | null> = new BehaviorSubject<LabFilterOptions[] | null>(null);
  public practice_filter_options: BehaviorSubject<PracticeFilterOptions[] | null> = new BehaviorSubject<PracticeFilterOptions[] | null>(null);
  // public lab_filter_atributes: LabFilterOptions[] = [
  //   new LabFilterOptions({
  //     title: 'Cursos',
  //     keyValue: 'courseId',
  //     values: []
  //   })
  // ]

  public course_collection: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public courses: BehaviorSubject<Course[] | null> = new BehaviorSubject<Course[] | null>(null);
  public profile: BehaviorSubject<Profile | null> = new BehaviorSubject<Profile | null>(null);
  public trails: BehaviorSubject<Trail[] | null> = new BehaviorSubject<Trail[] | null>(null);
  public labs: BehaviorSubject<Lab[] | null> = new BehaviorSubject<Lab[] | null>(null);
  public practices: BehaviorSubject<Practice[] | null> = new BehaviorSubject<Practice[] | null>(null);
  public labs_total: BehaviorSubject<LabsTotals | null> = new BehaviorSubject<LabsTotals | null>(null);
  public labs_session_started: BehaviorSubject<LabSession[] | null> = new BehaviorSubject<LabSession[] | null>(null);
  public lab_initialing_session: BehaviorSubject<LabInitialingCache | null> = new BehaviorSubject<LabInitialingCache | null>(null);
  public challenges: BehaviorSubject<Challenge[] | null> = new BehaviorSubject<Challenge[] | null>(null);
  public challenges_review_session: BehaviorSubject<ChallengeSession | null> = new BehaviorSubject<ChallengeSession | null>(null);


  public getListItems() {
    this.getCourses();
    this.getProfile();
    this.getLabs();
    this.getPractice();
    this.getLabSessionsStarted();
    this.getTrail();
    this.getChallenge();
    this.getChallengeSessionsStarted();
    this.getChallengeSessionReview();
    this.AppMenuService.getMenus();
  }


  private getCourses() {
    let sub = new Subject();
    const GET = this.HttpRequestsService.requestData('GET', ':productor/customer/user/courses_v2?order=order', true)
      // .pipe(
      //   map((res: any) => res),
      //   switchMap((res: any) => {
      //     return this.getMock('COURSES', res)
      //   })
      // )
    GET.pipe(takeUntil(sub))
      .subscribe({
        next: (res: any) => {
          if (res) {
            let course_list: Course[] = res.data.result.map((x: any) => new Course(x))
            // if (course_list.filter((x: Course) => x.type === 'video').length) {
            //   this.MockService.courses_mock.forEach(cm => {
            //     if (!course_list.find(c => c.id === cm.id)) {
            //       course_list.push(cm);
            //     }
            //   })
            // }

            this.lab_agrouping_atributes[0].order = course_list.map((x: Course) => x.title!); //? Colocar a ordenação por curso dos labs
            const COURSE_FILTER = {
              title: 'Curso',
              keyValue: 'courseId',
              values: course_list.map((x: Course) => {
                return {
                  identify: x.id,
                  name: x.title,
                  active: false
                }
              })
            }
            this.setLabFilterOption(new LabFilterOptions(COURSE_FILTER), 0); //? Colocar a fitragem por curso dos labs
            this.setPracticeFilterOption(new PracticeFilterOptions(COURSE_FILTER), 0); //? Colocar a fitragem por curso dos simulados
            course_list = course_list.filter((x: Course) => x.type === 'video')
            course_list = this.CommonService.orderAscByAtribute(course_list, 'locked').filter((x: Course) => !x.hide)
            course_list = course_list.map((c) => {
              c.collectionAlias = 'mentoria';
              return c
            }) //? Por enquanto não tem uma colleção definida, então adicionar todos a mentoria
            this.course_collection.next(
              [
                {
                  title: "Programas de Mentoria",
                  alias: "mentoria",
                  courses: course_list
                }
              ]
            );
            this.courses.next(course_list);
          }
        }, error: (err: any) => {
          if (err.status >= 400 && err.status < 500) {
            this.ToastrService.error(err.error.message, "Erro");
          } else {
            console.error(err);
            this.ToastrService.error("Ocorre um erro ao buscar os cursos da plataforma", "Erro");
          }
        },
        complete: () => {
          // this.getCoursesUser();
          sub.next(true);
          sub.complete()
        }
      })
  }
  private getCoursesUser() {
    let sub = new Subject();
    this.HttpRequestsService.requestData('GET', ':productor/customer/user/courses', true).pipe(takeUntil(sub))
      .subscribe({
        next: (res: any) => {
          if (res) {
            let courses: Course[] = this.courses.value!;
            courses.forEach((course, idx) => {
              const COURSE_FIND: Course = res.data.result.find((c: Course) => c.alias === course.alias);
              if (COURSE_FIND) {
                courses![idx].modules = COURSE_FIND.modules.map((m: any) => new CourseModule(m)); //? Modulos para verificar as aulas do curso e ter uma porcentagem de progresso
              }
            })
            courses = courses.map((c) => {
              c.collectionAlias = 'mentoria';
              return c
            }) //? Por enquanto não tem uma colleção definida, então adicionar todos a mentoria
            this.courses.next(courses);
            this.course_collection.next(
              [
                {
                  title: "Programas de Mentoria",
                  alias: "mentoria",
                  courses: courses
                }
              ]
            );
          }
        }, error: (err: any) => {
          if (err.status >= 400 && err.status < 500) {
            this.ToastrService.error(err.error.message, "Erro");
          } else {
            console.error(err);
            this.ToastrService.error("Ocorre um erro ao buscar os cursos do usuário", "Erro");
          }
        },
        complete: () => {
          sub.next(true);
          sub.complete();
        }
      })
  }
  private getProfile() {
    let sub = new Subject();
    this.HttpRequestsService.requestData('GET', ':productor/customer/user/profile', true).pipe(takeUntil(sub))
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.profile.next(new Profile(res.data));
            if (this.profile.value) {
              this.profile.value.insignia = res.data.insignia;
            }
            this.CommonService.changeMode(this.profile.value?.darkMode ? 'dark' : 'light')
          }
        }, error: (err: any) => {
          if (err.status >= 400 && err.status < 500) {
            this.ToastrService.error(err.error.message, "Erro");
          } else {
            console.error(err);
            this.ToastrService.error("Ocorre um erro ao buscar os cursos do profile", "Erro");
          }
        },
        complete: () => {
          sub.next(true);
          sub.complete();
        }
      })
  }
  public getLabs() {
    let sub = new Subject();
    const GET = this.HttpRequestsService.requestData('GET', ':productor/customer/labsv2?order=order', true)
      // .pipe(
      //   map((res: any) => res),
      //   switchMap((res: any) => {
      //     return this.getMock('LABS', res)
      //   })
      // )
    GET.pipe(takeUntil(sub))
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.labs_total.next(res.data.totals);
            let lab: Lab[] = res.data.result.map((x: any) => new Lab(x));
            if (lab.length) {
              this.MockService.labs_mock.forEach(lm => {
                if (!lab.find(l => l.id === lm.id)) {
                  lab.unshift(lm);
                }
              })
            }
            lab = this.CommonService.orderAscByAtribute(lab, 'locked').filter((x: Lab) => !x.hide)
            this.labs.next(lab);
          }
        }, error: (err: any) => {
          if (err.status >= 400 && err.status < 500) {
            this.ToastrService.error(err.error.message, "Erro");
          } else {
            console.error(err);
            this.ToastrService.error("Ocorre um erro ao buscar laboratórios", "Erro");
          }
        },
        complete: () => {
          sub.next(true);
          sub.complete();
        }
      })
  }
  public getPractice() {
    let sub = new Subject();
    const GET = this.HttpRequestsService.requestData('GET', ':productor/customer/test?order=order', true)
      // .pipe(
      //   map((res: any) => res),
      //   switchMap((res: any) => {
      //     return this.getMock('TEST#CONFIG', res)
      //   })
      // )
    GET.pipe(takeUntil(sub))
      .subscribe({
        next: (res: any) => {
          if (res) {
            let practices: Practice[] = res.data.result.map((x: any) => new Practice(x));
            practices = this.CommonService.orderAscByAtribute(practices, 'locked').filter((x: Practice) => !x.hide);
            if (practices.length) {
              this.MockService.practice_mock.forEach(pm => {
                if (!practices.find(p => p.id === pm.id)) {
                  practices.push(pm);
                }
              })
            }
            this.practices.next(practices);
          }
        }, error: (err: any) => {
          if (err.status >= 400 && err.status < 500) {
            this.ToastrService.error(err.error.message, "Erro");
          } else {
            console.error(err);
            this.ToastrService.error("Ocorre um erro ao buscar os simulados", "Erro");
          }
        },
        complete: () => {
          sub.next(true);
          sub.complete();
        }
      })
  }
  private getTrail() {
    let sub = new Subject();
    this.HttpRequestsService.requestData('GET', ':productor/customer/trails', true).pipe(takeUntil(sub))
      .subscribe({
        next: (res: any) => {
          if (res) {
            let trails = res.data.result.map((x: any) => new Trail(x)).filter((x: Trail) => !x.hide);
            this.trails.next(trails);
          }
        }, error: (err: any) => {
          if (err.status >= 400 && err.status < 500) {
            this.ToastrService.error(err.error.message, "Erro");
          } else {
            console.error(err);
            this.ToastrService.error("Ocorre um erro ao buscar as trilhas do usuário", "Erro");
          }
        },
        complete: () => {
          sub.next(true);
          sub.complete();
        }
      })
  }
  private getChallenge() {
    let sub = new Subject();
    const GET = this.HttpRequestsService.requestData('GET', ':productor/customer/challenge?order=order', true)
      // .pipe(
      //   map((res: any) => res),
      //   switchMap((res: any) => {
      //     return this.getMock('CHALLENGES', res)
      //   })
      // )
    GET.pipe(takeUntil(sub))
      .subscribe({
        next: (res: any) => {
          if (res) {
            let challenge = res.data.result.map((x: any) => new Challenge(x)).sort((a: Challenge, b: Challenge) => {
              if (!a.locked && b.locked) {
                return -1
              }
              else if (a.locked && !b.locked) {
                return 1
              }
              else {
                return 0
              }
            })
              .filter((x: Challenge) => !x.hide);
            this.challenges.next(challenge);
          }
        }, error: (err: any) => {
          if (err.status >= 400 && err.status < 500) {
            this.ToastrService.error(err.error.message, "Erro");
          } else {
            console.error(err);
            this.ToastrService.error("Ocorre um erro ao buscar os desafios", "Erro");
          }
        },
        complete: () => {
          sub.next(true);
          sub.complete();
        }
      })
  }
  public getMock(type: MockType, result: any): Observable<any> {
    let ids = result.data.result.map((x: any) => x.id);
    const PAYLOAD = { type, ids }
    return this.HttpRequestsService.requestData('PUT', ':productor/customer/mock', true, PAYLOAD).pipe(
      map(
        (resp: any) => {
          result.data.result = [...result.data.result, ...resp.data.result]
          return result
        },
        (err: any) => {
          throw new Error(err);
        }
      )
    )
  }
  private getLabSessionsStarted() {
    /**
     ** Deve buscar a sessão que esta iniciada e verificar se seu tempo acabou
     ** caso acabe Finaliza-la
     */
    let sub = new Subject();
    this.labs.pipe(takeUntil(sub))
      .subscribe(labs => {
        if (labs) {
          let sub02 = new Subject();
          this.HttpRequestsService.requestData('GET', ':productor/customer/labs/start?filters=status:Started', true).pipe(takeUntil(sub02))
            .subscribe({
              next: (res: any) => {
                const LABS_STARTED: LabSession[] = res.data.result.map((x: any) => new LabSession(x))
                this.labs_session_started.next(LABS_STARTED);
                if (res.data.result.length >= 1) {
                  LABS_STARTED?.forEach((x) => {
                    //? Sessão esta em andamento
                    let lab_to_session = labs.find(lab => lab?.sk == x.labSk);
                    if (moment(moment.tz('America/Sao_Paulo').format('MM-DD-YYYY HH:mm:ss'), 'MM-DD-YYYY HH:mm:ss').valueOf() - x.startedAt?.valueOf()! >= lab_to_session?.duration!) {
                      //? laboratorio expirou
                      this.finishLabSession(x.labId!, x.courseId!, x.id!, true);
                    } else if (!this.CommonService.getLocalStorage('lab-init')) {
                      //? laboratorio ainda esta em andamento
                      let PAYLOAD_STORAGE = {
                        profileId: this.profile.value!.id,
                        dateInit: moment(x.startedAt),
                        labId: lab_to_session?.id,
                        labName: lab_to_session?.name,
                        labAlias: lab_to_session?.alias,
                        labSk: lab_to_session?.sk,
                        sessionId: x?.id,
                      }
                      this.CommonService.setLocalStorage('lab-init', PAYLOAD_STORAGE);
                      this.lab_initialing_session.next(null);
                      this.CommonService.delLocalStorage('lab-initialing');
                    }
                  })
                }
                else {
                  this.labs_session_started.next(null);
                  this.CommonService.delLocalStorage('lab-init');
                }
                sub02.next(true);
                sub02.complete();
              }, error: (err: any) => {
                console.error(err);
              }
            })
          sub.next(true);
          sub.complete();
        }
      })
  }
  private getLabSessionsInitialing() {
    let sub = new Subject();
    this.labs.pipe(takeUntil(sub))
      .subscribe(labs => {
        if (labs) {
          let sub02 = new Subject();
          this.HttpRequestsService.requestData('GET', ':productor/customer/labs/start?filters=status:Initialing', true).pipe(takeUntil(sub02))
            .subscribe({
              next: (res: any) => {
                if (res.data.result.length >= 1) {
                  let lab_to_session = labs.find(lab => lab?.sk == res.data.result[0].labSk);
                  let lab_initialing_cache = this.CommonService.getLocalStorage('lab-initialing')
                  if (!lab_initialing_cache) {
                    let PAYLOAD_STORAGE = {
                      profileId: this.profile.value!.id,
                      labId: lab_to_session?.id,
                      labName: lab_to_session?.name,
                      labAlias: lab_to_session?.alias,
                      labSk: lab_to_session?.sk,
                      sessionId: res.data.result[0].id
                    }
                    this.CommonService.setLocalStorage('lab-initialing', PAYLOAD_STORAGE);
                    this.lab_initialing_session.next(PAYLOAD_STORAGE);
                  } else {
                    this.lab_initialing_session.next(lab_initialing_cache);
                  }
                }
                else {
                  this.lab_initialing_session.next(null);
                  this.CommonService.delLocalStorage('lab-initialing');
                }
                sub02.next(true);
                sub02.complete();
              }, error: (err: any) => {
                console.error(err);
              }
            })
          sub.next(true);
          sub.complete();
        }
      })
  }
  private getChallengeSessionsStarted() {
    /**
     ** Deve verificar se existe alguma sessão do desafio aberta
     *! Caso esteja fora do path ("/desafio/iniciar") do desafio deve finalizada
     */
    let sub = new Subject();
    this.HttpRequestsService.requestData('GET', ':productor/customer/challenge/session?filters=status:Started', true).pipe(takeUntil(sub))
      .subscribe({
        next: (res: any) => {
          if (res.data?.result?.length) {
            if (this.Router.url.indexOf('/desafio/iniciar') !== 0) {
              this.finishChallengeSession(new ChallengeSession(res.data.result[0]));
            }
          }
        }, error: (err: any) => {
          console.error(err);
        },
        complete: () => {
          sub.next(true);
          sub.complete();
        }
      })
  }
  public getChallengeSessionReview() {
    /**
    ** Deve verificar se existe alguma sessão do desafio em revisão/ aguardando revisão
    *! Caso exista, salvar em cache
    */
    let sub = new Subject();
    this.HttpRequestsService.requestData('GET', ':productor/customer/challenge/session?filters=status:In Review*Awaiting Evaluation', true).pipe(takeUntil(sub))
      .subscribe({
        next: (res: any) => {
          if (res.data?.result?.length) {
            let session = new ChallengeSession(res.data.result[0]);
            this.challenges_review_session.next(session);
            this.CommonService.setLocalStorage('challenge-await', session);
            let sub02 = new Subject();
            this.challenges.pipe(takeUntil(sub02))
              .subscribe((challenges) => {
                if (challenges) {
                  sub02.next(true);
                  sub02.complete();
                }
              })
          } else {
            this.challenges_review_session.next(null)
            this.CommonService.delLocalStorage('challenge-await');
          }
        }, error: (err: any) => {
          console.error(err);
        },
        complete: () => {
          sub.next(true);
          sub.complete();
        }
      })
  }

  /**
   * @param labId Id do laboratorio
   * @param courseId Id do curso
   * @param id  Id da sessão
   * @param timeout Foi finalizado por timeout
   */
  private finishLabSession(labId: string, courseId: string, id: string, timeout: boolean = false) {
    let payload: any = { labId, courseId, id }
    if (timeout) {
      payload.status = "Timeout"
    }
    let sub = new Subject();
    this.HttpRequestsService.requestData('PUT', `:productor/customer/labs/start`, true, payload).pipe(takeUntil(sub))
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.CommonService.delLocalStorage('lab-init');
            this.lab_initialing_session.next(null);
            this.labs_session_started.next(null);
            if (res.data.labSk) {
              this.finishLabStatus(res.data.labSk, !timeout);
            } else {
              //? Caso não retorne o labId, buscar todos os laboratorios
              this.getLabs();
            }
          }
        }, error: (err: any) => {
          console.error(err);
        },
        complete: () => {
          sub.next(true);
          sub.complete();
        }
      })
  }
  /**
   * @param labID ID do laboratorio
   * @param complete Completar laboratorio
   */
  public finishLabStatus(labSK: string, complete: boolean = true) {
    //* Finaliza o status do laboratorio
    //? Função esta no service para caso o usuário saia da tela antes de finalizar o labs, ainda atualizar o status
    let labs = this.labs.value;
    let lab_idx = labs?.findIndex(x => x.sk === labSK);
    labs![lab_idx!].onProgress = false;
    labs![lab_idx!].onInialing = false;
    if (complete) {
      //! Aumentar o total de tentativas ao finalizar o laboratorio
      labs![lab_idx!].totalTries++;
    }
    this.labs.next(labs);
  }

  /**
   * @param session Sessão que será encerrada
   */
  private finishChallengeSession(session: ChallengeSession) {
    let sub = new Subject();
    const PAYLOAD = {
      challengeId: session.challengeId,
      id: session.id,
    }
    this.HttpRequestsService.requestData("POST", `:productor/customer/challenge/finish`, true, PAYLOAD).pipe(takeUntil(sub))
      .subscribe({
        next: (res: any) => {
          if (res?.data) {
            const CHALLENGE = this.challenges.value?.find(x => x.id === session.challengeId);
            this.ToastrService.info(`Sessão iniciada do desafio '${CHALLENGE?.name}' foi finalizada`);
          }
          sub.next(true);
          sub.complete();
        },
        error: (err: any) => {
          if (err.status >= 400 && err.status < 500) {
            this.ToastrService.error(err.error.message, 'Erro');
          } else {
            this.ToastrService.error('Erro ao Finalizar o desafio', 'Erro');
          }
          sub.next(true);
          sub.complete();
          console.error(err);
        }
      })

  }


  /**
   * @param key Chave do objeto de preferencias
   * @param value Valor da chave informada
   */
  public setPreferences(key: string, value: string) {
    const PAYLOAD = {
      preferences: { ...this.profile.value?.preferences, [key]: value }
    }
    let sub = new Subject();
    this.HttpRequestsService.requestData('PUT', ':productor/customer/user/profile', true, PAYLOAD).pipe(takeUntil(sub))
      .subscribe({
        next: (res: any) => {
          if (res) {
            let profile = this.profile.value;
            profile!.preferences[key] = value;
            this.profile.next(new Profile(profile));
          }
        },
        error: (err: any) => {
          console.error(err);
        },
        complete: () => {
          sub.next(true);
          sub.complete();
        }
      });
  }
  public getPreferences(key: string): any {
    return this.profile.value?.preferences[key] ?? false;
  }

  public setLabFilterOption(option: LabFilterOptions, position?: number) {
    let value = this.lab_filter_options.value ?? [];
    if (position !== undefined) {
      value[position] = option;
    } else {
      value = [...value!, option];
    }
    this.lab_filter_options.next(value);
  }
  public setPracticeFilterOption(option: PracticeFilterOptions, position?: number) {
    let value = this.practice_filter_options.value ?? [];
    if (position !== undefined) {
      value[position] = option;
    } else {
      value = [...value!, option];
    }
    this.practice_filter_options.next(value);
  }

  public callLabSessionStarted() {
    this.getLabSessionsStarted();
  }
  public callLabSessionInitialing() {
    this.getLabSessionsInitialing();
  }

  public uploadZipFile(uri: any) {
    this.fileUri = uri;
  }

  public clearZipFile() {
    this.fileUri = '';
  }
}
