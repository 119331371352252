/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=regular&m=free */

import {
    faArrowAltCircleDown,
    faBell,
    faCheckCircle,
    faFlag,
    faBuilding,
    faFile,
    faFilePdf,
    faFileWord,
    faFileExcel,
    faFileAudio,
    faFileImage,
    faFileArchive,
    faFileVideo,
    faFilePowerpoint,
    faFolder,
    faStar,
    faEdit,
    faFaceFrown,
    faFaceMeh,
    faFaceSmile,
    faPaperPlane,
    faPenToSquare,
    faCircleCheck,
    faCircleXmark,
    faUser,
    faAddressCard,
    faClock,
    faClipboard,
    faSquare,
    faSquareCheck,
    faQuestionCircle,
    faFloppyDisk,
    faThumbsDown,
    faThumbsUp,
    faWindowRestore,
    faCircleQuestion,
} from '@fortawesome/free-regular-svg-icons';

export const fontAwesomeRegularIcons = {
    faArrowAltCircleDown,
    faBell,
    faCheckCircle,
    faFlag,
    faBuilding,
    faFile,
    faFilePdf,
    faFileWord,
    faFileExcel,
    faFileAudio,
    faFileImage,
    faFileArchive,
    faFileVideo,
    faFilePowerpoint,
    faFolder,
    faStar,
    faEdit,
    faFaceFrown,
    faFaceMeh,
    faFaceSmile,
    faPaperPlane,
    faPenToSquare,
    faCircleCheck,
    faCircleXmark,
    faUser,
    faAddressCard,
    faClock,
    faClipboard,
    faSquare,
    faSquareCheck,
    faQuestionCircle,
    faFloppyDisk,
    faThumbsDown,
    faThumbsUp,
    faWindowRestore,
    faCircleQuestion,

};
