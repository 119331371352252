<div class="w-100 bar-progress">
    <div class="bg-gray-800 rounded-pill position-relative" [style]="progressStyle.join(';')">
        <div [class]="'bg-'+barBg+' rounded-pill h-100'" [style]="'width:'+progress+'%'"></div>
        <ng-container *ngIf="markers?.length">
            <ng-container *ngFor="let item of markers">
                <div [class]="'progress-marker d-flex justify-content-center align-items-center position-absolute rounded-circle text-white '+item.size+'-size'" [ngClass]="{'active':progress >= item.position!,'bg-gray-800':progress < item.position!}"  [style]="'--pos:' + item.position + '%'">
                    <fa-icon class="d-flex justify-content-center align-items-center" [icon]="['fas',item.icon!]"></fa-icon>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
