import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-badge-amount",
  templateUrl: "./badge-amount.component.html",
  styleUrls: ["./badge-amount.component.scss"],
})
export class BadgeAmountComponent implements OnInit {
  @ViewChild("container") container!: any;

  @Input() badges: any[] = [];
  @Input() size: "sm" | "nm" | "md" | "lg" = "md";
  @Input() carregando: boolean = false;
  @Input() moreBadges: boolean = false;
  @Input() moreBadgesRoute?: string;
  @Input() inverse: boolean = true;
  @Input() maxLenght?: number;
  @Input() imageAtribute: string = "image";
  @Output() moreBadgeClick:EventEmitter<any> = new EventEmitter<any>();
  
  public carregando_badges: any[] = [1, 2, 3];
  public width: number = 0;
  public totalBadges: number = 0;
  public isOver(): boolean {
    const width = this.container?.nativeElement?.getBoundingClientRect().width;
    if (width && width < this.totalBadgeSize()) {
      return true;
    } else {
      return false;
    }
  }
  public getBadgeDiffRem(): number {
    const width = this.container?.nativeElement?.getBoundingClientRect().width;
    let diff = 0;
    if (width && width < this.totalBadgeSize()) {
      diff = Math.ceil(this.totalBadgeSize() - width);
    }
    return diff / 16;
  }
  public totalBadgeSize(): number {
    let badges_length = this.moreBadges ? this.badges?.length + 1 :this.badges?.length;
    switch (this.size) {
      case "sm":
        return badges_length * 32;
      case "nm":
        return badges_length * 48;
      case "lg":
        return badges_length * 96;
      default:
        return badges_length * 64;
    }
  }
  public onMoreBadgeClick(){
    this.moreBadgeClick.emit();
  }
  ngOnInit(): void {
    let badges = [];
    this.totalBadges = this.badges.length;;
    if (this.maxLenght !== undefined) {
      for (let i = 0; i < this.maxLenght; i++) {
        if (this.badges[i] !== undefined) {
          badges.push(this.badges[i]);
        }
      }
      this.badges = badges;
    }
  }
}
