import { CourseImage, CourseModule } from "./course"
import { Lab } from "./lab"
import { Practice } from "./practice"

export class Trail {
    statusCode?:number
    modules?:CourseModule[] = []
    imgs?:CourseImage
    ended?:boolean = false
    hided?:boolean = false
    getId?:boolean = false
    getLabs?:boolean = false
    alias?:string
    labs?:Lab[] = []
    labsAlias?:Lab[] = []
    testes?:Practice[]
    courseId?:string
    description?:string
    id?:string
    title?:string

    constructor(config?:any){
        if(config){
            this.statusCode = (config.statusCode !== undefined) ? config.statusCode : this.statusCode
            // this.modules = (config.modules !== undefined) ? config.modules.map((x:any)=>new CourseModule(x)) : this.modules
            this.modules = (config.modules !== undefined) ? config.modules : this.modules
            // this.labs = (config.labs !== undefined) ? config.labs.map((x:any)=>new Lab(x)) : this.labs
            this.labs = (config.labsAlias !== undefined) ? config.labsAlias : this.labs
            this.labs = (config.labs !== undefined) ? config.labs : this.labs
            this.labsAlias = (config.labsAlias !== undefined) ? config.labsAlias : this.labsAlias
            // this.testes = (config.testes !== undefined) ? config.testes.map((x:any)=>new Practice(x)) : this.testes
            this.testes = (config.testes !== undefined) ? config.testes : this.testes
            this.imgs = (config.imgs !== undefined) ? config.imgs : this.imgs
            this.ended = (config.ended !== undefined) ? !!config.ended : this.ended
            this.hided = (config.hide !== undefined) ? !!config.hide : this.hided
            this.alias = (config.alias !== undefined) ? config.alias : this.alias
            this.courseId = (config.courseId !== undefined) ? config.courseId : this.courseId
            this.description = (config.description !== undefined) ? config.description : this.description
            this.id = (config.id !== undefined) ? config.id : this.id
            this.title = (config.title !== undefined) ? config.title : this.title

        }
    }
    public get locked(): boolean {
        return !this.statusCode;
    }
    public get hide(): boolean {
        if (this.hided) {
            return this.hided;
        } else {
            return this.ended!;
        }
    }
}