import { Subscription } from 'rxjs';
import { AppService } from '@modules/app-common/services';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CourseClasse, CourseModule } from '@modules/app-common/classes';


@Component({
  selector: 'app-modules-card',
  templateUrl: './modules-card.component.html',
  styleUrls: ['./modules-card.component.scss']
})
export class ModulesCardComponent implements OnInit, OnDestroy {
  @ViewChild('moduleRef') _moduleRef!: ElementRef

  @Input() module: CourseModule = new CourseModule();
  // @Input() module: CourseClasse = new CourseClasse();
  @Input() collectionAlias?: string;
  @Input() classes: string = '';

  private subscriptions: Subscription[] = [];
  public total_ms: number = 0;
  public total_hr: string = '0s';
  public show_progress: boolean = false;
  public collapse: boolean = true;
  constructor(
    private Router: Router,
    private AppService: AppService
  ) { }
  public getHr(ms: number): string {
    const SEC = Math.floor(ms / 1000);
    const MIN = Math.floor(SEC / 60);
    const HR = Math.floor(MIN / 60);
    if (HR > 0) {
      return `${HR}h ${(MIN % 60) > 0 ? (MIN % 60) + 'min' : ''}`;
    }
    else if (MIN > 0) {
      return `${MIN}min ${(SEC % 60) > 0 ? `${SEC % 60}s` : ''}`;
    } else {
      return `${SEC}s`;
    }
  }
  private getTotalMs(classes: CourseClasse[]) {
    let ms = 0;
    classes.forEach((c) => {
      ms += c.duration!;
    })
    this.total_ms = ms;
    this.total_hr = this.getHr(ms);
  }
  public counterPorcentNumber(): number {
    const COUNT_CLASS_WATCHED = this.module?.classes.filter((c: any) => c.watched).length;
    if(COUNT_CLASS_WATCHED > 0){
      return COUNT_CLASS_WATCHED / this.module?.classes.length;
    } else {
      return 0;
    }
  }
  public counterPorcent(): string {
    const COUNT_CLASS_WATCHED = this.module?.classes.filter((c: any) => c.watched).length;
    if(COUNT_CLASS_WATCHED > 0){
      return (COUNT_CLASS_WATCHED * 100 / this.module?.classes.length).toFixed(0) + '<small>%</small>';
    } else {
      return "0<small>%</small>"
    }
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.getTotalMs(this.module.classes);
      if (this._moduleRef.nativeElement) {
        this._moduleRef.nativeElement.addEventListener('mousemove', (ev: any) => {
          const X = ev.layerX + 20;
          const Y = ev.layerY;
          this._moduleRef.nativeElement.style.setProperty('--mouse-x', `${X}px`);
          this._moduleRef.nativeElement.style.setProperty('--mouse-y', `${Y}px`);
        })
        this._moduleRef.nativeElement.addEventListener('mouseenter', (ev: any) => {
          this._moduleRef.nativeElement.style.setProperty('--opacity', 1);
        })
        this._moduleRef.nativeElement.addEventListener('mouseleave', (ev: any) => {
          this._moduleRef.nativeElement.style.setProperty('--opacity', 0);
        })
      }
    }, 1)
    setTimeout(()=>{
      //? Progresso do modulo estava dando problema ao iniciar, então coloquei um timeout
      this.show_progress = true;
    },800)
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }
}
