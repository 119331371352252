<div class="d-flex badge-amount-container" #container [ngClass]="{'over-badge':isOver(),'inverse justify-content-start':inverse,'justify-content-end':!inverse}" [style]="'--diff:'+getBadgeDiffRem()+';--badges-length:'+badges.length">
    <ng-container *ngIf="carregando">
        <app-badge [badges]="carregando_badges" [index]="0" [size]="size" [imageAtribute]="imageAtribute" [carregando]="carregando" [maxLenght]="maxLenght" [inverse]="inverse" (moreBadgeClick)="onMoreBadgeClick()"></app-badge>
    </ng-container>
    <ng-container *ngIf="!carregando">
        <!-- <app-badge [badges]="badges" [index]="0" [size]="size" [imageAtribute]="imageAtribute" [carregando]="carregando" [maxLenght]="maxLenght" [inverse]="inverse"></app-badge> -->
        <ng-container *ngFor="let item of badges;let index = index;let last = last">
            <app-badge [badges]="badges" [index]="index" [size]="size" [imageAtribute]="imageAtribute" [carregando]="carregando" [maxLenght]="maxLenght" [inverse]="inverse"></app-badge>
            <ng-container *ngIf="last && moreBadges && ((index + 1) < totalBadges)">
                <app-badge [badges]="badges" [index]="(index + 1)" [size]="size" [imageAtribute]="imageAtribute" [carregando]="carregando" [maxLenght]="maxLenght" [inverse]="inverse" [moreBadges]="moreBadges" [moreBadgesRoute]="moreBadgesRoute" (moreBadgeClick)="onMoreBadgeClick()"></app-badge>
            </ng-container>
        </ng-container>
    </ng-container>
</div>