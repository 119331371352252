import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CommonService } from '../services';

@Injectable()
export class IsLogged implements CanActivate {


  constructor(
    private Router: Router,
    private common: CommonService,
  ) { }

  public canActivate(): Observable<boolean> {
    const aux: any = (this.common.getSessionStorage('auth-data') ? this.common.getSessionStorage('auth-data') : this.common.getLocalStorage('auth-data'));
    if (aux) {
      this.Router.navigate(['']);
      return of(false);
    } else {
      return of(true);
    }
  }

}
