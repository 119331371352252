<a class="course-card rounded-3 overflow-hidden d-block text-decoration-none" [ngClass]="{'locked':course.locked}" (click)="!course.locked ? goRoute() : ''">
    <div class="course-info w-100 h-100">
        <ng-container *ngIf="course.imgs![0]?.logoMini">
            <div class="background-image" [style]="'background-image:url('+course.imgs![0].logoMini+')'"></div>
        </ng-container>
        <ng-container *ngIf="course?.tag">
            <div [class]="'tag text-center lh-1 py-1 px-5 bg-'+tag_color" id="tag">
                <span class="fw-bold text-white" [innerHTML]="course.tag?.text | uppercase"></span>
            </div>
        </ng-container>
        <div class="title px-3 text-start w-100 lh-sm ">
            <span class="fs-5 fw-bold limit-line-2 text-gray-100" [innerHTML]="course.title | noSanitize"></span>
        </div>
        <div class="details px-3 w-100  mt-1">
            <div class="description lh-sm ">
                <small class="text-white opacity-50 limit-line-4" [innerHTML]="course.description | noSanitize"></small>
            </div>
        </div>
        <ng-container *ngIf="!course.locked">
            <div class="play-btn d-flex align-items-center justify-content-center rounded-circle">
                <fa-icon class="fa-3x d-flex text-black" [icon]="['fas','play']"></fa-icon>
            </div>
        </ng-container>
        <ng-container *ngIf="course.locked">
            <div class="locked-icon d-flex align-items-center justify-content-center rounded-circle">
                <fa-icon class="fa-3x d-flex text-white" [icon]="['fas','lock']"></fa-icon>
            </div>
        </ng-container>
        <div class="progress-course">
            <div class="w-100">
                <ng-container *ngIf="course.progress">
                    <div class=" text-end pe-2 pb-1">
                        <small class="text-white opacity-50">Progresso: {{course.progress}}%</small>
                    </div>
                </ng-container>
                <ng-container *ngIf="!course.progress">
                    <div class="d-flex gap-1 justify-content-end pe-2 pb-1">
                        <ng-container *ngIf="course.locked">
                            <fa-icon class="text-white d-flex" [icon]="['fas','lock']"></fa-icon>
                        </ng-container>
                        <small class="opacity-75 fw-bold init-course">INICIAR {{course.isTest ? 'SIMULADO' : 'CURSO'}}</small>
                    </div>
                </ng-container>
                <app-progress-bar [progress]="course.progress" [height]="'sm'"></app-progress-bar>
            </div>
        </div>
        <div class="cover-hover"></div>
    </div>
</a>