// Core Module
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CommonModule, JsonPipe } from '@angular/common';
import { ErrorHandler, Inject, Injectable, InjectionToken, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';

// Plugins
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { AppCommonModule } from '../modules/app-common/app-common.module'
import { initializeApp } from "firebase/app";
import { environment } from '@environments/environment';

// App Component
import { AppComponent } from './app.component';
import { AppToastr } from '../modules/app-common/components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonService, HttpIntercepterService, RollbarErrorHandler, RollbarService, rollbarFactory } from '@modules/app-common/services';

// initializeApp(environment.firebaseConfig);
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    JsonPipe,
    AppCommonModule.forRoot(),
    ToastrModule.forRoot({ progressBar: true, toastComponent: AppToastr }),
    NgScrollbarModule,
    HighlightModule,
    FontAwesomeModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: environment.production,
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [Title, {
    provide: HIGHLIGHT_OPTIONS,
    useValue: {
      coreLibraryLoader: () => import('highlight.js/lib/core'),
      languages: {
        typescript: () => import('highlight.js/lib/languages/typescript'),
        css: () => import('highlight.js/lib/languages/css'),
        xml: () => import('highlight.js/lib/languages/xml')
      }
    },
  }, {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        visibility: 'hover'
      }
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpIntercepterService, multi: true },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor(
    private router: Router
    ) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        var elm = document.getElementById('app');
        if (elm) {
          elm.classList.remove('app-sidebar-mobile-toggled');
        }
      }
    });
  }

}
