import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Lab } from '@modules/app-common/classes';

@Component({
  selector: 'app-lab-card',
  templateUrl: './lab-card.component.html',
  styleUrls: ['./lab-card.component.scss']
})
export class LabCardComponent implements OnInit {
  @ViewChild('labRef') _labRef!: ElementRef
  @Input() lab!: Lab
  @Input() courseTitle: boolean = true;
  @Input() labDiff: boolean = true;
  @Input() classes: string = '';

  constructor(
    private Router: Router
  ) { }

  public getHr(ms: number): string {
    const SEC = Math.floor(ms / 1000);
    const MIN = Math.floor(SEC / 60);
    const HR = Math.floor(MIN / 60);
    if (HR > 0) {
      return `${HR}h ${(MIN % 60) > 0 ? (MIN % 60) + 'min' : ''}`;
    }
    else if (MIN > 0) {
      return `${MIN}min`;
    } else {
      return `${SEC}s`;
    }
  }
  public goTo() {
    if (!this.lab.locked) {
      this.Router.navigate([`/laboratorio/iniciar/${this.lab.alias}`])
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this._labRef.nativeElement && !this.lab.locked) {
        this._labRef.nativeElement.addEventListener('mousemove', (ev: any) => {
          const X = ev.layerX + 20;
          const Y = ev.layerY;

          this._labRef.nativeElement.style.setProperty('--mouse-x', `${X}px`);
          this._labRef.nativeElement.style.setProperty('--mouse-y', `${Y}px`);
        })
        this._labRef.nativeElement.addEventListener('mouseenter', (ev: any) => {
          this._labRef.nativeElement.style.setProperty('--opacity', 1);
        })
        this._labRef.nativeElement.addEventListener('mouseleave', (ev: any) => {
          this._labRef.nativeElement.style.setProperty('--opacity', 0);
        })
      }
    }, 1)
  }

}
