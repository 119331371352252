export class ExamTags {
    color?: string;
    id?: string;
    name?: string;
    level?: string;

    constructor(config?: any) {
        if (config) {
            this.color = config.color !== undefined ? config.color : this.color;
            this.id = config.id !== undefined ? config.id : this.id;
            this.name = config.name !== undefined ? config.name : this.name;
            this.level = config.attribute.level !== undefined ? config.attribute.level : this.level;
        }
    }
}