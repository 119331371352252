export class ExamList {
    statusCode?: number = 1;
    name?: string;
    dateExam?: string;
    description?: string;
    id?: string;
    type?: string;    

    constructor(config?: any) {
        if (config) {
            this.statusCode = config.statusCode !== undefined ? config.statusCode : this.statusCode;
            this.name = config.name !== undefined ? config.name : this.name;
            this.dateExam = config.dateExam !== undefined ? config.dateExam : this.dateExam;
            this.description = config.description !== undefined ? config.description : this.description;
            this.id = config.id !== undefined ? config.id : this.id;
            this.type = config.type !== undefined ? config.type : this.type;
        }
    }
}