<div class="d-flex flex-column w-100">
	<div class="container header-nav">
		<div class="d-flex justify-content-between align-items-center h-100">
			<ng-container *ngIf="is_logged">
				<div class="mobile-toggler">
					<button type="button" class="menu-toggler" (click)="handleAppToggleClass($event, 'app-sidebar-mobile-toggled')">
						<span class="bar"></span>
						<span class="bar"></span>
						<span class="bar"></span>
					</button>
				</div>
			</ng-container>
			<div class="brand">
				<a routerLink="/" class="brand-logo w-100" aria-label="Home">
					<div class="brand-img" aria-label="Home"></div>
				</a>
			</div>
			<div class="h-100 d-none d-md-block">
				<ng-container *ngIf="is_logged">
					<div class="navigation d-flex align-items-center h-100">
						<ng-container *ngFor="let item of menus">
							<ng-container *ngIf="item && !item.is_header">
								<ng-container *ngIf="!item.locked">
									<a class="navigation-item d-flex align-items-center gap-2 text-decoration-none h-75 px-3" [routerLink]="item.path" [ngClass]="{'active':verifyRoute(item.path)}">
										<span class=""  [innerHtml]="item.text | noSanitize"></span>
									</a>
								</ng-container>
								<ng-container *ngIf="item.locked">
									<div class="navigation-item d-flex align-items-center gap-2 text-decoration-none h-75 px-3 locked">
										<fa-icon class="text-gray-400" [icon]="['fas','lock']"></fa-icon>
										<span class="text-gray-400" [innerHtml]="item.text | noSanitize"></span>
									</div>
								</ng-container>
							</ng-container>
						</ng-container>
					</div>
				</ng-container>
			</div>
			<div>
				<div class="menu">
					<ng-container *ngIf="is_logged">
						<div class="menu-item dropdown dropdown-mobile-full d-none d-md-flex">
							<a href="javascript:;" data-bs-toggle="dropdown" data-bs-display="static" class="menu-link">
								<ng-container *ngIf="profile.image">
									<div class="menu-img online">
										<div class="w-100 rounded-circle" [style]="'background-image:url('+profile.image+');background-size:cover;background-position:center;aspect-ratio:1/1'" alt="Profile"  ></div>
									</div>
								</ng-container>
								<ng-container *ngIf="!profile.image">
									<div class="menu-img online d-flex justify-content-center align-items-center bg-altern-800 rounded-circle ">{{getinitials()}}</div>
								</ng-container>
							</a>
							<div class="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1 rounded-2">
								<a class="dropdown-item d-flex align-items-center" routerLink="/perfil/meu-perfil">MEU PERFIL <fa-icon class="ms-auto text-theme fs-16px my-n1" [icon]="['fas','user']"></fa-icon></a>
								<!-- <a class="dropdown-item d-flex align-items-center" routerLink="/perfil/certificado">CERTIFICADOS <fa-icon class="ms-auto text-theme fs-16px my-n1" [icon]="['fas','certificate']"></fa-icon></a> -->
								<a class="dropdown-item d-flex align-items-center gap-2" role="button" (click)="openModal(modalDiscord)"><span>EMAIL DO DISCORD</span> <fa-icon class="ms-auto text-theme fs-16px my-n1" [icon]="['fab','discord']"></fa-icon></a>
								<a class="dropdown-item d-flex align-items-center gap-2" routerLink="/perfil/editar">MEUS DADOS <i class="bi bi-gear ms-auto text-theme fs-16px my-n1"></i></a>
								<a class="dropdown-item d-flex align-items-center gap-2" routerLink="/suporte">SUPORTE <fa-icon class="ms-auto text-theme fs-16px my-n1" [icon]="['fas','life-ring']"></fa-icon></a>
								<div class="dropdown-divider"></div>
								<div class="dropdown-item d-flex align-items-center gap-2" role="button" (click)="onLogout()">LOGOUT <fa-icon class="ms-auto text-theme fs-16px my-n1" [icon]="['fas','right-from-bracket']"></fa-icon></div>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="!is_logged">
						<div>
							<a class="btn btn-sm d-flex align-items-center gap-2 text-decoration-none" routerLink="/auth">
								<div class="d-flex justify-content-center align-items-center border border-default rounded-circle h-32px w-32px">
									<fa-icon class="fa-lg" [icon]="['fas','user']"></fa-icon>
								</div>
								<span class="fw-bold">Login</span>
							</a>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
	<div [class]="'header-notification w-100 bg-'+notification.bg" [ngClass]="{'animation':notification.animation,'text-center':!notification.animation}">
		<div class="notification d-flex align-items-center h-100">
			<span class="fw-bold text-white w-100" [innerHTML]="notification.notification | noSanitize"></span>
		</div>
	</div>
</div>
<ng-template let-modal #modalDiscord>
			<div class="modal-content rounded-3 bg-altern border-0"  id="modalDiscord">
				<div class="modal-header border-0 shadow-sm">
					<span class="fs-5 fw-bold">Editar Email do Discord</span>
					<button type="button" class="btn-close" [disabled]="carregando" (click)="profileConst();modal.close()"></button>
				</div>
				<div class="modal-body">
					<div class="row" >
						<form #fDados="ngForm" class="col-12" (ngSubmit)="onSubmitDiscord(fDados,'modalDiscord')">
							<div class="w-100 mb-4">
								<app-input [label]="'E-mail do Discord'" [type]="'email'" [placeholder]="'Ex.: exemplo@cloudfaster.com.br'" [(value)]="profile.email_discord" [maxlength]="100" [ngForm]="fDados"  [disabled]="carregando"></app-input>
							</div>
							<div class="d-flex justify-content-end gap-2 w-100">
								<button type="button" class="btn btn-outline-default px-4 close" [disabled]="carregando" (click)="profileConst();modal.close()">Fechar</button>
								<button type="submit" class="btn btn-theme px-4" [disabled]="!fDados.valid || carregando">Salvar</button>
							</div>
						</form>
					</div>
				</div>
			</div>
</ng-template>