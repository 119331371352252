export class UserRank {
    nameStudent?: string;
    username?: string;
    points: number = 0;
    imgStudent?: string;
    type?: string;
    index?: string;

    constructor(config?: any) {
        if (config) {
            this.nameStudent = config.nameStudent !== undefined ? config.nameStudent : this.nameStudent;
            this.username = config.username !== undefined ? config.username : this.username;
            this.points = config.points !== undefined ? Number(Number(config.points).toFixed(0)) : this.points;
            this.imgStudent = config.imgStudent !== undefined ? config.imgStudent : this.imgStudent;
            this.type = config.type !== undefined ? config.type : this.type;
            this.index = config.index !== undefined ? config.index : this.index;
        }
    }
}

export interface RankType {
    name:string,
    type:string
}