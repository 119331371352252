import { IconPrefix } from "@fortawesome/free-solid-svg-icons";
import { ProfileCountry } from "./profile";

export class UserData {
    public id?: string
    public email?: string
    public name?: string
    public email_verified?: string
    public incompleted?: boolean = false;

    constructor(config: any) {
        if (config) {
            this.id = (config.id !== undefined) ? config.id : this.id;
            this.email = (config.email !== undefined) ? config.email : this.email;
            this.name = (config.name !== undefined) ? config.name : this.name;
            this.email_verified = (config.email_verified !== undefined) ? config.email_verified : this.email_verified;
            this.incompleted = (config.incompleted !== undefined) ? config.incompleted : this.incompleted;
        }
    }

    public get initials(): string {
        let initials: string = '';
        const aux = this.name?.split(' ');
        for (let index = 0; (aux) ? index < aux.length : 0; index++) {
            const element = (aux) ? aux[index] : undefined;
            initials += (element && element.length > 0) ? element.charAt(0).toUpperCase() : ''
            if (initials.length >= 2) {
                index = (aux) ? aux.length : 0;
            }
        }
        return initials;
    }
    public get initialsBg(): string {
        return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    }
}

export type Links = {
    [index in keyof LinksType]: {
        icon: IconPrefix;
        link: string;
        name: string;
    };
};
export type LinksType = {
    linkdin: 'linkedin',
    site: 'link',
    facebook: 'facebook',
}

export class CompleteUserAuth {
    document?: string
    country?: ProfileCountry
    birthDate?: string
    objective?: string
    address?: {
        cep: string
        city: string
        complement: string
        neighborhood: string
        number: string
        street: string
        uf: string
    }
    constructor(config:any){
        if(config){
            this.document = (config.document !== undefined) ? config.document : this.document;
            this.country = (config.country !== undefined) ? config.country : this.country;
            this.birthDate = (config.birthDate !== undefined) ? config.birthDate : this.birthDate;
            this.objective = (config.objective !== undefined) ? config.objective : this.objective;
            this.address = (config.address !== undefined) ? config.address : {} as any;
        }
    }
}