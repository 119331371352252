import { MockService } from './../../services/mock.service';
import { AppService } from '@modules/app-common/services';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Course, CourseClasse, CourseModule } from '@modules/app-common/classes';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'app-lesson',
  templateUrl: './lesson.component.html',
  styleUrls: ['./lesson.component.scss']
})
export class LessonComponent implements OnInit, OnDestroy, OnChanges {
  @Input() activeId?: string;
  @Input() module: CourseModule = new CourseModule();
  @Input() course: Course = new Course();
  @Output() clickClasse: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickWatched: EventEmitter<any> = new EventEmitter<any>();
  @Input() classSelected: CourseClasse = {} as CourseClasse;
  @Output() classSelectedChange: EventEmitter<CourseClasse> = new EventEmitter<CourseClasse>();
  public subscription: Subscription[] = [];
  public moduleCollapse: boolean = true;
  public isActive: boolean = false;
  public data_classe: boolean = false;
  public total_class_ms: number = 0;
  public total_class_time?: string;

  constructor(
    private Router: Router,
    private AppService: AppService,
    private MockService: MockService,
  ) {
    this.subscription.push(
      this.Router.events.pipe(filter(x => x instanceof NavigationEnd))
        .subscribe((val) => {
        })
    )
  }

  public onClickClass(item: CourseClasse) {
    if (!item.locked) {
      this.classSelected = item;
      this.clickClasse.emit(
        {
          classe: item,
          module: this.module
        }
      );
    }
  }
  public onClickWatched(item: any) {
    if (!item.locked) {
      this.clickWatched.emit(
        {
          classe: item,
          module: this.module
        }
      );
      this.togglewatchedClasse(item.alias);
    }
  }


  public togglewatchedClasse(alias: string) {
    const CLASSE = this.module?.classes.find((x: any) => x.alias === alias);
    CLASSE!.watched = !CLASSE!.watched;
  }
  public counterPorcentNumber(): number {
    const COUNT_CLASS_WATCHED = this.module?.classes.filter((c: any) => c.watched).length;
    if (COUNT_CLASS_WATCHED > 0) {
      return COUNT_CLASS_WATCHED / this.module?.classes.length;
    } else {
      return 0;
    }
  }
  public counterPorcent(): string {
    const COUNT_CLASS_WATCHED = this.module?.classes.filter((c: any) => c.watched).length;
    if (COUNT_CLASS_WATCHED > 0) {
      return (COUNT_CLASS_WATCHED * 100 / this.module?.classes.length).toFixed(0) + '<small>%</small>';
    } else {
      return "0<small>%</small>"
    }
  }
  public counterDeg(): string {
    const COUNT_CLASS_WATCHED = this.module?.classes.filter((c: any) => c.watched).length;
    const percent = COUNT_CLASS_WATCHED * 100 / this.module?.classes.length;
    const deg = 360 * percent / 100;
    if (COUNT_CLASS_WATCHED > 0) {
      return (deg + 'deg');
    } else {
      return "0deg"
    }
  }
  private verifyActives() {
    this.module.classes.forEach((c: any) => {
      if (c.alias == this.classSelected.alias) {
        c.actived = true;
        this.moduleCollapse = false;
      } else {
        c.actived = false;
      }
    });
  }
  public getTotalClassMs(module?: CourseModule): number {
    let total = 0;
    if (module) {
      module.classes.forEach((c: CourseClasse) => {
        total += c.duration!;
      })
    }
    return total;
  }
  public getTime(ms: number): string {
    const SEC = Math.floor(ms / 1000);
    const MIN = Math.floor(SEC / 60);
    const HR = Math.floor(MIN / 60);
    if (HR > 0) {
      return `${HR}h ${(MIN % 60) > 0 ? (MIN % 60) + 'min' : ''}`;
    }
    else if (MIN > 0) {
      return `${MIN}min`;
    } else {
      return `${SEC}s`;
    }
  }
  ngOnInit(): void {
    this.total_class_ms = this.getTotalClassMs(this.module);
    this.total_class_time = this.getTime(this.total_class_ms);
    /**
   *TODO: REMOVER MOKING
   */
    //* Liberar ou bloquear o modulo de desafio
    if (this.MockService.module_initial_mock_ids.includes(this.module.id!)) { //? ID - MODULO 01 do "desafio"
      const MODULE_CHALLENGE = this.course.modules.find(x => this.MockService.module_challenge_mock_ids.includes(x.id!)); //? ID - MODULO DO DESAFIO do "desafio"
      const COUNT_CLASS_WATCHED = this.module.classes!.filter((c: any) => c.watched).length;
      if (COUNT_CLASS_WATCHED / this.module.classes.length != 1) {
        MODULE_CHALLENGE!.statusCode = 0;
        MODULE_CHALLENGE!.disabledClasses();
      } else {
        MODULE_CHALLENGE!.statusCode = 1;
        MODULE_CHALLENGE!.enabledClasses();
      }
    }
    // if(this.MockService.module_initial_mock_ids.includes(this.module.id!) || this.MockService.module_challenge_mock_ids.includes(this.module.id!)){ //? ID - MODULO 01 do "desafio"
    //     const MODULES_RESOURSE = this.course.modules.filter(x=>{this.MockService.modules_resource_mock_ids.includes(x.id!)}); //? ID - MODULOS DE RECURSOS do "desafio"
    //     const COUNT_CLASS_WATCHED = this.module.classes!.filter((c: any) => c.watched).length;
    //     if(COUNT_CLASS_WATCHED  / this.module.classes.length != 1){
    //       MODULES_RESOURSE.forEach(mr=>{
    //         mr!.statusCode = 0;
    //         mr!.disabledClasses();
    //       })
    //     } else {
    //       MODULES_RESOURSE.forEach(mr=>{
    //         mr!.statusCode = 1;
    //         mr!.enabledClasses();
    //       })
    //     }
    // }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['classSelected']) {
      this.verifyActives();
    }
  }
  ngOnDestroy(): void {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}
