import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CommonService } from '@common/services';
import { Observable, Subscription, of } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate, OnDestroy {
    private _subscription: Subscription[] = []

    constructor(
        private Router: Router,
        private common: CommonService,

    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const aux: any = (this.common.getSessionStorage('auth-data') ? this.common.getSessionStorage('auth-data') : this.common.getLocalStorage('auth-data'));
        if (aux) {
            return of(true);
        } else {
            this.common.delSessionStorage('auth-data');
            this.common.delSessionStorage('user-data');
            this.common.delLocalStorage('auth-data');
            this.common.delLocalStorage('user-data');
            if (state.url !== '/' && !state.url.includes('/auth')) {
                this.common.setSessionStorage('path-redirect-after-login', state.url);
            }
            this.Router.navigate(['/auth']);
            return of(false);
        }

    }
    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub.unsubscribe())
    }
}
