import { MockService } from './../modules/app-common/services/mock.service';
import { UpdateService } from './../modules/app-common/services/update.service';
import { AfterViewInit, Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { AppSettings } from '../modules/app-common/services/app-settings.service';
import { ChildActivationEnd, NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription, filter } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { AppService, CommonService, HttpRequestsService } from '@modules/app-common/services';
import { UserData } from '@modules/app-common/classes';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
	appEvent = new EventEmitter<string>();
	appLoaded: boolean = false;
	private subscriptions: Subscription[] = [];
	constructor(
		public appSettings: AppSettings,
		private Router: Router,
		private Title: Title,
		private AppService: AppService,
		private CommonService: CommonService,
		private UpdateService: UpdateService,
		private HttpRequestsService: HttpRequestsService,
		private MockService: MockService,
	) {
		this.Router.events.pipe(filter(ev => ev instanceof ChildActivationEnd))
			.subscribe((ev) => {
				let userData: UserData = this.CommonService.getSessionStorage('user-data') ? new UserData(this.CommonService.getSessionStorage('user-data')) : new UserData(this.CommonService.getLocalStorage('user-data'));
				let snapshot = (ev as ChildActivationEnd).snapshot;
				while (snapshot.firstChild !== null) {
					snapshot = snapshot.firstChild;
				}
				this.Title.setTitle(`${snapshot.data['title']}`);
				// this.verifyBtnAnimated();
			})
		this.Router.events.pipe(filter(ev => ev instanceof NavigationEnd))
			.subscribe((ev: any) => {
				let userData: UserData = this.CommonService.getSessionStorage('user-data') ? new UserData(this.CommonService.getSessionStorage('user-data')) : new UserData(this.CommonService.getLocalStorage('user-data'));
				if (this.AppService.courses.value) {
					/**
					 *TODO: REMOVER MOKING
					 */
					if (!this.AppService.courses.value.some(value => this.MockService.course_mock_ids.includes(value.id!))) { //? CURSO do "desafio"
						if (userData.incompleted && ev.urlAfterRedirects !== '/auth/login/complete') {
							this.Router.navigate(['auth/login/complete']);
						}
					}
				}
			})

	}
	@HostListener('contextmenu', ['$event'])
	onRightClick(event: any) {
		if (environment.production) {
			event.preventDefault();
		}
	}
	@HostListener('window:keydown', ['$event'])
	onKeyUp(event: KeyboardEvent) {
		if (environment.production) {
			if (event.key == 'F12' || (event.ctrlKey && event.shiftKey && (event.key == 'i' || event.key == 'I' || event.key == 'j' || event.key == 'J' || event.key == 'k' || event.key == 'K'))) {
				event.preventDefault()
			}
		}
	}
	// @HostListener('window:visibilitychange', ['$event'])
	// onClose(event: any) {
	// 	console.log(event)
	// }
	
	handleSetCover(coverClass: string) {
		var htmlElm = document.querySelector('html');
		if (htmlElm) {
			for (var x = 0; x < document.documentElement.classList.length; x++) {
				var targetClass = document.documentElement.classList[x];
				if (targetClass.search('bg-cover-') > -1) {
					htmlElm.classList.remove(targetClass);
				}
			}
			htmlElm.classList.add(coverClass);
		}
	}

	handleSetMode(mode: string) {
		document.documentElement.setAttribute('data-bs-theme', mode);
		this.appEvent.emit('theme-reload');
	}

	handleSetTheme(themeClass: string) {
		for (var x = 0; x < document.body.classList.length; x++) {
			var targetClass = document.body.classList[x];
			if (targetClass.search('theme-') > -1) {
				document.body.classList.remove(targetClass);
			}
		}
		document.body.classList.add(themeClass);
		this.appEvent.emit('theme-reload');
	}
	public updateSW() {
		window.location.href = window.location.href; //? Reload limpando o cache
	}
	public verifyAppVersion() {
		/**
		 ** Função deve comparar a versão do app com a versão do servidor, caso seja menor deve atualizar 
		*/
		this.subscriptions.push(
			this.HttpRequestsService.requestData('GET', 'info/platform/version', false)
			.subscribe({
				next: (res: any) => {
					const VERSION: string[] = this.CommonService.getAppVersion(false).split('.');
					const VERSION_SERVER: string[] = res.data.version.split('.');
					let new_version = false;
					let stop_verification = false;
					for (let i = 0; i < VERSION_SERVER.length && !stop_verification; i++) {
						if (VERSION[i] == undefined || Number(VERSION_SERVER[i]) > Number(VERSION[i])) {
							new_version = true;
						} else if (Number(VERSION_SERVER[i]) < Number(VERSION[i])) {
							stop_verification = true;
						}
					}
					// VERSION_SERVER.forEach((vs, idx) => {
					// 	if (VERSION[idx] == undefined || Number(vs) > Number(VERSION[idx])) {
					// 		new_version = true;
					// 	} else if (Number(vs) < Number(VERSION[idx])) {
					// 		new_version = false;
					// 	}
					// })
					if (new_version) {
						setTimeout(() => {
							this.appSettings.updateSW = true;
						}, 90000) // 1m 30s
					}
				}, error: (err: any) => {
					console.error(err)
				}
			})
		)
	}
	ngOnInit() {
		// this.FirebaseService.requestFirebasePermission();
		// this.FirebaseService.listenMessages();
		this.verifyAppVersion();
		let auth_data = this.CommonService.getLocalStorage('auth-data');
		if (!auth_data) auth_data = this.CommonService.getSessionStorage('auth-data');
		if (auth_data) {
			this.AppService.getListItems();
		}
		var elm = document.body;
		if (elm) {
			elm.classList.add('app-init');
		}
		if (this.appSettings.appMode) {
			this.handleSetMode(this.appSettings.appMode);
		}
		else if (localStorage['appMode']) {
			this.handleSetMode(localStorage['appMode']);
		}
		if (this.appSettings.appTheme) {
			this.handleSetTheme(this.appSettings.appTheme);
		}
		else if (localStorage['appTheme']) {
			this.handleSetTheme(localStorage['appTheme']);
		}
		if (this.appSettings.appCover) {
			this.handleSetCover(this.appSettings.appCover);
		}
		else if (localStorage['appCover']) {
			this.handleSetCover(localStorage['appCover']);
		}
		// this.FirebaseService.lastMessage.subscribe((x) => {
		// 	if (x && x.type == 'lab') {
		// 		/**
		// 		 ** Criar o lab de qualque lugar da plataforma
		// 		 */
		// 		if (this.AppService.data_alias_label !== `labs/iniciar/${this.AppService.lab_initialing_session.value?.labAlias}`) {
		// 			this.AppService.startLabSession({
		// 				pwd: x.pwd,
		// 				url: x.url,
		// 				user: x.user,
		// 			});
		// 		}
		// 	}
		// })
	}
	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}
	ngAfterViewInit() {
		this.appLoaded = true;
	}
}
