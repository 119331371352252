import { Injectable } from '@angular/core';
// import { getToken, getMessaging, onMessage } from 'firebase/messaging'
// import { environment } from '../../../environments/environment';
import { CommonService } from './common.service';
// import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class FirebaseService {
	// public lastMessage: BehaviorSubject<any> = new BehaviorSubject<any>({});
	// public messageHistoric: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	constructor(
		private CommonService: CommonService
	) {
	}

	// public requestFirebasePermission() {
	// 	Notification.requestPermission();
	// 	const messaging = getMessaging();
	// 	getToken(messaging,
	// 		{ vapidKey: environment.firebaseConfig.vapidKey }).then(
	// 			(currentToken) => {
	// 				//? curenToken == "deviceId"
	// 				if (currentToken) {
	// 					this.CommonService.setLocalStorage('device-id', currentToken)
	// 				} else {
	// 					console.error('Não foi possível obter um token válido.');
	// 				}
	// 			}).catch((err) => {
	// 				console.error('Ocorre um erro na criação do token.', err);
	// 			});
	// }
	// public listenMessages() {
	// 	/**
	// 	 ** Receber o SNS enviado pelo Firebase
	// 	 */
	// 	const message = getMessaging();
	// 	onMessage(message, (res: any) => {
	// 		const dataJSON = JSON.parse(res?.data?.default) //? Recebe resposta do Firebase e tranforma-la em JSON
	// 		this.lastMessage.next(dataJSON) //? Salva a ultima mensagem recebida
	// 		this.messageHistoric.next([...this.messageHistoric.value, dataJSON]) //? Salva a ultima mensagem recebida
	// 	})
	// }
}
