import { Component, OnInit, Input, EventEmitter, Output, ViewChild, OnChanges } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { CommonService } from '@common/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-input-select',
    templateUrl: './input-select.component.html',
    styleUrls: ['input-select.component.scss'],
})
export class InputSelectComponent implements OnInit {
    @ViewChild('ngInput') ngInput!: NgModel

    // ? Tag input
    @Input() name?: string
    @Input() required?: boolean = true;
    @Input() placeholder?: string = 'Selecione...'
    @Input() disabled?: boolean = false
    @Input() multiple?: boolean = false
    @Input() allowClear?: boolean = false
    @Input() closeOnSelect?: boolean = true
    @Input() maxlength?: number = 300
    @Input() minlength?: number = 1
    @Input() optionValueField!: string
    @Input() ngOptions?: any
    @Input() optionTextField!: string
    @Input() items!: any[]
    // @Input() size?: 'sm' | 'md' | 'lg' = 'lg'
    @Input() classes?: string
    @Output() selectionChanges?: EventEmitter<any> = new EventEmitter<any>()
    @Output() remove?: EventEmitter<any> = new EventEmitter<any>()
    @Output() clear?: EventEmitter<any> = new EventEmitter<any>()
    @Output() add?: EventEmitter<any> = new EventEmitter<any>()
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>()
    // ?

    @Input() label!: string
    @Input() permission?: string;
    @Input() ngForm!: NgForm
    @Input() value?: string
    @Input() menuName?: string
    @Input() loading?: boolean = false;
    @Input() isTag?: boolean = false;
    @Input() isCheckbox?: boolean = false;
    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

    public isDarkMode: boolean = false;
    public search: string = "";
    private subscriptions: Subscription[] = [];
    public isMaleWord: boolean = true;
    public routeNew: string = "";
    public permissionPrecedence: number = 4;
    constructor(
        private CommonService: CommonService

    ) { }
    public onInputChange(ev: any) {
        this.selectionChanges?.emit(ev)
        this.onChange?.emit(ev)
    }
    public onInputAdd(ev: any) {
        this.add?.emit(ev)
    }
    public onRemove(ev: any) {
        this.remove?.emit(ev)
    }
    public onClear(ev: any) {
        this.clear?.emit(ev)
    }
    private verifyGender(word: string): boolean {
    //     /**
    //      ** Verificar se o gênero da palavra é masculino ou feminino
    //      */
        if (word[word.length - 1] === 'a') {
            return false;
        }
        else {
            return true;
        }
    }

    ngOnInit() {
        if (!this.name) {
            this.name = this.label?.trim().toLowerCase().normalize('NFC').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '-');
        }
        this.isMaleWord = this.verifyGender(this.label);
        this.subscriptions.push(
            this.CommonService.isLightMode.subscribe({
                next: (light: boolean) => {
                    this.isDarkMode = !light;
                }
            })
        );
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.ngForm.addControl(this.ngInput)
        }, 0)
    }
}
