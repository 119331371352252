<div class="fixed-bottom shadow-lg cookies rounded-1 bg-dark bg-opacity-95"
	[ngClass]="{'disabled-cookies':isPolictAccepted}">
	<div class="py-3 px-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
		<div class="d-flex flex-column text-center text-md-start">
			<small>
				Este site armazena dados como cookies para habilitar funcionalidades essenciais do site, bem como
                    personalização e análises.
				<br>
				<a class="more-info fw-bold"
					target="_self"
					routerLink="/home/politica-de-privacidade">
					Conheça nossa Política de Privacidade e Cookies.
				</a>
			</small>
		</div>
		<div class="mt-3 mt-md-0">
			<button class="btn btn-theme w-120px"
				(click)="confirmCookie()">
				Entendi
			</button>
		</div>
	</div>
</div>
