<div class="card-toast p-3 mb-2" [ngClass]="{'border-success': toastPackage.toastType == 'toast-success', 'border-warning': toastPackage.toastType == 'toast-warning', 'border-danger': toastPackage.toastType == 'toast-error', 'border-info': toastPackage.toastType == 'toast-info'}">
    <div *ngIf="title" [class]="'d-flex justify-content-between align-items-center font-toast font-weight-500 ' + options.titleClass" [attr.aria-label]="title">
        <div class="d-flex justify-content-around align-items-center">
            <fa-icon class="icon-size-md text-secondary" [ngClass]="{'text-success': toastPackage.toastType == 'toast-success', 'text-warning': toastPackage.toastType == 'toast-warning', 'text-danger': toastPackage.toastType == 'toast-error', 'text-info': toastPackage.toastType == 'toast-info'}" [icon]="['fas',icon]"></fa-icon>
            <span class="ms-2" [innerHTML]="title | noSanitize"></span>
        </div>
        <a *ngIf="options.closeButton" (click)="remove()" class="btn btn-transparent-dark btn-icon btn-sm">
            <fa-icon [icon]="['fas','x']"></fa-icon>
        </a>
    </div>
    <hr *ngIf="title" class="my-1 border-primary" [ngClass]="{'border-success': toastPackage.toastType == 'toast-success', 'border-warning': toastPackage.toastType == 'toast-warning', 'border-danger': toastPackage.toastType == 'toast-error', 'border-info': toastPackage.toastType == 'toast-info'}">
    <div *ngIf="message" role="alert" aria-live="polite" class="d-flex justify-content-start align-items-center" >
        <fa-icon *ngIf="!title" class="me-2 icon-toast text-secondary" [ngClass]="{'text-success': toastPackage.toastType == 'toast-success', 'text-warning': toastPackage.toastType == 'toast-warning', 'text-danger': toastPackage.toastType == 'toast-error', 'text-info': toastPackage.toastType == 'toast-info'}" [icon]="['fas',icon]"></fa-icon>
        <div [class]="'font-toast font-weight-400 ' + options.messageClass" [innerHTML]="message | noSanitize"></div>
    </div>
</div>
<div *ngIf="options.progressBar">
    <div class="toast-progress ms-2" [style.width]="width + '%'"></div>
</div>