<div class="d-flex ">
    <div [class]="'badge-amount '+'badge-amount-'+size" [style]="'--index:'+index">
        <ng-container *ngIf="carregando">
            <div class="placeholder-glow w-100 p-1">
                <div class="placeholder w-100 h-100 shadow"></div>
            </div>
        </ng-container>
        <ng-container *ngIf="!carregando">
            <ng-container *ngIf="!moreBadges">
                <img class="w-100" [src]="badge[imageAtribute]" alt="badge">
            </ng-container>
            <ng-container *ngIf="moreBadges">
                <div class="badge-custom-image d-inline-block position-relative h-100 w-100" role="button" (click)="onBadgeClick()">
                    <img class="w-100" src="../../../../assets/img/badge/reticencias.png" alt="badge">
                    <img class="hover position-absolute w-100" src="../../../../assets/img/badge/reticencias-hover.png" alt="badge">
                </div>
            </ng-container>
        </ng-container>
    </div>
    <!-- <ng-container *ngIf="badges[index + 1] && ( maxLenght ? (index + 1) <= maxLenght : true )">
        <div class="next-badge-amount" >
            <app-badge [badges]="badges" [index]="index + 1" [size]="size" [imageAtribute]="imageAtribute" [carregando]="carregando"></app-badge>
        </div>
    </ng-container>
</div> -->
