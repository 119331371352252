<div [class]="'lab-card d-flex align-items-center shadow-sm rounded-3 px-4 w-100 overflow-hidden bg-altern '+classes" #labRef [ngClass]="{'locked bg-opacity-75':lab.locked}" (click)="goTo()">
    <div class="w-100">
        <div class="row lab-details align-items-center h-100">
            <div class="" [ngClass]="{'col-9':!labDiff,'col-7':labDiff}">
                <div class="d-flex align-items-center gap-3 position-relative">
                    <div class="lab-icon py-2">
                        <fa-icon class="fa-2x" [ngClass]="{'text-warning':lab.onProgress && !lab.locked,'text-inverse':!lab.onProgress && !lab.locked,'text-gray-400':lab.locked}" [icon]="['fas',lab.locked ? 'lock' : 'flask']"></fa-icon>
                    </div>
                    <div class="d-flex flex-column justify-content-center w-90 py-2">
                        <div class="lab-title ">
                            <span class="d-block fs-15px fw-bold lh-sm" [ngClass]="{'text-gray-400':lab.locked}"  [innerHTML]="lab.name | noSanitize"></span>
                        </div>
                        <ng-container *ngIf="courseTitle">
                            <div class="lab-course">
                                <small class=" fw-bold limit-line-1 text-inverse text-opacity-60" [ngClass]="{'text-gray-300':lab.locked}" [style]="'white-space:nowrap'" [innerHTML]="lab.courseTitle | noSanitize"></small>  
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-1">
                <div class="lab-hour py-2 h-100">
                    <div class="d-flex align-items-center gap-1 lh-1 h-100">
                        <fa-icon class="fa-sm d-flex text-inverse text-opacity-50" [ngClass]="{'text-gray-400':lab.locked}" [icon]="['fas','clock']" [fixedWidth]="true"></fa-icon>
                        <span class="text-inverse text-opacity-60" [ngClass]="{'text-gray-400':lab.locked}" style="white-space: nowrap;">{{getHr(lab.duration!)}}</span>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="labDiff">
                <div class="col-2">
                    <div class="d-flex align-items-center justify-content-center gap-1 h-100">
                        <span class="text-inverse text-opacity-60"  [ngClass]="{'text-gray-400':lab.locked}" [innerHTML]="lab.difficulty | noSanitize"></span>
                    </div>
                </div>
            </ng-container>
            <div class="col-2">
                <div class="d-flex justify-content-center align-items-center gap-1 h-100" >
                    <ng-container *ngIf="lab.onProgress && !lab.locked">
                        <div class="d-flex align-items-center gap-1">
                            <fa-icon class="text-warning text-opacity-70" [icon]="['fas','flask']"></fa-icon>
                            <span class="text-opacity-60 text-warning">Em Andamento</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!lab.onProgress && !lab.locked && !lab.onInialing && !lab.totalTries">
                        <div class="d-flex align-items-center gap-1">
                            <fa-icon class="text-blue-400 text-opacity-70" [icon]="['fas','check']"></fa-icon>
                            <span class="text-opacity-60 text-blue-400">Disponivel</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!lab.onProgress && !lab.locked && !lab.onInialing && lab.totalTries">
                        <div class="d-flex align-items-center gap-1">
                            <fa-icon class="text-success text-opacity-70" [icon]="['fas','check']"></fa-icon>
                            <span class="text-opacity-60 text-success">Concluído</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!lab.onProgress && !lab.locked && lab.onInialing">
                        <div class="d-flex align-items-center gap-1">
                            <fa-icon class="text-theme text-opacity-80" [icon]="['fas','spinner']"></fa-icon>
                            <span class="text-opacity-70 text-theme">Iniciando</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="lab.locked">
                        <div class="d-flex align-items-center gap-1">
                            <fa-icon class="text-gray-400 text-opacity-70" [icon]="['fas','lock']"></fa-icon>
                            <span class="text-opacity-60 text-gray-400">Bloqueado</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>