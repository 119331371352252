import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CourseClasse } from '@modules/app-common/classes';

@Component({
  selector: 'app-classe-card',
  templateUrl: './classe-card.component.html',
  styleUrls: ['./classe-card.component.scss']
})
export class ClasseCardComponent implements OnInit{
  @ViewChild('classeRef') _classeRef!: ElementRef
  @Input() classe: CourseClasse = new CourseClasse();
  @Input() collectionAlias?: string;
  @Input() classes:string = '';

  public getHr(ms: number): string {
    const SEC = Math.floor(ms / 1000);
    const MIN = Math.floor(SEC / 60);
    const HR = Math.floor(MIN / 60);
    if (HR > 0) {
      return `${HR}h ${(MIN % 60) > 0 ? (MIN % 60) + 'min' : ''}`;
    }
    else if (MIN > 0) {
      return `${MIN}min ${(SEC % 60) > 0 ? `${SEC % 60}s` : ''}`;
    } else {
      return `${SEC}s`;
    }
  }
  public goRoute() {
    // let sub: Subscription = new Subscription();
    // sub = this.AppService.courses.subscribe(courses => {
    //   if (courses) {
    //     const COURSE = courses.find((c) => c.id == this.module.courseId);
    //     this.Router.navigate([`/curso/simulado/${COURSE?.alias}/${this.module.alias}`]);
    //     sub.unsubscribe();
    //   }
    // })
  }
  ngOnInit(): void {
    setTimeout(() => {
      if (this._classeRef.nativeElement && !this.classe.locked) {
        this._classeRef.nativeElement.addEventListener('mousemove', (ev: any) => {
          const X = ev.layerX + 20;
          const Y = ev.layerY;
          this._classeRef.nativeElement.style.setProperty('--mouse-x', `${X}px`);
          this._classeRef.nativeElement.style.setProperty('--mouse-y', `${Y}px`);
        })
        this._classeRef.nativeElement.addEventListener('mouseenter', (ev: any) => {
          this._classeRef.nativeElement.style.setProperty('--opacity', 1);
        })
        this._classeRef.nativeElement.addEventListener('mouseleave', (ev: any) => {
          this._classeRef.nativeElement.style.setProperty('--opacity', 0);
        })
      }
    }, 1)
  }
}

