<!-- BEGIN #app -->
<div id="app" class="app" [ngClass]="{
	'd-none': !appLoaded,
	'app-without-header': appSettings.appHeaderNone,
	'app-without-sidebar': appSettings.appSidebarNone,
	'app-boxed-layout': appSettings.appBoxedLayout,
	'app-sidebar-collapsed': appSettings.appSidebarCollapsed,
	'app-content-full-height': appSettings.appContentFullHeight,
	'app-content-full-width': appSettings.appContentFullWidth,
	'app-footer-fixed': appSettings.appFooter
}">

  <!-- <header *ngIf="!appSettings.appHeaderNone"></header> -->

  <!-- <sidebar *ngIf="!appSettings.appSidebarNone"></sidebar> -->
  
  <!-- <sidebar-mobile-backdrop *ngIf="!appSettings.appSidebarNone"></sidebar-mobile-backdrop> -->

  <div id="content" class="app-content p-0" [class]="appSettings.appContentClass">
    <router-outlet></router-outlet>
  </div>
  <ng-container *ngIf="appSettings.updateSW">
    <div class="update-sw-button rounded-5">
      <button class="btn animate-ripple rounded-5 overflow-hidden border-0 p-0" (click)="updateSW()">
        <div class="d-flex">
          <div class="icon-update d-flex justify-content-center align-items-center bg-theme rounded-circle">
            <fa-icon class="fa-xl" [icon]="['fas','rotate-right']"></fa-icon>
          </div>
          <div class="update-text d-flex flex-column bg-theme pe-3">
            <div class="">
              <span class="fw-bold text-gray-100">Atualizar</span>
            </div>
            <hr class="my-0">
            <div class="">
              <small class="text-gray-100">Nova versão disponível</small>
            </div>
          </div>
        </div>
      </button>
    </div>
  </ng-container>
  
  <!-- <footer *ngIf="appSettings.appFooter"></footer> -->
  
  <!-- <theme-panel></theme-panel> -->
</div>
<!-- END #app -->
