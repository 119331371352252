import { KeenSliderInstance } from 'keen-slider'

export class SliderObject {
    slider?: KeenSliderInstance;
    rel?: number = 0
    slider_lenght?: number = 0
    per_view?: number = 3
    constructor(config: any) {
        this.slider = (config.slider !== undefined) ? config.slider : this.slider;
        this.rel = (config.rel !== undefined) ? config.rel : this.rel;
        this.slider_lenght = (config.slider_lenght !== undefined) ? config.slider_lenght : this.slider_lenght;
        this.per_view = (config.per_view !== undefined) ? config.per_view : this.per_view;
    }
    public get finalRel(): number {
        // const is_ceil = Number(this.per_view!.toString().split('.')[1]) >= 5;
        // if (is_ceil) {
        //     return Math.ceil(this.slider_lenght! / this.per_view!)
        // } else {
            // return Math.ceil(this.slider_lenght! / this.per_view!)
            // }
            return Math.ceil(this.slider_lenght! - this.per_view!)
    }
}