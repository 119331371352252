<div class="classes d-flex align-items-start gap-2" [ngClass]="{'watched':classe.watched,'active':classe.actived || (classActive && classActive === classe.id),'locked':classe.locked}"  role="button">
    <div class="watched">
       <div class="watched-marker rounded-1" (click)="onClickWatched(classe)"></div>
       <ng-container *ngIf="classe.watched">
           <div class="check-svg" (click)="onClickWatched(classe)">
               <svg  viewBox="0 0 352.62 352.62" xml:space="preserve">
                   <g>
                       <path d="M337.222,22.952c-15.912-8.568-33.66,7.956-44.064,17.748c-23.867,23.256-44.063,50.184-66.708,74.664
                           c-25.092,26.928-48.348,53.856-74.052,80.173c-14.688,14.688-30.6,30.6-40.392,48.96c-22.032-21.421-41.004-44.677-65.484-63.648
                           c-17.748-13.464-47.124-23.256-46.512,9.18c1.224,42.229,38.556,87.517,66.096,116.28c11.628,12.24,26.928,25.092,44.676,25.704
                           c21.42,1.224,43.452-24.48,56.304-38.556c22.645-24.48,41.005-52.021,61.812-77.112c26.928-33.048,54.468-65.485,80.784-99.145
                           C326.206,96.392,378.226,44.983,337.222,22.952z M26.937,187.581c-0.612,0-1.224,0-2.448,0.611
                           c-2.448-0.611-4.284-1.224-6.732-2.448l0,0C19.593,184.52,22.653,185.132,26.937,187.581z"/>
                   </g>
               </svg>
           </div>
       </ng-container>
    </div>
    <div class="name d-flex align-items-start flex-column"  (click)="onClickClass(classe)">
       <div class="d-flex align-items-start gap-2 lh-sm">
           <ng-container *ngIf="!classe.duration && !classe.locked">
               <ng-container *ngIf="classe.type === 'video'">
                   <fa-icon [icon]="['fas','film']"></fa-icon>
               </ng-container>
               <ng-container *ngIf="classe.type === 'quiz'">
                   <fa-icon [icon]="['fas','rocket']"></fa-icon>
               </ng-container>
               <ng-container *ngIf="classe.type === 'text'">
                   <fa-icon [icon]="['fas','file-lines']"></fa-icon>
               </ng-container>
               <ng-container *ngIf="classe.type === 'pdf'">
                   <fa-icon [icon]="['fas','file-pdf']"></fa-icon>
               </ng-container>
               <ng-container *ngIf="classe.type === 'lab'">
                   <fa-icon [icon]="['fas','flask']"></fa-icon>
               </ng-container>
               <ng-container *ngIf="classe.type === 'flashcard'">
                   <fa-icon [icon]="['fas','bolt-lightning']"></fa-icon>
               </ng-container>
               <ng-container *ngIf="classe.type === 'practice'">
                   <fa-icon [icon]="['fas','book']"></fa-icon>
               </ng-container>
           </ng-container>
           <ng-container *ngIf="!classe.locked">
               <small [innerHTML]="classe.name | noSanitize"></small>
           </ng-container>
       </div>
       <ng-container *ngIf="classe.duration && !classe.locked">
           <div class="d-flex align-items-center gap-1 small">
               <ng-container *ngIf="classe.type === 'video'">
                   <fa-icon class="opacity-75" [icon]="['fas','film']"></fa-icon>
               </ng-container>
               <ng-container *ngIf="classe.type === 'quiz'">
                   <fa-icon class="opacity-75" [icon]="['fas','rocket']"></fa-icon>
               </ng-container>
               <ng-container *ngIf="classe.type === 'lab'">
                   <fa-icon class="opacity-75" [icon]="['fas','flask']"></fa-icon>
               </ng-container>
               <ng-container *ngIf="classe.type === 'text'">
                   <fa-icon class="opacity-75" [icon]="['fas','file-lines']"></fa-icon>
               </ng-container>
               <small class="opacity-75" [innerHTML]="getTime(classe.duration)"></small>                                       
           </div>
       </ng-container>
       <ng-container *ngIf="classe.locked">
           <div class="d-flex align-items-center gap-1 small text-gray-400">
               <fa-icon class="opacity-75 text-gray-400" [icon]="['fas','lock']"></fa-icon>
               <small class="text-gray-400" [innerHTML]="classe.name | noSanitize"></small>
           </div>
       </ng-container>
    </div>
    <ng-container *ngIf="index < module.classes.length - 1">
       <div class="line-down" [ngClass]="{'watched':classe.watched && module.classes[index + 1] && module.classes[index + 1].watched }"></div>
   </ng-container>
</div>