import { AppService } from '@modules/app-common/services';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { LabInitCache, RouteSession } from '@classes';
import { CommonService } from '../../services';
import { AppSettings } from '../../services/app-settings.service';

@Component({
	selector: 'app-nav-header',
	templateUrl: './nav-header.component.html',
	styleUrls: ['./nav-header.component.scss']
})
export class NavHeaderComponent implements OnInit, OnDestroy {
	private subscriptions: Subscription[] = [];
	public route: string = '/';
	public lab_init?: LabInitCache;

	public show_lab_popup?: boolean = false;
	public active_lab_popup?: boolean = false;
	constructor(
		private Router: Router,
		private Title: Title,
		public CommonService: CommonService,
		public AppSettings: AppSettings,
		public AppService: AppService
	) {
		this.subscriptions.push(
			this.Router.events.pipe(filter(ev => ev instanceof NavigationEnd))
				.subscribe((ev) => {
					this.route = (ev as NavigationEnd).urlAfterRedirects;
					const payload: RouteSession = {
						text: this.Title.getTitle().split('|')[0],
						path: { ...(ev as NavigationEnd) }.urlAfterRedirects
					}
					if (payload.text) {
						this.toggleRouteSession(payload);
					}
				})
		)
	}
	public toggleRouteSession(rs: RouteSession) {
		let route_session: RouteSession[] = this.CommonService.getSessionStorage('route-session');
		const COUNT_BAR: number = rs.path.split('/').length;
		if (route_session) {
			route_session = route_session.slice(0, COUNT_BAR)
			route_session[COUNT_BAR - 1] = rs;
			this.CommonService.route_session = route_session;
			this.CommonService.setSessionStorage('route-session', this.CommonService.route_session);

		} else {
			this.CommonService.route_session[COUNT_BAR - 1] = rs;
			this.CommonService.setSessionStorage('route-session', this.CommonService.route_session);
		}
	}
	public getZIndex(idx: number): number {
		return this.CommonService.route_session.length - idx;
	}
	public getLabSession() {
		this.subscriptions.push(
			this.AppService.labs_session_started.subscribe((lab) => {
				if (lab) {
					const showLabInit = (lab_init: LabInitCache) => {
						if (lab_init) {
							this.show_lab_popup = true;
							if (this.AppSettings.activeLabsPopover) {
								setTimeout(() => {
									this.active_lab_popup = true;
									setTimeout(() => {
										this.active_lab_popup = false;
										this.AppSettings.activeLabsPopover = false;
									}, 8000)
								}, 2000)
							}
						}
					}
					let lab_init_cache = this.CommonService.getLocalStorage('lab-init');
					if (lab_init_cache) {
						this.lab_init = lab_init_cache;
						showLabInit(lab_init_cache);
					} else {
						setTimeout(() => {
							let lab_init_cache_02 = this.CommonService.getLocalStorage('lab-init');
							this.lab_init = lab_init_cache_02;
							showLabInit(lab_init_cache_02);
						}, 3000)
					}
				} else {
					this.lab_init = undefined
					this.active_lab_popup = false;
					this.show_lab_popup = false;
					this.AppSettings.activeLabsPopover = true;
				}
			})
		)
	}
	public goToLab() {
		this.Router.navigate([`/laboratorio/iniciar/${this.lab_init?.labAlias}`])
	}
	ngOnInit(): void {
		this.getLabSession();
	}
	ngOnDestroy(): void {
		this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
	}
}
