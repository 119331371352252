<div [class]="'rank-card d-flex align-items-center shadow-sm rounded-3 px-4 w-100 overflow-hidden'+classes" [style]="!disabledRoute && 'cursor:pointer'" #rankRef (click)="goTo()">
    <div class="w-100">
        <div class="row align-items-center h-100">
            <div class="col-11">
                <div class="d-flex gap-2">
                    <div class="d-flex align-items-center gap-2">
                        <div class="">
                            <span class="fw-bold fs-5">#{{position}}</span>
                        </div>
                        <ng-container *ngIf="user?.imgStudent">
                            <div class="rank-user rounded-circle" [style]="'background-image:url('+user.imgStudent+')'"></div>
                        </ng-container>
                        <ng-container *ngIf="!user?.imgStudent">
                            <div class="rank-user d-flex justify-content-center align-items-center bg-altern-800 rounded-circle fw-bold">{{getinitials()}}</div>
                        </ng-container>
                    </div>
                    <div class="d-flex flex-column justify-content-center py-2">
                        <div class="rank-name ">
                            <span class="d-block fw-semibold fs-15px lh-sm  limit-line-1"  [innerHTML]="user.nameStudent | noSanitize"></span>
                        </div>
                        <ng-container *ngIf="user?.username">
                            <small class="fw-semibold limit-line-1 text-inverse text-opacity-60  limit-line-1" [style]="'white-space:nowrap'" [innerHTML]="user.username | noSanitize"></small>  
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-1" style="z-index: 10;">
                <div class="d-flex justify-content-center">
                    <ng-container *ngIf="!byIndex">
                        <span class="fw-semibold text-inverse fs-15px">{{user.points}}<small>pt</small></span>
                    </ng-container>
                    <ng-container *ngIf="byIndex">
                        <span class="fw-semibold text-inverse fs-15px">{{user.index}}<small>pt</small></span>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>