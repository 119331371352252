// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiKey: "mP3G2UmO631DEWpyxjfLA716vWlkpbdWAV3kvm47",
  apiUrl: "https://api-dev.cloudfaster.academy/sandbox/",
  productName: "Plataforma CloudFaster Academy",
  firebaseConfig: {
    apiKey: "AIzaSyD2S1U4mDHmf1s2kw95JRkaoaUOgMbaH00",
    authDomain: "plataforma-ead-391716.firebaseapp.com",
    projectId: "plataforma-ead-391716",
    storageBucket: "plataforma-ead-391716.appspot.com",
    messagingSenderId: "482598413362",
    appId: "1:482598413362:web:542680b89db9f75bdb882b",
    measurementId: "G-K6SYS5MS6S",
    vapidKey: 'BECGjrkm44nIZJ3j7mqyNwVj78I5keJz4_cigYnY_fFrL_7FP5_gPO9qxOH-uWgp_4wcOvlld_gBDOssjb-dwKY'
  },
  rollbar:{
    accessToken: '0c199602667346f1bf09ba5f578dd69f',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload:{
      environment: 'development'
    }
  },
  productor: "0c2586b8-7123-4a5d-b0f0-cc4b3a8b0d0b",
  productorTable: "cloudfaster"
};

