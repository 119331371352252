/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=solid&m=free */

import {
    faAngleDown,
    faAngleRight,
    faArrowLeft,
    faArrowRight,
    faBars,
    faBookOpen,
    faChartArea,
    faChartBar,
    faChartPie,
    faCheck,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faCircle,
    faColumns,
    faDollarSign,
    faExclamation,
    faExclamationTriangle,
    faFlag,
    faLongArrowAltRight,
    faMousePointer,
    faPercentage,
    faPlus,
    faPlusCircle,
    faSearch,
    faTable,
    faTachometerAlt,
    faTag,
    faTimes,
    faUser,
    faBuilding,
    faCheckCircle,
    faRocket,
    faFolder,
    faFilePdf,
    faFileImage,
    faFileAudio,
    faFileVideo,
    faFileWord,
    faFileExcel,
    faFilePowerpoint,
    faPencilRuler,
    faFile,
    faFileArchive,
    faFileAlt,
    faFolderOpen,
    faTrashAlt,
    faFolderPlus,
    faStar,
    faEdit,
    faPlay,
    faPaperclip,
    faHashtag,
    faPause,
    faXmark,
    faBell,
    faCircleExclamation,
    faTriangleExclamation,
    faInfo,
    faSun,
    faMoon,
    faCircleInfo,
    faEye,
    faEyeSlash,
    faPen,
    faLocationPin,
    faHouseChimneyUser,
    faIdCard,
    faCircleQuestion,
    faChevronLeft,
    faFaceMehBlank,
    faMapLocationDot,
    faCameraRetro,
    faHouse,
    faArrowRightFromBracket,
    faUserGear,
    faClipboard,
    faX,
    faEllipsisV,
    faCircleXmark,
    faInfoCircle,
    faPaperPlane,
    faLocationArrow,
    faFileLines,
    faCamera,
    faTicket,
    faListUl,
    faEllipsisVertical,
    faGear,
    faPeopleGroup,
    faBook,
    faCaretLeft,
    faCaretRight,
    faQuoteRight,
    faComments,
    faPenToSquare,
    faFilm,
    faFire,
    faTrophy,
    faRightFromBracket,
    faLink,
    faLock,
    faBarsProgress,
    faCalculator,
    faMinus,
    faRightToBracket,
    faRotateLeft,
    faCertificate,
    faSquare,
    faAddressCard,
    faFileArrowDown,
    faThumbsUp,
    faThumbsDown,
    faClock,
    faExpand,
    faCompress,
    faListCheck,
    faCalendar,
    faFlask,
    faSpinner,
    faLayerGroup,
    faRotateRight,
    faCopy,
    faArrowUpRightFromSquare,
    faBullseye,
    faMagnifyingGlass,
    faLocationDot,
    faEnvelope,
    faAnglesUp,
    faLifeRing,
    faReply,
    faBold,
    faCode,
    faItalic,
    faUnderline,
    faStrikethrough,
    faSuperscript,
    faSubscript,
    faListOl,
    faUnlink,
    faStopwatch,
    faWindowRestore,
    faTv,
    faSliders,
    faRankingStar,
    faBoltLightning,
    faFilePen,
    faFileZipper,
    faArrowUpFromBracket,
    faCloudArrowDown,
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeSolidIcons = {
    faAngleDown,
    faAngleRight,
    faArrowLeft,
    faArrowRight,
    faBars,
    faBookOpen,
    faChartArea,
    faChartBar,
    faChartPie,
    faCheck,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faCircle,
    faColumns,
    faDollarSign,
    faExclamation,
    faExclamationTriangle,
    faFlag,
    faLongArrowAltRight,
    faMousePointer,
    faPercentage,
    faPlus,
    faPlusCircle,
    faSearch,
    faTable,
    faTachometerAlt,
    faTag,
    faTimes,
    faUser,
    faBuilding,
    faCheckCircle,
    faRocket,
    faFolder,
    faFilePdf,
    faFileImage,
    faFileAudio,
    faFileVideo,
    faFileWord,
    faFileExcel,
    faFilePowerpoint,
    faPencilRuler,
    faFile,
    faFileArchive,
    faFileAlt,
    faFolderOpen,
    faTrashAlt,
    faFolderPlus,
    faStar,
    faEdit,
    faPlay,
    faPaperclip,
    faHashtag,
    faPause,
    faXmark,
    faBell,
    faCircleExclamation,
    faTriangleExclamation,
    faInfo,
    faSun,
    faMoon,
    faCircleInfo,
    faEye,
    faEyeSlash,
    faPen,
    faLocationPin,
    faHouseChimneyUser,
    faIdCard,
    faCircleQuestion,
    faChevronLeft,
    faFaceMehBlank,
    faMapLocationDot,
    faCameraRetro,
    faHouse,
    faArrowRightFromBracket,
    faUserGear,
    faClipboard,
    faX,
    faEllipsisV,
    faCircleXmark,
    faInfoCircle,
    faPaperPlane,
    faLocationArrow,
    faFileLines,
    faCamera,
    faTicket,
    faListUl,
    faEllipsisVertical,
    faGear,
    faPeopleGroup,
    faBook,
    faCaretLeft,
    faCaretRight,
    faQuoteRight,
    faComments,
    faPenToSquare,
    faFilm,
    faFire,
    faTrophy,
    faRightFromBracket,
    faLink,
    faLock,
    faBarsProgress,
    faCalculator,
    faMinus,
    faRightToBracket,
    faRotateLeft,
    faCertificate,
    faSquare,
    faAddressCard,
    faFileArrowDown,
    faThumbsUp,
    faThumbsDown,
    faClock,
    faExpand,
    faCompress,
    faListCheck,
    faCalendar,
    faFlask,
    faSpinner,
    faLayerGroup,
    faRotateRight,
    faCopy,
    faArrowUpRightFromSquare,
    faBullseye,
    faMagnifyingGlass,
    faLocationDot,
    faEnvelope,
    faAnglesUp,
    faLifeRing,
    faReply,
    faBold,
    faCode,
    faItalic,
    faUnderline,
    faStrikethrough,
    faSuperscript,
    faSubscript,
    faListOl,
    faUnlink,
    faStopwatch,
    faWindowRestore,
    faTv,
    faSliders,
    faRankingStar,
    faBoltLightning,
    faFilePen,
    faFileZipper,
    faArrowUpFromBracket,
    faCloudArrowDown,

};