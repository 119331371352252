<div class="w-100 position-relative">
    <div class="search-icon d-flex justify-content-center align-items-center position-absolute h-100 " [ngClass]="{'opacity-50':value?.length! < minlength!}">
        <fa-icon class="d-flex" [icon]="['fas','magnifying-glass']"></fa-icon>
    </div>
    <input 
    class="search-input form-control form-control-lg rounded-4 " 
    autocomplete="off" 
    #ngInput="ngModel" 
    [id]="'search'" 
    [name]="'search'" 
    [type]="'text'" 
    [minlength]="minlength!" 
    [maxlength]="maxlength!" 
    [(ngModel)]="value" 
    [disabled]="disabled!" 
    [placeholder]="placeholder" 
    (ngModelChange)="onInputChange()" 
    (blur)="blur.emit($event)" 
    (focus)="focus.emit($event)" 
    (change)="onChange.emit($event)"  
    />
    <div class=" d-flex justify-content-center align-items-center position-absolute h-100 " role="button" [ngClass]="{'clear-icon':value?.length! >= minlength!,'opacity-0':value?.length! < minlength!}" (click)="value = ''">
        <fa-icon class="d-flex text-danger" [icon]="['fas','times']"></fa-icon>
    </div>
</div>