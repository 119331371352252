import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-difficulty',
  templateUrl: './difficulty.component.html',
  styleUrls: ['./difficulty.component.scss']
})
export class DifficultyComponent implements OnInit {

  @Input() nivel!: number;
  @Input() size: string = "1rem";
  @Input() stageStyle: 'default' | 'gray' = 'default';
  public nivel_div: number[] = [];
  private getNivelDiv() {
    const NIVEL_DIV = this.nivel.toString().split('.');
    const DECIMAL = Number("0."+NIVEL_DIV[1]);
    for (let i = 0; i < Number(NIVEL_DIV[0]); i++) {
      this.nivel_div.push(1);
    }
    if(DECIMAL){
      this.nivel_div.push(DECIMAL);
    }
  }
  ngOnInit(): void {
    this.getNivelDiv();
  }
}
