import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppSettings } from './';
import { AppVariablesService } from './app-variables.service';
import { RouteSession } from '../classes';

@Injectable()
export class CommonService {
    // versao do app
    public appVersion = '1.5.78';
    // Criando o padrao para verificacao de erro de API
    private httpResponse = new BehaviorSubject(200);
    public httpResponseAtual = this.httpResponse.asObservable();
    // Criando o padrao para a variavel "isAuthenticated" do AppComponent
    private isAuthenticatedPadrao = new BehaviorSubject(false);
    public isAuthenticatedAtual = this.isAuthenticatedPadrao.asObservable();
    // variavel do tema escuro ou claro
    public isLightMode = new BehaviorSubject<boolean>(true);
    // Criando o padrao para dados do usuario
    public changedUserData = new BehaviorSubject<boolean>(false);

    public route_session: RouteSession[] = []

    constructor(
        private modalService: NgbModal,
        private AppSettings: AppSettings,
        private AppVariablesService: AppVariablesService,
    ) { }
    // Funcao para alterar o erro de http
    public alteraHttpResponse(httpStatus: number) {
        this.httpResponse.next(httpStatus);
    }
    // Função para alterar a variável "isAuthenticated" do AppComponent
    public alteraIsAuthenticated(isAuthenticated: boolean) {
        this.isAuthenticatedPadrao.next(isAuthenticated);
    }
    /**
     * @param hml Caso seja HML retornar o hml no final da versão
     * @returns Versão do App
     */
    public getAppVersion(hml: boolean = true): string {
        return (environment.production) ? this.appVersion : `${this.appVersion}${hml ? ' HML' : ''}`;
    }
    public getDeviceId(): string {
        return this.getLocalStorage('device-id') ? this.getLocalStorage('device-id') : '123';
    }
    /**
  * setSessionStorage: Grava no localStorage do browser os dados informados
  * @param varName Nome da variavel a ser criada no localStorage
  * @param value Valor da variavel
  * @param expire [Opicional] Tempo de validade em milesegundos (0 = nunca expira)
  */
    public setSessionStorage(varName: string, value: any, expire: number = 0) {
        // Testa se navegador tem suporte a gravação temporaria
        if (typeof Storage !== 'undefined') {
            // Criando arquivo para salvar
            const record = {
                // tslint:disable-next-line:object-literal-shorthand
                value: value,
                timestamp: (expire === 0) ? 0 : new Date().getTime() + expire
            };
            // Grava as informações no navegador
            sessionStorage.setItem(varName, JSON.stringify(record));
            // retorna verdadeiro, informando que foi gravada a variável
            return true;
        } else {
            // se não tiver suporte a gravacao, retorna falso
            return false;
        }
    }
    /**
     * getSessionStorage: Retorna o conteudo de uma variavel gravada no localStorage do browser
     * @param varName Nome da variável a ser recuperada
     * @returns Conteúdo da variável solicitada
     */
    public getSessionStorage(varName: string) {
        // Recebendo variável
        let record: any = sessionStorage[varName];
        // Se não encontrar a variável, retorna falso
        if (!record) {
            return false;
        } else {
            record = JSON.parse(record);
            // se o prazo a variavel ainda nao tiver expirado
            if (record.timestamp === 0 || new Date().getTime() <= record.timestamp) {
                // retorna o conteudo da variável
                return record.value;
            } else {
                // se o prazo já tiver espirado, deleta a variável do armazenamento
                sessionStorage.removeItem(varName);
                // retorna falso
                return false;
            }
        }
    }
    /**
     * delSessionStorage: Remove uma variável armazenada no localStorage do browser
     * @param varName Nome da variável
     */
    public delSessionStorage(varName: string) {
        // Testa se navegador tem suporte a gravação temporaria
        if (typeof Storage !== 'undefined') {
            // Deleta a variável
            sessionStorage.removeItem(varName);
            // retorna verdadeiro, informando que foi removida
            return true;
        } else {
            // se não tiver suporte a gravacao, retorna falso
            return false;
        }
    }
    /**
     * setLocalStorage: Grava no localStorage do browser os dados informados
     * @param varName Nome da variavel a ser criada no localStorage
     * @param value Valor da variavel
     * @param expire [Opicional] Tempo de validade em milesegundos (0 = nunca expira)
     */
    public setLocalStorage(varName: string, value: any, expire: number = 0) {
        // Testa se navegador tem suporte a gravação temporaria
        if (typeof Storage !== 'undefined') {
            // Criando arquivo para salvar
            const record = {
                // tslint:disable-next-line:object-literal-shorthand
                value: value,
                timestamp: (expire === 0) ? 0 : new Date().getTime() + expire
            };
            // Grava as informações no navegador
            localStorage.setItem(varName, JSON.stringify(record));
            // retorna verdadeiro, informando que foi gravada a variável
            return true;
        } else {
            // se não tiver suporte a gravacao, retorna falso
            return false;
        }
    }
    /**
     * getLocalStorage: Retorna o conteudo de uma variavel gravada no localStorage do browser
     * @param varName Nome da variável a ser recuperada
     * @returns Conteúdo da variável solicitada
     */
    public getLocalStorage(varName: string) {
        // Recebendo variável
        let record: any = localStorage[varName];
        // Se não encontrar a variável, retorna falso
        if (!record) {
            return false;
        } else {
            record = JSON.parse(record);
            // se o prazo a variavel ainda nao tiver expirado
            if (record.timestamp === 0 || new Date().getTime() <= record.timestamp) {
                // retorna o conteudo da variável
                return record.value;
            } else {
                // se o prazo já tiver espirado, deleta a variável do armazenamento
                sessionStorage.removeItem(varName);
                // retorna falso
                return false;
            }
        }
    }
    /**
     * delLocalStorage: Remove uma variável armazenada no localStorage do browser
     * @param varName Nome da variável
     */
    public delLocalStorage(varName: string) {
        // Testa se navegador tem suporte a gravação temporaria
        if (typeof Storage !== 'undefined') {
            // Deleta a variável
            localStorage.removeItem(varName);
            // retorna verdadeiro, informando que foi removida
            return true;
        } else {
            // se não tiver suporte a gravacao, retorna falso
            return false;
        }
    }
    /**
     * Valida os digitos verificadores de um CPF
     * @param cpf <string> CPF
     * @returns boolean
     */
    private validadeCPF(cpf: string): boolean {
        const BLACKLIST: Array<string> = [
            '00000000000',
            '11111111111',
            '22222222222',
            '33333333333',
            '44444444444',
            '55555555555',
            '66666666666',
            '77777777777',
            '88888888888',
            '99999999999',
            '12345678909'
        ];
        if (!cpf) {
            return false;
        }
        // CPF can't be blacklisted
        else if (BLACKLIST.includes(cpf)) {
            return false
        }
        const verifierDigit = (digits: string): number => {
            const numbers: Array<number> = digits
                .split('')
                .map(number => {
                    return parseInt(number, 10)
                })
            const modulus: number = numbers.length + 1
            const multiplied: Array<number> = numbers.map((number, index) => number * (modulus - index))
            const mod: number = multiplied.reduce((buffer, number) => buffer + number) % 11
            return (mod < 2 ? 0 : 11 - mod)
        }
        let numbers: string = cpf.substr(0, 9)
        numbers += verifierDigit(numbers)
        numbers += verifierDigit(numbers)
        return numbers.substr(-2) === cpf.substr(-2)
    }
    /**
     * Valida os dígitos verificadores de um CNPJ
     * @param cnpj <string> CNPJ
     * @returns boolean
     */
    private validadeCNPJ(cnpj: string): boolean {
        const BLACKLIST: Array<string> = [
            '00000000000000',
            '11111111111111',
            '22222222222222',
            '33333333333333',
            '44444444444444',
            '55555555555555',
            '66666666666666',
            '77777777777777',
            '88888888888888',
            '99999999999999'
        ];
        if (!cnpj) {
            return false;
        }
        // CPF can't be blacklisted
        else if (BLACKLIST.includes(cnpj)) {
            return false
        }
        const verifierDigit = (digits: string): number => {
            let index: number = 2;
            const reverse: Array<number> = digits.split('').map(Number).reverse()
            const sum: number = reverse.reduce((buffer, number) => {
                buffer += number * index
                index = (index === 9 ? 2 : index + 1)
                return buffer
            }, 0)
            const mod: number = sum % 11
            return (mod < 2 ? 0 : 11 - mod)
        }
        let numbers: string = cnpj.substr(0, 12)
        numbers += verifierDigit(numbers)
        numbers += verifierDigit(numbers)
        return numbers.substr(-2) === cnpj.substr(-2)
    }
    /**
     * Verifica se um documento informado é um CPF ou CNPJ válido
     * @param document <string> CPF ou CNPJ
     * @returns boolean
     */
    public isValid = (document: string): boolean => {
        if (!document) {
            return false;
        } else {
            document = document.replace(/\D/g, '');
            if (document.length !== 11 && document.length !== 14) {
                return false;
            } else if (document.length === 11) {
                return this.validadeCPF(document);
            } else {
                return this.validadeCNPJ(document);
            }
        }
    }
    public validarData(dataStr?: string): boolean {
        if (dataStr) {

            const partes = dataStr.split('/');

            if (partes.length !== 3) {
                return false;
            }

            const dia = parseInt(partes[0], 10);
            const mes = parseInt(partes[1], 10);
            const ano = parseInt(partes[2], 10);

            if (isNaN(dia) || isNaN(mes) || isNaN(ano)) {
                return false;
            }

            if (ano < 1000 || ano > 9999 || mes < 1 || mes > 12) {
                return false;
            }

            const diasPorMes = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            if (ano % 4 === 0 && (ano % 100 !== 0 || ano % 400 === 0)) {
                diasPorMes[2] = 29; // Ano bissexto
            }

            return dia >= 1 && dia <= diasPorMes[mes];
        }
        else {
            return false;
        }

    }
    /**
     * Abre um modal do NG Bootstrap
     * @param content: <TemplateRef> Template que será carregado pelo modal
     * @param callBack: <function> Função de retorno do modal
     * @param options: <object> [Opcional] Opções do modal
     */
    public openModal(content: TemplateRef<any>, callBack: any, options?: any): NgbModalRef {
        const modalOptions: any = {
            centered: true,
            backdropClass: 'bg-dark',
            keyboard: false,
            animation: true,
            backdrop: 'static',
            size: 'lg'
        };
        if (options) {
            for (const key in options) {
                if (Object.prototype.hasOwnProperty.call(options, key)) {
                    modalOptions[key] = options[key];
                }
            }
        }
        const myModal: NgbModalRef = this.modalService.open(content, modalOptions);
        myModal.result.then((result: any) => {
            callBack(result);
        });
        return myModal;
    }
    /**
     **  Verifica se um objeto é vazio
     * @param obj Objeto que sera verificado
     * @returns true || false
     */
    public verifyisEmptyObject(obj: any): boolean {
        let isEmpty: boolean = true;
        if (!!obj) {
            let ignorableAtributes = ['status', 'statusCode', 'statusTicket', 'statusDescription', 'confirmed', 'hasModal', 'hasPopover', 'strValue']
            Object.keys(obj)?.forEach(key => {
                if (!ignorableAtributes.includes(key)) {
                    if (Array.isArray(obj[key])) {
                        if (obj[key].length > 0) isEmpty = false;
                    }
                    else if (typeof obj[key] === 'object') {
                        this.verifyisEmptyObject(obj[key]);
                    }
                    else {
                        if (!!obj[key]) isEmpty = false;
                    }
                }
            })
        }
        return isEmpty;
    }
    /**
     ** Troca a cor tema da plataforma
     * @param theme Tema para qual sera alterado
     */
    public changeTheme(theme: string) {
        this.AppSettings.appTheme = theme;
        if (localStorage) {
            localStorage['appTheme'] = theme;
        }
        for (var x = 0; x < document.body.classList.length; x++) {
            var targetClass = document.body.classList[x];
            if (targetClass.search('theme-') > -1) {
                document.body.classList.remove(targetClass);
            }
        }
        document.body.classList.add(theme);
        this.AppVariablesService.variablesReload.emit();
    }
    public changeMode(mode: string) {
        this.AppSettings.appMode = mode;
        if (localStorage) {
            localStorage['appMode'] = mode;
        }
        document.documentElement.setAttribute('data-bs-theme', mode);
        this.AppVariablesService.variablesReload.emit();
    }
    /**
     ** Pegar a Rota 
     * @param position Posição da rota ( essa posição seria a '/' da rota )
     */
    public getRouteSession(position?: number) {
        /**
         ** Altera o titulo da sessão da rota
         ** Altera tanto no service quanto em chache
         */
        if (position !== undefined) {
            return this.route_session[position]
        } else {
            return this.route_session
        }
    }
    /**
     ** Altera a Rota 
     * @param position Posição da rota ( essa posição seria a '/' da rota )
     * @param title O titulo da sessão da rota
     */
    public changeRouteSessionTitle(position: number, title: string) {
        /**
         ** Altera o titulo da sessão da rota
         ** Altera tanto no service quanto em chache
         */
        let route_session: RouteSession[] = this.getSessionStorage('route-session');
        if (route_session[position]) {
            route_session[position].text = title;
            this.route_session[position].text = title
            this.setSessionStorage('route-session', route_session);
        }
    }
    /**
     ** Altera a da sessão da rota
     * @param position Posição da rota ( essa posição seria a '/' da rota )
     * @param routeSession a rota da sessão
     */
    public changeRouteSession(position: number, routeSession: RouteSession | null) {
        /**
        ** Altera a da sessão da rota
        ** Altera tanto no service quanto em chache
        */
        let route_session: RouteSession[] = this.getSessionStorage('route-session');
        if (route_session) {
            route_session[position] = routeSession!;
            this.route_session[position] = routeSession!;
            this.setSessionStorage('route-session', route_session);
        }
    }
    /**
     ** Mover um elemento de um vetor para outra posição
     * @param arr Vetor que sera alterado
     * @param oldIndex Posição no elemento que será movido
     * @param newIndex Nova posição no elemento que será movido
     */

    public arrayMove = (arr: any[], oldIndex: number, newIndex: number) => {
        if (newIndex >= arr.length) {
            let k = newIndex - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
    };

    /**
     * @param array Vetor que será ordenado
     * @param attribute atributo na qual será ordenado
     */
    public orderAscByAtribute(array: any[], attribute: any) {
        return array.sort((a: any, b: any) => {
            if (!a[attribute] && b[attribute]) {
                return -1
            }
            else if (a[attribute] && !b[attribute]) {
                return 1
            }
            else {
                return 0
            }
        })
    }
}
