import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UpdateService {

  constructor(
    private swUpdate: SwUpdate,
    private toastr: ToastrService
  ) {
    if (!this.swUpdate.isEnabled) {
        console.warn('SWUpdate: disabled!');
    } else {
        console.info('SWUpdate: enabled!');
        this.swUpdate.available.subscribe(event => {
            this.toastr.info('Nova versão localizada! Atualizando a aplicação...');
            console.log('SWUpdate: Nova versão localizada!');
            console.log(event);
            
            console.log('SWUpdate: Atualizando aplicação...');
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        });
        setInterval(() => {
            console.info('SWUpdate: Verificando atualizações...');
            this.swUpdate.checkForUpdate();
        }, 3600000);
    }
  }
}
