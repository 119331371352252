<div [class]="'classe-card d-flex align-items-center shadow-sm rounded-3 px-4 w-100 overflow-hidden bg-altern '+classes" #classeRef [ngClass]="{'locked bg-opacity-75':classe.locked}" (click)="!classe.locked ? goRoute() : ''">
    <div class="w-100">
        <div class="row classe-details align-items-center h-100">
            <div class="col-7">
                <div class="d-flex align-items-center gap-3">
                    <!-- <div class="classe-image position-relative">
                        <ng-container *ngIf="classe.imgs![0]?.logoMini">
                            <div class="img w-100 h-100" [style]="'background-image:url('+classe.imgs![0].logoMini+')'"></div>
                        </ng-container>
                        <div class="locked-icon d-flex justify-content-center align-items-center rounded-circle w-50px h-50px">
                            <fa-icon class="text-white fa-2x" [icon]="['fas','lock']"></fa-icon>
                        </div>
                    </div> -->
                    <div class="classe-title py-2">
                        <span class="d-block fs-15px fw-bold lh-sm" [ngClass]="{'text-gray-400':classe.locked}" [innerHTML]="classe.name | noSanitize"></span>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="classe-hour py-2 h-100">
                    <div class="d-flex align-items-center gap-1 lh-1 h-100">
                        <ng-container *ngIf="classe.duration">
                            <fa-icon class="fa-sm d-flex text-inverse text-opacity-50" [ngClass]="{'text-gray-400':classe.locked}" [icon]="['fas','clock']" [fixedWidth]="true"></fa-icon>
                            <span class="text-inverse text-opacity-60" [ngClass]="{'text-gray-400':classe.locked}">{{getHr(classe.duration)}}</span>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-1">
                <div class="d-flex justify-content-center align-items-center h-100" >
                    <div class="d-flex align-items-center gap-1">
                        <div class="d-flex justify-content-end w-20px">
                            <span [ngClass]="{'text-gray-400':classe.locked}" class="text-inverse text-opacity-60" >{{classe.files?.length}}</span>
                        </div>
                        <fa-icon  [ngClass]="{'text-gray-400':classe.locked}" class="fa-sm d-flex text-inverse text-opacity-50"  [icon]="['fas','file']"></fa-icon>
                    </div>
                </div>
            </div>
            <div class="col-1">
                <div class="d-flex justify-content-center align-items-center h-100" >
                    <div class="d-flex align-items-center gap-1">
                        <span [ngClass]="{'text-gray-400':classe.locked}" class="text-inverse text-opacity-60">{{classe.typeFormatted}}</span>
                    </div>
                </div>
            </div>
            <div class="col-1">
                <div class="d-flex justify-content-center align-items-center h-100" >
                    <ng-container *ngIf="!classe.locked">
                        <div class="d-flex align-items-center ms-2">
                            <div class="position-relative" [ngClass]="{'watched':classe.watched && !classe.locked,'locked':classe.locked}">
                                <div class="watched-marker rounded-1"></div>
                                <div class="check-svg">
                                    <svg  viewBox="0 0 352.62 352.62" xml:space="preserve">
                                        <g>
                                            <path d="M337.222,22.952c-15.912-8.568-33.66,7.956-44.064,17.748c-23.867,23.256-44.063,50.184-66.708,74.664
                                                c-25.092,26.928-48.348,53.856-74.052,80.173c-14.688,14.688-30.6,30.6-40.392,48.96c-22.032-21.421-41.004-44.677-65.484-63.648
                                                c-17.748-13.464-47.124-23.256-46.512,9.18c1.224,42.229,38.556,87.517,66.096,116.28c11.628,12.24,26.928,25.092,44.676,25.704
                                                c21.42,1.224,43.452-24.48,56.304-38.556c22.645-24.48,41.005-52.021,61.812-77.112c26.928-33.048,54.468-65.485,80.784-99.145
                                                C326.206,96.392,378.226,44.983,337.222,22.952z M26.937,187.581c-0.612,0-1.224,0-2.448,0.611
                                                c-2.448-0.611-4.284-1.224-6.732-2.448l0,0C19.593,184.52,22.653,185.132,26.937,187.581z"/>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="classe.locked">
                        <fa-icon class="text-gray-400 ms-3" [icon]="['fas','lock']"></fa-icon>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>