import { Subscription } from 'rxjs';
import { AppService } from '@modules/app-common/services';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Practice } from '@modules/app-common/classes';

@Component({
  selector: 'app-practice-card',
  templateUrl: './practice-card.component.html',
  styleUrls: ['./practice-card.component.scss']
})
export class PracticeCardComponent implements OnInit, OnDestroy {
  @ViewChild('practiceRef') _practiceRef!: ElementRef

  @Input() practice: Practice = new Practice();
  @Input() collectionAlias?: string;
  @Input() classes?: string = '';

  private subscriptions: Subscription[] = [];
  public all_classes: any[] = [];
  public class_percent: string = '0';
  public class_percent_number: number = 0;
  public class_deg: string = '0';
  constructor(
    private Router: Router,
    private AppService: AppService
  ) { }

  public goRoute() {
    // let sub: Subscription = new Subscription();
    // sub = this.AppService.courses.subscribe(courses => {
    //   if (courses) {
    //     const COURSE = courses.find((c) => c.id == this.practice.courseId);
    //     this.Router.navigate([`/curso/simulado/${COURSE?.alias}/${this.practice.alias}`]);
    //     sub.unsubscribe();
    //   }
    // })
    this.Router.navigate([`/curso/simulado/${this.practice?.courseAlias}/${this.practice.alias}`]);
  }
  public getHr(ms: number): string {
    const SEC = Math.floor(ms / 1000);
    const MIN = Math.floor(SEC / 60);
    const HR = Math.floor(MIN / 60);
    if (HR > 0) {
      return `${HR}h ${(MIN % 60) > 0 ? (MIN % 60) + 'min' : ''}`;
    }
    else if (MIN > 0) {
      return `${MIN}min`;
    } else {
      return `${SEC}s`;
    }
  }
  ngOnInit(): void {
    setTimeout(() => {
      if (this._practiceRef.nativeElement && !this.practice.locked) {
        this._practiceRef.nativeElement.addEventListener('mousemove', (ev: any) => {
          const X = ev.layerX + 20;
          const Y = ev.layerY;

          this._practiceRef.nativeElement.style.setProperty('--mouse-x', `${X}px`);
          this._practiceRef.nativeElement.style.setProperty('--mouse-y', `${Y}px`);
        })
        this._practiceRef.nativeElement.addEventListener('mouseenter', (ev: any) => {
          this._practiceRef.nativeElement.style.setProperty('--opacity', 1);
        })
        this._practiceRef.nativeElement.addEventListener('mouseleave', (ev: any) => {
          this._practiceRef.nativeElement.style.setProperty('--opacity', 0);
        })
      }
    }, 1)

  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }
}

