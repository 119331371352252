import { IconProp } from "@fortawesome/fontawesome-svg-core";
import * as moment from "moment";


export class Profile {
    id?: string;
    links?: ProfileLink = {} as ProfileLink
    email?: string
    username?: string
    description?: string
    document?: string
    objective?: string
    name?: string
    image?: string
    country?: ProfileCountry
    file_image?: File
    email_discord?: string
    darkMode?: number = 1
    private?: number = 0
    phone?: string
    preferences?: any = {} as any;
    birth?: moment.Moment
    birthDate?: string
    dateIncluded?: moment.Moment
    hasLab?: boolean = false;
    address?: ProfileAddress = {} as ProfileAddress
    rank?: ProfileRank = {} as ProfileRank
    badges: Badge[] = []
    mfaConfig?: MFAProfile = {} as MFAProfile;
    listCertificates?: ProfileCertificates[] = [];
    awsBadges?: ProfileAwsBadges[] = [];
    insignia?: ProfileInsignia[] = [];
    constructor(config?: any) {
        if (config) {
            this.id = (config.id !== undefined) ? config.id : this.id;
            this.links = (config.links !== undefined) ? new ProfileLink(config.links) : this.links;
            this.email = (config.email !== undefined) ? config.email : this.email;
            this.username = (config.username !== undefined) ? config.username : this.username;
            this.description = (config.description !== undefined) ? config.description : this.description;
            this.name = (config.name !== undefined) ? config.name : this.name;
            this.image = (config.image !== undefined) ? config.image : this.image;
            this.country = (config.country !== undefined) ? new ProfileCountry(config.country) : this.country;
            this.email_discord = (config.email_discord !== undefined) ? config.email_discord : this.email_discord;
            this.darkMode = (config.darkMode !== undefined) ? config.darkMode : this.darkMode;
            this.private = (config.private !== undefined) ? config.private : this.private;
            this.phone = (config.phone !== undefined) ? config.phone : this.phone;
            this.document = (config.document !== undefined) ? config.document : this.document;
            this.objective = (config.objective !== undefined) ? config.objective : this.objective;
            this.birth = (config.birth !== undefined) ? moment(config.birth) : this.birth;
            this.dateIncluded = (config.dateIncluded !== undefined) ? moment(config.dateIncluded).locale('pt-br') : this.dateIncluded;
            this.dateIncluded = (config.dateInclusion !== undefined) ? moment(config.dateInclusion).locale('pt-br') : this.dateIncluded;
            this.address = (config.address !== undefined) ? new ProfileAddress(config.address) : this.address;
            this.rank = (config.rank !== undefined) ? config.rank : this.rank;
            this.hasLab = (config.hasLab !== undefined) ? config.hasLab : this.hasLab;
            this.badges = (config.badges !== undefined) ? config.badges : this.badges;
            this.mfaConfig = (config.mfaConfig !== undefined) ? config.mfaConfig : this.mfaConfig;
            this.preferences = (config.preferences !== undefined) ? config.preferences : this.preferences;
            this.listCertificates = (config.listCertificates !== undefined) ? config.listCertificates : this.listCertificates;
            this.awsBadges = (config.awsBadges !== undefined) ? config.awsBadges : this.awsBadges;
            this.insignia = (config.insignia !== undefined) ? config.insignia : this.insignia;

            if(this.phone){
                const DIAL_CODE = this.phone!.slice(0, -11);
                if (!DIAL_CODE) {
                    if(this.country?.dial_code){
                        this.phone = `${this.country?.dial_code}${this.phone}`
                    } else {
                        this.phone = `+55${this.phone}`
                    }
                }
            }
        }
    }
}

export class ProfileLink {
    [index: string]: ProfileLinkModule
    constructor(config: any) {
        if (config) {
            for (let key in config) {
                this[key] = new ProfileLinkModule({
                    icon: config[key].icon,
                    link: config[key].link,
                    name: config[key].name,
                })
            }
        }
    }

}
export class ProfileLinkModule {
    icon!: IconProp;
    link: string = '';
    name?: string

    constructor(config: any) {
        if (config) {
            this.icon = (config.icon !== undefined) ? config.icon : this.icon;
            this.link = (config.link !== undefined) ? config.link : this.link;
            this.name = (config.name !== undefined) ? config.name : this.name;
        }
    }
    public get format(): string {
        return this.link!.replace('https://', '').replace('http://', '').replace('www.', '');

    }

}
export interface ProfileCertificates {
    urlCertificate: string;
    date: string;
    badge: string;
    description: string;
    id: string;
    title: string;
}
export interface ProfileAwsBadges {
    link: string;
    name: string;
    image: string;
    id: string;
}
export interface ProfileInsignia {
    description: string;
    color: string;
    isTag: number;
    name: string;
    image: string;
    id: string;
}
export interface Badge {
    name: string,
    description: string,
    image: string,
}
export interface MFAProfile {
    sms?: number;
    token?: number;
    preferredSms?: number;
    preferredToken: number;
}
export class ProfileCountry {
    name?: string
    image?: string
    dial_code?: string
    constructor(config?:any){
        if(config){
            this.name = (config.name !== undefined) ? config.name : this.name;
            this.image = (config.image !== undefined) ? config.image : this.image;
            this.dial_code = (config.dial_code !== undefined) ? config.dial_code : this.dial_code;
        }
    }
}
export class ProfileAddress {
    number?: string
    uf?: string
    city?: string
    street?: string
    compliment?: string
    state?: string
    cep?: string
    neighborhood?: string
    complement?: string

    constructor(config?: any) {
        if (config) {
            this.number = (config.number !== undefined) ? config.number?.trim() : this.number
            this.uf = (config.uf !== undefined) ? config.uf?.trim() : this.uf
            this.city = (config.city !== undefined) ? config.city?.trim() : this.city
            this.street = (config.street !== undefined) ? config.street?.trim() : this.street
            this.compliment = (config.compliment !== undefined) ? config.compliment?.trim() : this.compliment
            this.cep = (config.cep !== undefined) ? config.cep?.trim() : this.cep
            this.neighborhood = (config.neighborhood !== undefined) ? config.neighborhood?.trim() : this.neighborhood
            this.complement = (config.complement !== undefined) ? config.complement?.trim() : this.complement
        }
    }
}
export interface ProfileRank {
    rank: number,
    rankLevel: string,
    rankGlobal: number,
}

export interface Password {
    password: string;
    newPassword: string;
    confirmPassword: string;
}
export class Historic {
    dateChanged?: moment.Moment;
    dateIncluded?: moment.Moment;
    id?: string;
    constructor(config: any) {
        this.dateChanged = (config.dateChanged !== undefined) ? moment(config.dateChanged).locale('pt-br') : this.dateChanged;
        this.dateIncluded = (config.dateIncluded !== undefined) ? moment(config.dateIncluded).locale('pt-br') : this.dateIncluded;
        this.id = (config.id !== undefined) ? config.id : this.id;
    }
}
export class HistoricDay {
    date?: moment.Moment
    tasks?: number = 0;
    constructor(config: any) {
        this.date = (config.date !== undefined) ? moment(config.date).locale('pt-br') : this.date;
        this.tasks = (config.tasks !== undefined) ? config.tasks : this.tasks;
    }
    public get color(): number {
        if (this.tasks! >= 15) {
            return 0
        }
        else if (this.tasks! >= 12) {
            return 100
        }
        else if (this.tasks! >= 8) {
            return 150
        }
        else if (this.tasks! >= 5) {
            return 200
        }
        else if (this.tasks! >= 1) {
            return 250
        }
        else {
            return 500
        }
    }
}

export class HistoricMonth {
    date?: moment.Moment
    days?: HistoricDay[] = [];
    constructor(config: any) {
        this.date = (config.date !== undefined) ? moment(config.date).locale('pt-br') : this.date;
        this.days = (config.days !== undefined) ? config.days.map((day: any) => new HistoricDay(day)) : this.days;

    }
}
export class HistoricYear {
    date?: moment.Moment
    months?: HistoricMonth[] = [];
    constructor(config: any) {
        this.date = (config.date !== undefined) ? moment(config.date).locale('pt-br') : this.date;
        this.months = (config.months !== undefined) ? config.months.map((day: any) => new HistoricMonth(day)) : this.months;
    }
}
export interface Task {
    count: number,
    day: moment.Moment
}
