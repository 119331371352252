import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Course } from '@modules/app-common/classes';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss']
})
export class CourseCardComponent implements OnInit {

  @Input() course: Course = new Course();
  @Input() collectionAlias?: string;
  public all_classes: any[] = [];
  private all_classes_watched: any[] = [];
  public class_percent: string = '0';
  public class_percent_number: number = 0;
  public class_deg: string = '0';
  public tag_color: string = 'theme';
  constructor(
    private Router:Router
  ){}

  public goRoute(){
    if(this.course.isTest){
      this.Router.navigate([`/curso/simulado/${this.course.alias}`]);
    }else{
      this.Router.navigate([`/curso/video/${this.collectionAlias}/${this.course.alias}`]);
    }
  }
  ngOnInit(): void {
    if(this.course.tag?.color){
      this.tag_color = this.course.tag.color; 
    }
    this.course.modules?.forEach((cl: any) => { this.all_classes.push(...cl.classes) })
    if(this.all_classes.length ){
      this.all_classes_watched = this.all_classes.filter((cl: any) => cl.watched);
      this.class_percent = (this.all_classes_watched.length * 100 / this.all_classes.length).toFixed(0);
      this.class_percent_number = Number(this.class_percent);
      this.class_deg = (360 * Number(this.class_percent) / 100).toFixed(0);
    } else {
      this.class_percent_number = 0
      this.class_deg = '0';
      this.class_percent = '0';
    }
  }
}
