import { Component } from '@angular/core';
import { CommonService } from '@modules/app-common/services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  host: { class: 'app-footer' }
})
export class FooterComponent {
  public year = new Date().getFullYear();

  constructor(
    public CommonService:CommonService
  ){}
}
