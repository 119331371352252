import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { HttpRequestsService } from './http-requests.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
	providedIn: 'root'
})

export class AppMenuService {
	constructor(
		private HttpRequestsService: HttpRequestsService,
		private ToastrService: ToastrService,
	) {}

	public menus: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	public getMenus() {
		this.HttpRequestsService.requestData('GET', `:productor/customer/menu`, true)
			.subscribe({
				next: (res: any) => {
					if (res.data) {
						let menutest = [
							{ 'text': 'Menu', 'is_header': true },
							...res.data.menu
						];
						this.menus.next(menutest);
					} else {
						this.ToastrService.error('Ocorreu um erro ao carregar as informações!', 'Erro');
					}
				}, error: (err: any) => {
					console.error(err);
				}
			})
	}

	getAppMenus(): Observable<any> {
		return this.menus;
	}
}