import * as moment from "moment";
import { Lab } from "./lab";
import { Practice } from "./practice";

export class Course {
    public id?: string;
    public courseId?: string;
    public alias?: string;
    public title?: string;
    public attachments: CourseClasseFiles[] = [];
    public configIds: string[] = [];
    public tag?: CourseTag;
    public description?: string;
    public type?: 'video' | 'test' | 'trail' | 'lab' | 'practice' | 'challenge' = 'video';
    public certificateEmit?: number = 0;
    public intructions?: string;
    public collectionAlias?: string;
    public codStatus: number = 1;
    public progress: number = 0;
    public hided: boolean = false;
    public ended: boolean = false;
    public certificateValid: number = 0;
    public imgs?: CourseImage[] = []
    public isTest: boolean = false;
    public isGetId: boolean = false;
    public owned?: number = 0
    public statusCode?: number = 0
    public modules: CourseModule[] = [];
    public labs: Lab[] = [];
    public testes: Practice[] = [];
    public practice: any[] = [];
    public badges: any[] = [];
    constructor(config?: any) {
        if (config) {
            this.id = (config.id !== undefined) ? config.id : this.id;
            this.courseId = (config.courseId !== undefined) ? config.courseId : this.id;
            this.alias = (config.alias !== undefined) ? config.alias : this.alias;
            this.title = (config.title !== undefined) ? config.title : this.title;
            this.attachments = (config.attachments !== undefined) ? config.attachments : this.attachments;
            this.configIds = (config.configIds !== undefined) ? config.configIds : this.configIds;
            this.certificateEmit = (config.certificateEmit !== undefined) ? config.certificateEmit : this.certificateEmit;
            this.tag = (config.tag !== undefined) ? config.tag : this.tag;
            this.intructions = (config.intructions !== undefined) ? config.intructions : this.intructions;
            this.type = (config.type !== undefined) ? config.type : this.type;
            this.description = (config.description !== undefined) ? config.description : this.description;
            this.progress = (config.progress !== undefined) ? Math.floor(config.progress) : this.progress;
            this.codStatus = (config.codStatus !== undefined) ? config.codStatus : this.codStatus;
            this.certificateValid = (config.certificateValid !== undefined) ? config.certificateValid : this.certificateValid;
            this.collectionAlias = (config.collectionAlias !== undefined) ? config.collectionAlias : this.collectionAlias;
            this.hided = (config.hide !== undefined) ? !!config.hide : this.hided;
            this.ended = (config.ended !== undefined) ? !!config.ended : this.ended;
            this.owned = (config.owned !== undefined) ? config.owned : this.owned
            this.statusCode = (config.statusCode !== undefined) ? config.statusCode : this.statusCode
            this.imgs = (config.imgs !== undefined) ? config.imgs : this.imgs;
            this.modules = (config.modules !== undefined) ? config.modules.map((x: any) => new CourseModule(x)).filter((x: CourseModule) => !x.hide) : this.modules;
            this.labs = (config.labs !== undefined) ? config.labs.map((x: any) => new Lab(x)).sort((a: Lab, b: Lab) => {
                if (Number(a.order?.replaceAll('.', '')) > Number(b.order?.replaceAll('.', ''))) {
                    return 1;
                } else if (Number(a.order?.replaceAll('.', '')) < Number(b.order?.replaceAll('.', ''))) {
                    return -1;
                } else {
                    return 0;
                }
            })
                .sort((a: Lab, b: Lab) => {
                    if (!a.locked && b.locked) {
                        return -1
                    }
                    else if (a.locked && !b.locked) {
                        return 1
                    }
                    else {
                        return 0
                    }
                })
                .filter((x: Lab) => !x.hide) : this.labs;
            this.testes = (config.testes !== undefined) ? config.testes.map((x: any) => new Practice(x)).sort((a: Practice, b: Practice) => {
                if (Number(a.order?.replaceAll('.', '')) > Number(b.order?.replaceAll('.', ''))) {
                    return 1;
                } else if (Number(a.order?.replaceAll('.', '')) < Number(b.order?.replaceAll('.', ''))) {
                    return -1;
                } else {
                    return 0;
                }
            })
                .sort((a: Practice, b: Practice) => {
                    if (!a.locked && b.locked) {
                        return -1
                    }
                    else if (a.locked && !b.locked) {
                        return 1
                    }
                    else {
                        return 0
                    }
                })
                .filter((x: Practice) => !x.hide) : this.testes;
            this.isTest = (config.isTest !== undefined) ? !!config.isTest : this.isTest;
            this.practice = (config.practice !== undefined) ? config.practice : this.practice;
            this.badges = (config.badges !== undefined) ? config.badges : this.badges;
        }
    }
    public get locked(): boolean {
        return !this.statusCode
    }
    public get hide(): boolean {
        // if (this.hided) {
        //     return this.hided;
        // } else {
        //     if (this.ended) {
        //         return !this.owned;
        //     } else {
        //         return this.ended;
        //     }
        // }
        return false;
    }
    public get completed(): boolean {
        return this.modules.every(module => module.classes.every(classe => classe.watched))
    }
    public get progressPercent(): string {
        let all_classes: any[] = [];
        this.modules?.forEach((cl: any) => { all_classes.push(...cl.classes) })
        if (all_classes.length) {
            let all_classes_watched = all_classes.filter((cl: any) => cl.watched);
            return (all_classes_watched.length * 100 / all_classes.length).toFixed(0);
        } else {
            return '0';
        }
    }
    public get progressPercentNumber(): number {
        let all_classes: any[] = [];
        this.modules?.forEach((cl: any) => { all_classes.push(...cl.classes) })
        if (all_classes.length) {
            let all_classes_watched = all_classes.filter((cl: any) => cl.watched);
            let class_percent = (all_classes_watched.length * 100 / all_classes.length).toFixed(0);
            return Number(class_percent);
        } else {
            return 0;
        }
    }
    public get progressDeg(): string {
        let all_classes: any[] = [];
        this.modules?.forEach((cl: any) => { all_classes.push(...cl.classes) })
        if (all_classes.length) {
            let all_classes_watched = all_classes.filter((cl: any) => cl.watched);
            let class_percent = (all_classes_watched.length * 100 / all_classes.length).toFixed(0);
            return (360 * Number(class_percent) / 100).toFixed(0);
        } else {
            return '0';
        }
    }
}

export interface CourseImage {
    logoMini?: string;
    logo?: string;
    badge?: string;
}

export interface CourseTag {
    color?: string;
    text?: string;
}

export class CourseModule {
    alias?: string;
    id?: string;
    name?: string;
    courseId?: string;
    statusCode?: number = 1;
    hide?: number = 0;
    classes: CourseClasse[] = []

    constructor(config?: any) {
        if (config) {
            this.alias = (config.alias !== undefined) ? config.alias : this.alias
            this.id = (config.id !== undefined) ? config.id : this.id
            this.name = (config.name !== undefined) ? config.name : this.name
            this.courseId = (config.courseId !== undefined) ? config.courseId : this.courseId
            this.statusCode = (config.statusCode !== undefined) ? config.statusCode : this.statusCode
            this.hide = (config.hide !== undefined) ? config.hide : this.hide
            this.classes = (config.classes !== undefined) ? config.classes.map((x: any) => new CourseClasse(x)).filter((x: CourseClasse) => !x.hide) : this.classes
            if (!this.statusCode) {
                this.disabledClasses();
            }
        }
    }
    public disabledClasses() {
        this.classes = this.classes.map((x: CourseClasse) => {
            x.statusCode = false;
            return x;
        })
    }
    public enabledClasses() {
        this.classes = this.classes.map((x: CourseClasse) => {
            x.statusCode = true;
            return x;
        })
    }
}
export class CourseClasse {
    alias?: string;
    id?: string;
    name?: string;
    type?: 'video' | 'quiz' | 'text' | 'lab' | 'practice' | 'flashcard' | 'pdf' = 'video';
    url?: string;
    text?: string;
    courseId?: string;
    moduleId?: string;
    pdf?: string;
    pdfUrl?: string;
    img?: string;
    duration?: number = 0;
    description?: string;
    files?: CourseClasseFiles[] = [];
    watched?: boolean = false;
    statusCode?: boolean;
    hide?: boolean = false;
    actived?: boolean = true;
    quiz?: CourseQuiz[] = [];
    flashcards?: CourseFlashcard[] = [];
    result: any = 1;
    lab?: Lab;
    practice?: Practice;
    events: ClasseEvent[] = []

    constructor(config?: any) {
        if (config) {
            this.alias = (config.alias !== undefined) ? config.alias : this.alias
            this.id = (config.id !== undefined) ? config.id : this.id
            this.courseId = (config.courseId !== undefined) ? config.courseId : this.courseId
            this.moduleId = (config.moduleId !== undefined) ? config.moduleId : this.moduleId
            this.name = (config.name !== undefined) ? config.name : this.name
            this.pdf = (config.pdf !== undefined) ? config.pdf : this.pdf
            this.pdfUrl = (config.pdfUrl !== undefined) ? config.pdfUrl : this.pdfUrl
            this.type = (config.type !== undefined) ? config.type : this.type
            this.url = (config.url !== undefined) ? config.url : this.url
            this.text = (config.text !== undefined) ? config.text : this.text
            this.img = (config.img !== undefined) ? config.img : this.img
            this.files = (config.files !== undefined) ? config.files : this.files
            this.description = (config.description !== undefined) ? config.description : this.description
            this.watched = (config.watched !== undefined) ? !!config.watched : this.watched
            this.statusCode = (config.statusCode !== undefined) ? !!config.statusCode : this.statusCode
            this.hide = (config.hide !== undefined) ? !!config.hide : this.hide
            this.quiz = (config.quiz !== undefined) ? config.quiz.map((x: any) => new CourseQuiz(x)) : this.quiz
            this.flashcards = (config.flashcards !== undefined) ? config.flashcards : this.flashcards
            this.lab = (config.lab !== undefined) ? new Lab(config.lab) : this.lab;
            this.practice = (config.practice !== undefined) ? new Practice(config.practice) : this.practice;
            this.duration = (config.duration !== undefined) ? (config.duration * 1000) : this.getDuration();
            this.events = (config.events !== undefined) ? config.events.map((x: any) => new ClasseEvent(x)) : this.events
            // this.events = (config.events !== undefined) ? EVENT_PATTERN.map((x: any) => new ClasseEvent(x)) : EVENT_PATTERN.map((x: any) => new ClasseEvent(x))
        }
    }

    private getDuration(): number {
        switch (this.type) {
            case "lab": return this.lab!.duration!
            default: return 0;
        }
    }

    public get locked(): boolean {
        return !this.statusCode;
    }
    public get typeFormatted(): string {
        switch (this.type) {
            case 'quiz':
                return 'Quiz';
            case 'text':
                return 'Texto';
            default:
                return 'Video';
        }
    }
}
const EVENT_PATTERN: ClasseEvent[] = [
    {
        action: 'next-class',
        percent: 80,
    },
    {
        action: 'call-api',
        percent: 30,
        body: {
            method: 'GET',
            path: ':productor/customer/menu',
            authorization: true,
            responseError: "Invalid"
        }
    },
    {
        action: 'reload-menu',
        percent: 40,
    },

]
export class CourseQuiz {
    answers: QuizAnwers[] = []
    correct: any
    question?: string
    response: any = null
    type: number = 1
    maxAnswer: number = 1
    constructor(config: any) {
        this.answers = (config.answers !== undefined) ? config.answers : this.answers
        this.correct = (config.correct !== undefined) ? config.correct : this.correct
        this.question = (config.question !== undefined) ? config.question : this.question
        this.type = (config.type !== undefined) ? config.type : this.type
        this.maxAnswer = (config.maxAnswer !== undefined) ? config.maxAnswer : this.maxAnswer
        this.response = (config.response !== undefined) ? config.response : (this.type == 1 ? null : this.answers.map(x => { return false }))
    }
}

export class ClasseEvent {
    action!: ClasseEventAction
    percent!: number
    check?: boolean = false;
    body?: any

    constructor(config: any) {
        if (config) {
            this.action = (config.action !== undefined) ? config.action : this.action
            this.percent = (config.percent !== undefined) ? config.percent : this.percent
            this.body = (config.body !== undefined) ? config.body : this.body
        }
    }
}

export type ClasseEventAction =
    'save-progress' //* Salvar na tela home como "continuar assistindo"
    | 'check-watched' //* Marcar como visto
    | 'check-not-watched' //* Marcar como não visto
    | 'next-class' //* Redirecionar para proxima aula ( Usando o autoplay, para dar tempo do usuário escolher cancelar o redirecionamento )
    | 'skip-class' //* Redirecionar para proxima aula ( Sem autoplay, sem escolha do usuário cancelar o redirecionamento )
    | 'go-route' //* Redirecionar para uma rota personalizada
    | 'change-speed' //* Alterar velocidade do video
    | 'call-api' //* Chamar uma API
    | 'reload-menu' //* Recarregar o menu

export interface ClasseBodyRoute {
    route: string
    routeQueryParams: any
}
export interface ClasseBodyChangeSpeed {
    speed: .5 | 1 | 1.25 | 1.5 | 1.75 | 2
}
export interface ClasseBodyCallAPI {
    path: string
    method: 'GET' | 'POST' | 'PUT'
    authorization: boolean
    body: any
    responseSuccess: string
    responseError: string
}
export type GetClasseEventBody<T extends ClasseEventAction> =
    T extends "go-route" ? ClasseBodyRoute
    : T extends "change-speed" ? ClasseBodyChangeSpeed
    : T extends "call-api" ? ClasseBodyCallAPI
    : any

export interface QuizAnwers {
    answer: string
}
export interface CourseClasseFiles {
    name: string;
    uri: string;
    key: string;
    size: number;
    type: string;
    url: string;
    downloadUrl: string;
}
export interface CourseFlashcard {
    image: string,
    title: string,
}
export class CourseComment {
    addedBy?: string;
    name?: string;
    classId?: string;
    dateId?: string;
    image?: string;
    codeStatus?: number = 1;
    dateIncluded?: moment.Moment;;
    comment?: string;
    username?: string;
    id?: string;
    quote?: string;
    quoteResponse?: CourseComment;
    dateChanged?: moment.Moment;
    responses: CourseComment[] = []
    responsesLoaded: CourseComment[] = []
    responsesLoadedSize: number = 20
    constructor(config?: any) {
        if (config) {
            this.addedBy = (config.addedBy !== undefined) ? config.addedBy : this.addedBy
            this.name = (config.name !== undefined) ? config.name : this.name
            this.classId = (config.classId !== undefined) ? config.classId : this.classId
            this.dateId = (config.dateId !== undefined) ? config.dateId : this.dateId
            this.image = (config.image !== undefined) ? config.image : this.image
            this.codeStatus = (config.codeStatus !== undefined) ? config.codeStatus : this.codeStatus
            this.dateIncluded = (config.dateIncluded !== undefined) ? moment(config.dateIncluded).locale('pt-BR') : this.dateIncluded
            this.comment = (config.comment !== undefined) ? config.comment : this.comment
            this.username = (config.username !== undefined) ? config.username : this.username
            this.id = (config.id !== undefined) ? config.id : this.id
            this.quote = (config.quote !== undefined) ? config.quote : this.quote
            this.responsesLoadedSize = (config.responsesLoadedSize !== undefined) ? config.responsesLoadedSize : this.responsesLoadedSize
            this.dateChanged = (config.dateChanged !== undefined) ? moment(config.dateChanged).locale('pt-BR') : this.dateChanged
            this.responses = (config.responses !== undefined) ? config.responses.map((x: any) => new CourseComment(x)) : this.responses
            this.responsesLoaded = (config.responses !== undefined) ? this.responses.slice(0, this.responsesLoadedSize) : this.responsesLoaded
        }
    }
}

export class Evaluation {
    classId?: string
    totalLikes: number = 0
    like: boolean = false
    dislike: boolean = false
    constructor(config?: any) {
        if (config) {
            this.classId = (config.classId !== undefined) ? config.classId : this.classId
            this.totalLikes = (config.totalLikes !== undefined) ? config.totalLikes : this.totalLikes
            this.like = (config.like !== undefined) ? !!config.like : this.like
            this.dislike = (config.dislike !== undefined) ? !!config.dislike : this.dislike
        }
    }
    public toggle(eva: 'like' | 'dislike') {
        if (eva === 'like') {
            if (!this.like) {
                this.totalLikes++;
            }
            // this.like = !this.like;
            this.like = true;
            this.dislike = false;
        } else if (eva === 'dislike') {
            // this.dislike = !this.dislike;
            this.dislike = true;
            if (this.like) {
                this.totalLikes--;
            }
            this.like = false;
        }
    }
}
export interface CourseCommentPayload {
    classId: string
    dateId: string
    id: string
    comment: string
    quote: CourseComment
    link: string
}

export interface SendWatchedPayload {
    courseId: string,
    moduleId: string,
    classId: string
    trailId?: string
}
export interface ChangeLessonPayload {
    module: CourseModule,
    classe: CourseClasse
}

export interface ChangeLessonPayload {
    module: CourseModule,
    classe: CourseClasse
}