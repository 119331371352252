<!-- <div class="">
    <hr class=" mb-0 mt-3">
    <div class="details">
        <div class="container">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column flex-md-row gap-md-1 align-items-md-center position-relative">
                    <small>Todos direitos reservados</small>
                    <span class="d-none d-md-inline-block"> - </span>
                    <small>CloudFaster Academy &#9679; {{year}}</small>
                    <div class="version d-none d-md-block position-absolute small opacity-50 small">
                        <small>v{{CommonService.getAppVersion()}}</small>
                    </div>
                </div>
                <div class="academy-container position-relative">
                    <div class="academy position-absolute"></div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<hr class=" mb-0 mt-3">
<div class="bg-main-bright py-3 w-100">
    <div class="container">
      <div class="row gy-4 gy-lg-0 lh-sm justify-content-center justify-content-lg-between">
        <div class="d-lg-none col-12 col-md-6">
          <div class="d-flex justify-content-center align-items-center">
            <div class="brand-footer-img h-50px" aria-label="Home"></div>
        </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="d-flex flex-column">
                <div class="d-flex flex-column flex-md-row gap-md-1 justify-content-center justify-content-lg-start align-items-center position-relative">
                    <span>Todos direitos reservados</span>
                    <span class="d-none d-md-inline-block"> - </span>
                    <span>CloudFaster Academy &#9679; {{year}}</span>
                </div>
                <div class="version d-none d-lg-block small opacity-50 small">
                    <small>v{{CommonService.getAppVersion()}}</small>
                </div>
            </div>
        </div>
        <div class="col-12 d-none d-lg-block col-lg-1">
          <!-- <div class="d-flex justify-content-center align-items-center text-center text-md-start h-100">
            <small class="fw-300 fs-6 text-opacity-75">CNPJ: 26.708.607/0001-14</small>
          </div> -->
        </div>
        <div class="col-12 col-lg-3">
          <div class="d-flex justify-content-center align-items-center text-center text-md-start h-100">
            <a [routerLink]="'/home/politica-de-privacidade'" class="text-white footer-link">
              <small class="fw-300 fs-6 text-opacity-75">Política de Privacidade<br class="d-none d-lg-block">e
                Cookies</small>
            </a>
          </div>
        </div>
        <div class="col-2 d-none d-lg-block">
          <div class="d-flex justify-content-center align-items-md-center h-100">
            <div class="brand-footer-img h-100" aria-label="Home"></div>
          </div>
        </div>
      </div>
    </div>
  </div>