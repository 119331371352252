import * as moment from 'moment';
import { CourseImage } from "@classes"

export class Challenge {
    max_provision?: number
    courseId?: string
    courseTitle?: string
    order?: string
    description?: string
    id?: string
    sk?: string
    alias?: string
    name?: string
    expected?: string
    duration?: number = 0
    type?: 'file' | 'wellArchitected' | 'awsAccount' = 'awsAccount'
    file? = ''
    fileUpload?:File
    maximumAttempts?: number = 3
    owned?: number
    statusCode?: number = 0
    isGetId?: boolean = false
    imgs?: CourseImage
    level?: string = 'Practice'
    difficulty?: string = 'Inciante'
    color?: string
    hided?: boolean = false;
    ended: boolean = false;
    approved: boolean = false;
    routeClass?:string
    statusSession?: 'Initialing' | 'Started' | 'Finished' | 'Awaiting Evaluation' | 'In Review'

    constructor(config?: any) {
        if (config) {
            this.max_provision = (config.max_provision !== undefined) ? config.max_provision : this.max_provision
            this.courseId = (config.courseId !== undefined) ? config.courseId : this.courseId;
            this.courseTitle = (config.courseTitle !== undefined) ? config.courseTitle : this.courseTitle;
            this.order = (config.order !== undefined) ? config.order : this.order;
            this.description = (config.description !== undefined) ? config.description : this.description;
            this.id = (config.id !== undefined) ? config.id : this.id;
            this.sk = (config.sk !== undefined) ? config.sk : this.sk;
            this.alias = (config.alias !== undefined) ? config.alias : this.alias;
            this.name = (config.name !== undefined) ? config.name : this.name;
            this.expected = (config.expected !== undefined) ? config.expected : this.expected;
            this.owned = (config.owned !== undefined) ? config.owned : this.owned;
            this.statusCode = (config.statusCode !== undefined) ? config.statusCode : this.statusCode;
            this.duration = (config.duration !== undefined) ? (config.duration * 1000) : this.duration;
            this.imgs = (config.imgs !== undefined) ? config.imgs : this.imgs;
            this.maximumAttempts = (config.maximumAttempts !== undefined) ? config.maximumAttempts : this.maximumAttempts
            this.level = (config.level !== undefined) ? config.level : this.level;
            this.difficulty = (config.difficulty !== undefined) ? config.difficulty : this.difficulty;
            this.color = (config.color !== undefined) ? config.color : this.color
            this.hided = (config.hide !== undefined) ? !!config.hide : this.hided;
            this.ended = (config.ended !== undefined) ? config.ended : this.ended;
            this.approved = (config.approved !== undefined) ? config.approved : this.approved;
            this.type = (config.type !== undefined) ? config.type : this.type;
            this.file = (config.file !== undefined) ? config.file : this.file;
            this.statusSession = (config.statusSession !== undefined) ? config.statusSession : this.statusSession
            this.routeClass = (config.routeClass !== undefined) ? config.routeClass : this.routeClass

        }
    }
    public get locked(): boolean {
        if (this.owned !== undefined) {
            return !this.statusCode || !this.owned
        } else {
            return !this.statusCode
        }
    }
    public get hide(): boolean {
        if (this.hided) {
            return this.hided;
        } else {
            if (this.ended) {
                return !this.owned;
            } else {
                return this.ended;
            }
        }
    }
}

export interface ChallengeInitCacheCredetials {
    url?: string
    user?: string
    pwd?: string
    accountId?: string
    region?: string
    accessKey?: string
    secretKey?: string
}

export class ChallengeInitCache {
    profileId?: string;
    challengeId?: string;
    challengeName?: string;
    dateInit?: moment.Moment;
    challengeAlias?: string;
    sessionId?: string;
    challengeSk?: string;
    credentials?: ChallengeInitCacheCredetials;

    constructor(config?: any) {
        if (config) {
            this.profileId = (config.profileId !== undefined) ? config.profileId : this.profileId
            this.challengeId = (config.challengeId !== undefined) ? config.challengeId : this.challengeId
            this.challengeName = (config.challengeName !== undefined) ? config.challengeName : this.challengeName
            this.dateInit = (config.dateInit !== undefined) ? moment(config.dateInit).locale('pt-br') : this.dateInit
            this.challengeAlias = (config.challengeAlias !== undefined) ? config.challengeAlias : this.challengeAlias
            this.sessionId = (config.sessionId !== undefined) ? config.sessionId : this.sessionId
            this.sessionId = (config.id !== undefined) ? config.id : this.sessionId
            this.challengeSk = (config.challengeSk !== undefined) ? config.challengeSk : this.challengeSk
            this.credentials = (config.credentials !== undefined) ? config.credentials : this.credentials
        }
    }
}
export class ChallengeInitialingCache {
    profileId?: string;
    challengeId?: string;
    challengeName?: string;
    challengeAlias?: string;
    challengeSk?: string;
    sessionId?: string;

    constructor(config?: any) {
        if (config) {
            this.profileId = (config.profileId !== undefined) ? config.profileId : this.profileId
            this.challengeId = (config.challengeId !== undefined) ? config.challengeId : this.challengeId
            this.challengeName = (config.challengeName !== undefined) ? config.challengeName : this.challengeName
            this.challengeAlias = (config.challengeAlias !== undefined) ? config.challengeAlias : this.challengeAlias
            this.challengeSk = (config.challengeSk !== undefined) ? config.challengeSk : this.challengeSk
            this.sessionId = (config.sessionId !== undefined) ? config.sessionId : this.sessionId
            this.sessionId = (config.id !== undefined) ? config.id : this.sessionId
        }
    }
}

export class ChallengeSession {
    accountId?: string;
    student?: string;
    productor?: string;
    status?: 'Initialing' | 'Started' | 'Finished' | 'Awaiting Evaluation' | 'In Review';
    userName?: string;
    sessionId?: string;
    challengeId?: string;
    startedAt?: moment.Moment;
    finishedAt?: moment.Moment;
    userId?: string;
    courseId?: string;
    id?: string;
    challengeSk?: string;
    approved?: boolean;
    review?: string;
    challengeName?: string;
    points?: number;
    credentials?: ChallengeInitCacheCredetials

    constructor(config?: any) {
        if (config) {
            this.accountId = (config.accountId !== undefined) ? config.accountId : this.accountId
            this.student = (config.student !== undefined) ? config.student : this.student
            this.productor = (config.productor !== undefined) ? config.productor : this.productor
            this.status = (config.status !== undefined) ? config.status : this.status
            this.userName = (config.userName !== undefined) ? config.userName : this.userName
            this.sessionId = (config.sessionId !== undefined) ? config.sessionId : this.sessionId
            this.challengeId = (config.challengeId !== undefined) ? config.challengeId : this.challengeId
            this.startedAt = (config.startedAt !== undefined) ? moment(config.startedAt).locale('pt-br') : this.startedAt
            this.finishedAt = (config.finishedAt !== undefined) ? moment(config.finishedAt).locale('pt-br') : this.finishedAt
            this.userId = (config.userId !== undefined) ? config.userId : this.userId
            this.courseId = (config.courseId !== undefined) ? config.courseId : this.courseId
            this.id = (config.id !== undefined) ? config.id : this.id
            this.challengeSk = (config.challengeSk !== undefined) ? config.challengeSk : this.challengeSk
            this.approved = (config.approved !== undefined) ? !!config.approved : this.approved
            this.review = (config.review !== undefined) ? config.review : this.review
            this.challengeName = (config.challengeName !== undefined) ? config.challengeName : this.challengeName
            this.points = (config.points !== undefined) ? config.points : this.points
            this.credentials = (config.credentials !== undefined) ? config.credentials : this.credentials
        }
    }

    public get duration(): number {
        if (this.startedAt && this.finishedAt) {
            return this.finishedAt.diff(this.startedAt)
        } else {
            return 0
        }
    }
}