<div class="w-100">
    <ng-container *ngIf="label">
        <label class="form-label" [for]="name">{{label}} <span *ngIf="required" class="text-danger">*</span></label>
    </ng-container>
    <ng-container *ngIf="type !== 'area'">
        <input
        [class]="'form-control form-control-'+size+' bg-inverse bg-opacity-5 ' + classes"
        autocomplete="off"
        #ngInput="ngModel"
        [id]="name"
        [name]="name!"
        [type]="type"
        [minlength]="minlength!"
        [maxlength]="maxlength!"
        [required]="required!"
        [(ngModel)]="value"
        [mask]="mask"
        [dropSpecialCharacters]="dropSpecialCharacters"
        [disabled]="disabled!"
        [placeholder]="placeholder"
        [ngClass]="{'is-invalid': ngForm!.submitted && (ngInput?.invalid || isInvalid)}"
        [pattern]="pattern!"
        (ngModelChange)="onInputChange()"
        (blur)="blur.emit($event)"
        (focus)="focus.emit($event)"
        (change)="onChange.emit($event)"
        />
    </ng-container>
    <ng-container *ngIf="type === 'area'">
        <textarea
        [class]="'form-control scrollable form-control-'+size+' bg-inverse bg-opacity-5 '+ classes" 
        autocomplete="off" 
        #ngInput="ngModel" 
        [id]="name" 
        [name]="name!" 
        [required]="required!" 
        [(ngModel)]="value" 
        [minlength]="minlength!" 
        [maxlength]="maxlength!"
        [(ngModel)]="value"
        [disabled]="disabled!"
        [placeholder]="placeholder"
        [ngClass]="{'is-invalid': ngForm!.submitted && (ngInput?.invalid || isInvalid)}"
        [style]="'max-height: '+ maxHeight +'px;min-height: '+ minHeight +'px;resize:'+ (resise ? 'vertical' : 'none')"
        (ngModelChange)="onInputChange()"
        (blur)="blur.emit($event)"
        (focus)="focus.emit($event)"
        (change)="onChange.emit($event)"
        >
        </textarea>
    </ng-container>
    <div class="d-flex justify-content-end pt-1" [ngClass]="{'justify-content-between':ngForm.submitted && (ngInput.invalid || isInvalid)}">
        <div *ngIf="ngForm.submitted && (ngInput.invalid || isInvalid) && !hideInvalidMsg" class="text-danger small"><small>Verifique se o campo foi preenchido corretamente.</small></div>
        <ng-container *ngIf="value?.length && showMaxLength">
            <div class="d-flex justify-content-end small text-muted">
                <small class="fw-bold" [ngClass]="{'text-danger':ngForm.submitted && (ngInput.invalid || isInvalid)}">{{value!.length}}/{{maxlength}}</small>
            </div>
        </ng-container>
    </div>
</div>