import { Injectable } from '@angular/core';
import { Course, Lab, Practice } from '@classes';

@Injectable({
  providedIn: 'root'
})
export class MockService {

  constructor() { }

  /**
 *TODO: REMOVER MOKING
  */

  /** CURSOS mokado do "desafio" */
  public courses_mock: Course[] = [
    new Course(
      {
        id: "d41ec420-3625-11ee-8485-d7bfd083d3e6",
        type: "video",
        imgs: [
          {
            logoMini: "https://assets.cloudfaster.academy/danrezende/img/courses/d41ec420-3625-11ee-8485-d7bfd083d3e6/logomini.png",
            badge: "https://assets.cloudfaster.academy/danrezende/img/courses/d41ec420-3625-11ee-8485-d7bfd083d3e6/badge.png",
            logo: "https://assets.cloudfaster.academy/danrezende/img/courses/d41ec420-3625-11ee-8485-d7bfd083d3e6/logo.png"
          }
        ],
        alias: "advc",
        courseId: "d41ec420-3625-11ee-8485-d7bfd083d3e6",
        description: "O Método ADVC, Arquiteto de Valor Cloud, é uma mentoria completa para profissionais que desejam se posicionar como um arquiteto de cloud que realmente conhece AWS e consegue desenvolver, projetar, desenhar, defender e implementar arquiteturas que resolvem problemas utilizando tecnologias em Cloud e AWS.",
        title: "Método ADVC",
        statusCode: 0,
      }
    )
  ]

  /** CURSO do "desafio" */
  public course_mock_ids: string[] = ["9be4bedd-5782-426f-ab87-9c47fa3877de", "480a1322-b3ac-4ae8-b9f9-5bbf8d432e4e", "3ab3da47-3f27-4035-9281-9004d88e5297", "d6d4ccaf-6f2d-4254-8111-a06155ab270f", "ea29a47e-d818-4987-b24b-e48a5068e401"]

  /** LABORATÓRIOS mokados do "desafio" */
  public labs_mock: Lab[] = [
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Conhecendo a console da AWS",
      "alias": "conhecendo-console-solutions-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#6013d854-e334-4408-a2aa-4a63556071d8",
      "id": "6013d854-e334-4408-a2aa-4a63556071d8",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.000.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Criando um alerta de Budget no console de Billing",
      "alias": "Criando um alerta de Budget no console de Billing-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#4c0a905c-3cc8-4c48-ab8d-d581d03cfa03",
      "id": "4c0a905c-3cc8-4c48-ab8d-d581d03cfa03",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.001.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS IAM - Criando seu primeiro usuário",
      "alias": "Criando um usuário do IAM-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "2a1fbc92-1237-4050-bb0d-d6facf2659da",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.002.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS Trusted Advisor - Conhecendo o serviço",
      "alias": "Conhecendo o Trusted Advisor-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#e3e66e7e-5f94-4ef1-a691-4488122b0add",
      "id": "e3e66e7e-5f94-4ef1-a691-4488122b0add",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.003.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediário",
      "name": "Amazon VPC - Criando e Configurando uma VPC",
      "alias": "vpc-solutions-arch-turma-02-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#5a34ce97-1f97-49c0-8666-3ed3bb56d96b",
      "id": "5a34ce97-1f97-49c0-8666-3ed3bb56d96b",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.004.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 1
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EC2 - Provisionando seu primeiro servidor Web",
      "alias": "primeiro-servidor-web-ec2-solutions-arch-turma-02-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#11983c3e-eb98-4330-992a-705c32d1c24f",
      "id": "11983c3e-eb98-4330-992a-705c32d1c24f",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.005.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 1
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EBS - Adicionando um novo volume EBS na Amazon EC2 - Linux",
      "alias": "adicionando-um-novo-volume-ebs-na-amazon-ec2-linux-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "c027488f-4c10-48a6-97ea-603c7e70c231",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.006.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EBS - Adicionando um novo volume EBS na Amazon EC2 - Windows",
      "alias": "adicionando-um-novo-volume-ebs-na-amazon-ec2-windows-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "51abcf35-2499-487e-9a7e-d0903a3426a3",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.007.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EBS - Editando um volume EBS já existente",
      "alias": "editando-um-volume-ebs-existente-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "72160d20-2e5f-458c-9b80-39fac07a3261",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.008.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediario",
      "name": "Amazon S3 - Criando seu primeiro bucket",
      "alias": "primeiro-bucket-solutions-arch-turma-02-imersao-soluctions-turma-02-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#6ee7b345-ce1f-42dd-b75f-b590747f7763",
      "id": "6ee7b345-ce1f-42dd-b75f-b590747f7763",
      "duration": 2700,
      "statusCode": 0,
      "order": "4.009.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon DynamoDB - Sua primeira tabela NoSQL",
      "alias": "dynamo-solutions-arch-turma-02-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#76d3b4b2-4090-484c-8eb7-1d7a6903f60c",
      "id": "76d3b4b2-4090-484c-8eb7-1d7a6903f60c",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.010.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EC2 - Provisionando um serviço web com EC2 + ELB + EC2 Auto Scaling",
      "alias": "provisionando-um-servico-web-em-ec2-com-autoscaling-e-balanceador-de-carga-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "36304f7f-4332-4fb5-81fb-f8bf526ae04a",
      "duration": 7200,
      "statusCode": 0,
      "order": "4.011.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 1
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Conhecendo o AWS Identity and Access Management (IAM)",
      "alias": "conhecendo-o-aws-iam-solutions-arch-turma-02-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#0e1b68e7-32a0-4d35-b033-f558a639d1df",
      "id": "0e1b68e7-32a0-4d35-b033-f558a639d1df",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.012.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS IAM - Criando uma política e uma IAM Role",
      "alias": "Criando uma política e uma IAM Role-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#648f3120-b79c-424e-a805-6b81dd6998d1",
      "id": "648f3120-b79c-424e-a805-6b81dd6998d1",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.013.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS IAM - Criando uma política de senha",
      "alias": "Criando uma política de senha-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "78df7fa2-8a02-403b-a6c2-c95e3898da01",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.014.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS IAM - Criando uma Access key e Secrets key para um usuário no IAM",
      "alias": "Criando uma Accesskey e Secrets key para um usuário no IAM-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "9c62d1c7-c78f-41b6-8e52-917807c170e7",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.015.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS IAM - Removendo acesso e desabilitando usuário",
      "alias": "Removendo acesso e desabilitando usuário do IA-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#94e796ec-6627-470b-b3b6-bf9874a3303f",
      "id": "94e796ec-6627-470b-b3b6-bf9874a3303f",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.016.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS Secrets Manager - Criando e acessando meu primeiro segredo na AWS",
      "alias": "Criando uma VPC com VPC Wizard-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#7a1f9e37-3a9f-4f46-9674-61d023d75923",
      "id": "7a1f9e37-3a9f-4f46-9674-61d023d75923",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.017.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon VPC - Criando uma VPC do zero, provisionando cada componente manualmente",
      "alias": "Criando uma VPC do zero, provisionando cada componente do zero-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#a051ce13-369a-4fee-b340-739707d4f8d7",
      "id": "a051ce13-369a-4fee-b340-739707d4f8d7",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.018.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "statusCode": 0,
      "order": "4.019.0",
      "difficulty": "Iniciante",
      "name": "Criando uma subnet pública",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "alias": "criando-uma-subnet-publica-advc",
      "level": "Associate",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#5aecdb5d-d9cc-4364-9724-4afff18afd0a",
      "id": "5aecdb5d-d9cc-4364-9724-4afff18afd0a",
      "duration": 3600,
      "owned": 1,
      "totalTries": 0
    }),
    // new Lab({
    //   "courseTitle": "Método ADVC",
    //   "statusCode": 0,
    //   "order": "4.021.0",
    //   "difficulty": "Iniciante",
    //   "name": "Criando uma subnet isolada",
    //   "imgs": {
    //     "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
    //     "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
    //   },
    //   "alias": "Criando uma subnet isolada-advc",
    //   "level": "Associate",
    //   "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
    //   "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#8aa0dead-a588-4f6b-b28d-66e7492355fd",
    //   "id": "8aa0dead-a588-4f6b-b28d-66e7492355fd",
    //   "duration": 3600,
    //   "owned": 1,
    //   "totalTries": 0
    // }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Conhecendo e configurando Security Group e Network ACL",
      "alias": "Trabalhando com Security Groups e as ACLs-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#d4f9568a-b2fa-4372-aabf-5e27065e00d0",
      "id": "d4f9568a-b2fa-4372-aabf-5e27065e00d0",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.023.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EC2 - Provisionando seu primeiro servidor Web escalável",
      "alias": "amazon-ec2-provisionando-seu-primeiro-servidor-web-escalavel-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "76bf5614-fcf3-4599-8baf-0e8736dd8484",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.024.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Criando um volume EBS",
      "alias": "criando-um-volume-ebs-colab-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#f42570a8-ef77-4522-ba90-b9366a47c868",
      "id": "f42570a8-ef77-4522-ba90-b9366a47c868",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.025.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Adicionando EBS extra a uma EC2",
      "alias": "adicionando-ebs-extra-a-uma-ec2-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#215434d5-1210-49fb-80f5-f84573445769",
      "id": "215434d5-1210-49fb-80f5-f84573445769",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.026.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Criando snapshots de EBS",
      "alias": "criando-snapshots-de-ebs-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "66b6bcca-5f85-4320-86a1-4ce5dda6b152",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.027.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EBS - Criando snapshot  e copiando para outra região",
      "alias": "Criando snapshot de EBS, copiando para outra região e criando EBS em outra região-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#5b31d7a0-4c34-487c-8bbf-ca0844c08e71",
      "id": "5b31d7a0-4c34-487c-8bbf-ca0844c08e71",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.028.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Criando um ALB e associando instâncias EC2",
      "alias": "criando-um-alb-e-associando-instancias-ec2-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#9c1c2284-39c1-45a3-aae8-80d33c667401",
      "id": "9c1c2284-39c1-45a3-aae8-80d33c667401",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.029.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediario",
      "name": "Amazon Elastic Load Balancer - Criando e configurando um Network Load Balancer",
      "alias": "Criando um NLB e associando instâncias EC2-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#ea0849d7-2411-4d15-b0a4-c61e50f1c506",
      "id": "ea0849d7-2411-4d15-b0a4-c61e50f1c506",
      "duration": 5400,
      "statusCode": 0,
      "order": "4.030.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS WAF -  Criando regras e aplicando segurança no ALB (Application Load Balancer)",
      "alias": "Adicionando regras de WAF a um ALB-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#572a091e-b7e1-4769-863a-56b0b29bb772",
      "id": "572a091e-b7e1-4769-863a-56b0b29bb772",
      "duration": 5400,
      "statusCode": 0,
      "order": "4.031.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon Elastic Load Balancing  -  Adicionando rotas personalizadas no ALB",
      "alias": "Adicionando rota personalizada no ALB-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#4d274ca5-6150-479c-9186-1c0bfdf6ad0b",
      "id": "4d274ca5-6150-479c-9186-1c0bfdf6ad0b",
      "duration": 5400,
      "statusCode": 0,
      "order": "4.032.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Criando um template do EC2 Auto Scaling",
      "alias": "ciando-um-template-do-ec2-auto-scaling-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "3710c6a3-8deb-410f-a6f5-f77f8e4655d6",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.033.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EC2 Auto Scaling - Implementando e acompanhando o lançamento de Instâncias EC2",
      "alias": "Implementando um EC2 Auto Scaling e acompanhando o lançamento das instâncias EC2-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#bb8085af-a59a-4a41-ae07-4ffea0834be8",
      "id": "bb8085af-a59a-4a41-ae07-4ffea0834be8",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.034.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon RDS - Criando um banco MySQL",
      "alias": "Criando um banco MySQL com RDS-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#429f4a04-104c-49c7-af3f-705acbe4602b",
      "id": "429f4a04-104c-49c7-af3f-705acbe4602b",
      "duration": 5400,
      "statusCode": 0,
      "order": "4.035.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon Aurora - Criando um banco MySQL",
      "alias": "Criando um banco Postgres com Aurora-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#758251e6-8883-48c7-9b3a-2ab2c06d9758",
      "id": "758251e6-8883-48c7-9b3a-2ab2c06d9758",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.038.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediario",
      "name": "Amazon Aurora PostgreSQL - Criando uma réplica de leitura do banco PostgreSQL",
      "alias": "Criando uma réplica de leitura Postgres no Aurora-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#481249b1-9116-4bdd-9e6e-aece8a0becb1",
      "id": "481249b1-9116-4bdd-9e6e-aece8a0becb1",
      "duration": 5400,
      "statusCode": 0,
      "order": "4.039.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Consumindo dados de uma tabela do Amazon DynamoDB via AWS Lambda",
      "alias": "Consumindo dados de uma tabela do DynamoDB via Lambda-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "baf0fb71-401d-406b-9785-cd4f6effdac7",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.040.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon DynamoDB: Realizando sua primeira consulta com PartiQL",
      "alias": "dynamodb-realizando-sua-primeira-consulta-com-partql-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "3673eab2-f993-41b8-853f-7d8dcb3d5fe5",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.041.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon S3 - Trabalhando com versionamento no S3",
      "alias": "trabalhando-com-versionamento-no-s3-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "cc1fd00b-c5e0-4ae8-811c-621f67f7ac04",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.043.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediario",
      "name": "Amazon S3: Implementando política de bucket no S3",
      "alias": "Implementando política de bucket no S3-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#0a6377c2-8b67-472d-872d-1a2148aa8948",
      "id": "0a6377c2-8b67-472d-872d-1a2148aa8948",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.044.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon S3 - Ativando replicação de bucket",
      "alias": "Ativando replicação de bucket no S3-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#8173dc06-5993-4b35-91f5-34d8c6f9df0a",
      "id": "8173dc06-5993-4b35-91f5-34d8c6f9df0a",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.045.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EFS - Configurando um volume entre duas EC2 Linux",
      "alias": "Configurando um EFS entre duas EC2 Linux-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#34a5ed08-7626-48a8-93a0-b9d2307b10b0",
      "id": "34a5ed08-7626-48a8-93a0-b9d2307b10b0",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.047.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS Lambda - Criando e testando sua primeira função Lambda",
      "alias": "lambda-cda-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "699a393b-41e6-451b-9ce2-74d3d44ac1ef",
      "duration": 2400,
      "statusCode": 0,
      "order": "4.048.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS Lambda - interagindo com o Amazon S3",
      "alias": "Criando Lambda com permissão de IAM role para interagir com S3-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#38199a7c-9025-4076-979d-93c8dd886b8b",
      "id": "38199a7c-9025-4076-979d-93c8dd886b8b",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.049.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediario",
      "name": "AWS Lambda - Interagindo back-end com DynamoDB",
      "alias": "Criando Lambda com permissão de IAM role para interagir com DynamoDB-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#5cd9e5ed-c570-43d5-a043-67c293725988",
      "id": "5cd9e5ed-c570-43d5-a043-67c293725988",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.050.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Monitorando uma instância Amazon EC2 com Amazon CloudWatch",
      "alias": "Monitorando uma instância Amazon EC2 com Amazon CloudWatch-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#f6469d2e-0a65-4984-8630-55f6affabc9a",
      "id": "f6469d2e-0a65-4984-8630-55f6affabc9a",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.051.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediario",
      "name": "Primeiro troubleshooting em uma função Lambda com Amazon CloudWatch Logs",
      "alias": "primeiro-troubleshooting-em-uma-funcao-lambda-com-amazon-cloudwatch-logs-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#1bfc65b0-23b7-4bfa-85cd-7a91aded45a3",
      "id": "1bfc65b0-23b7-4bfa-85cd-7a91aded45a3",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.052.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 1
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon CloudTrail - Conhecendo o painel do Amazon CloudTrail",
      "alias": "Primeiros passos com Amazon CloudTrail-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "fff7ec4d-54c8-4b9e-9d4c-56b7a90b0308",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.053.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon SQS - Primeira fila e armazenamento Mensagem",
      "alias": "sqs-cda-imersao-developer-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "085b3e55-49be-4971-8dc7-9ba8693fe075",
      "duration": 2400,
      "statusCode": 0,
      "order": "4.054.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon SNS - Criando um tópico de notificação por e-mail",
      "alias": "sns-cda-imersao-developer-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#c09e9ee2-7f9c-4923-9e7b-1091b3d429a2",
      "id": "c09e9ee2-7f9c-4923-9e7b-1091b3d429a2",
      "duration": 2400,
      "statusCode": 0,
      "order": "4.055.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Avançado",
      "name": "Amazon API Gateway - Criando primeira API",
      "alias": "api-gateway-cda-imersao-developer-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "f56ddc2d-3fab-4452-ab8f-62cab8272f8e",
      "duration": 2400,
      "statusCode": 0,
      "order": "4.056.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Criando uma API no API Gateway que invoca uma função Lambda",
      "alias": "Criando uma API no API Gateway que invoca uma função Lambda-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#207550e3-b814-4b7f-851f-69a90fcee2ea",
      "id": "207550e3-b814-4b7f-851f-69a90fcee2ea",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.057.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Criando um workflow com AWS Step Functions",
      "alias": "Criando um workflow com AWS Step Functions-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#14bca177-9075-4745-a2f4-94e0f77eb7b6",
      "id": "14bca177-9075-4745-a2f4-94e0f77eb7b6",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.058.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS CloudFormation: Criando um bucket S3 com AWS CloudFormation",
      "alias": "Criando um bucket S3 com AWS CloudFormation-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#8db870e1-8ddd-4ca9-ab2e-1d09bacc9334",
      "id": "8db870e1-8ddd-4ca9-ab2e-1d09bacc9334",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.060.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS Systems Manager - Conectando a uma instância EC2 via Session Manager",
      "alias": "Conectando a uma instância EC2 via Session Manager-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#15c5e17f-10fa-40bf-84ec-dad9cfd3ec99",
      "id": "15c5e17f-10fa-40bf-84ec-dad9cfd3ec99",
      "duration": 5400,
      "statusCode": 0,
      "order": "4.061.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Aprendendo a utilizar o AWS CloudShell",
      "alias": "aprendendo-a-utilizar-o-aws-cloudshell-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "01d489f4-578b-4e2f-8e43-7c483a70ee4d",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.062.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS Elastic Beanstalk - Provisionando sua primeira aplicação",
      "alias": "Primeira aplicação com o AWS Elastic Beanstalk-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#7fe7bf25-8796-4fab-8157-dada228bbf60",
      "id": "7fe7bf25-8796-4fab-8157-dada228bbf60",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.063.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EC2 - Alterando tipo da instância (redimensionamento)",
      "alias": "Amazon EC2, alterando tipo da instancia (redimensionamento)-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#059007fd-b122-46c0-97d7-aaaf173c066f",
      "id": "059007fd-b122-46c0-97d7-aaaf173c066f",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.064.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Avançado",
      "name": "Provisionando uma aplicação web com ECS + Fargate",
      "alias": "Provisionando uma aplicação web com ECS + Fargate-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "1843ae5e-0dea-4c95-9e80-50f8198ae8b5",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.064.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon CloudFront - Criando sua primeira distribuição (CDN)",
      "alias": "Criando sua primeira distribuição no CloudFront-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#f0f5d19e-bfaf-489c-9d70-98bf54f22ba5",
      "id": "f0f5d19e-bfaf-489c-9d70-98bf54f22ba5",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.065.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS Global Accelerator - Configurando o Global Accelerator em um Application Load Balancer (ALB)",
      "alias": "Adicionando Global Accelerator em um ALB-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#d5a39a63-6789-429a-9a26-215cfd25c07f",
      "id": "d5a39a63-6789-429a-9a26-215cfd25c07f",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.066.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Criando um VPC endpoint para o Amazon S3",
      "alias": "Criando um VPC endpoint para o Amazon S3-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#65c1872b-abd7-402c-ba28-8959c1066d9d",
      "id": "65c1872b-abd7-402c-ba28-8959c1066d9d",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.066.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Adicionando uma IAM Role em uma EC2 para acessar um bucket do Amazon S3",
      "alias": "Adicionando uma IAM Role em uma EC2 para acessar um bucket do Amazon S3-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#b01e2c6c-7ccb-4645-ac7b-a2bf35b407d4",
      "id": "b01e2c6c-7ccb-4645-ac7b-a2bf35b407d4",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.067.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS Cloud9 - Provisionando e acessando um ambiente de desenvolvimento",
      "alias": "provisionando-e-acessando-um-ambiente-de-desenvolvimento-no-cloud9-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "46d32051-2385-4c18-8a2d-576e88ceccc0",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.068.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Subindo uma imagem Docker para o Amazon ECR",
      "alias": "subindo-uma-imagem-docker-para-o-amazon-ecr-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "19637da5-9951-4547-afef-365e174de6a9",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.068.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "statusCode": 0,
      "order": "4.069.0",
      "difficulty": "Iniciante",
      "name": "Aplicando segurança nas images do Amazon ECR",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "alias": "Aplicando segurança nas images do Amazon ECR-advc",
      "level": "Associate",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#b2a38c12-639f-45a2-989f-c35b7a9b2da4",
      "id": "b2a38c12-639f-45a2-989f-c35b7a9b2da4",
      "duration": 3600,
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS CodeCommit -  Criando meu primeiro repositório",
      "alias": "Criando meu primeiro repositorio com AWS Code Commit-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#d66c2fd2-7597-4dd5-95cb-cc4af3ffb614",
      "id": "d66c2fd2-7597-4dd5-95cb-cc4af3ffb614",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.070.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Como fazer build de aplicações com o AWS Code Build",
      "alias": "Como fazer build de aplicações com o AWS Code Build-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#4685b450-a3a1-4319-b8b2-6c32a6ae21dd",
      "id": "4685b450-a3a1-4319-b8b2-6c32a6ae21dd",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.071.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediario",
      "name": "Provisionado uma EC2 com Terraform",
      "alias": "Provisionado uma EC2 com Terraform-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "2d659f03-80de-4aff-a228-87fb4a2e7147",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.077.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediario",
      "name": "Amazon EC2 - Acessando uma instância via serial",
      "alias": "Acessando uma EC2 via serial-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#9493eb8a-ae4b-4e18-99f3-e7e5a534c3a0",
      "id": "9493eb8a-ae4b-4e18-99f3-e7e5a534c3a0",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.078.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EC2 - Expandindo um EBS em uma EC2 Linux",
      "alias": "Expandindo um EBS em uma EC2 Linux-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#cda761c2-350b-45ae-9197-13050b7df2bc",
      "id": "cda761c2-350b-45ae-9197-13050b7df2bc",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.079.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EBS - Expandindo um EBS em uma EC2 Windows",
      "alias": "Expandindo um EBS em uma EC2 Windows-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "96c69bd9-1b7d-44f0-90c4-91f314c5c2ed",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.080.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EC2 - Recuperar acesso a uma instância sem chave ssh (.pem)",
      "alias": "Recuperar acesso a uma EC2 sem chave ssh (.pem)-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#979d9146-63be-464b-ac3d-b66b3d80505e",
      "id": "979d9146-63be-464b-ac3d-b66b3d80505e",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.082.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Elastic IP addresses - Criando e utilizando meu primeiro EIP (elastic IP)",
      "alias": "Criando e utilizando meu primeiro EIP (elastic IP)-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "546d0cfe-df0f-4160-ae3a-d6597206dfea",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.083.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Conhecendo e configurando acesso via AWS CLI",
      "alias": "conhecendo-e-configurando-acesso-via-aws-cli-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "id": "38d57913-b7cf-412b-9eb6-b57808a29556",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.083.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "statusCode": 0,
      "order": "4.084.0",
      "difficulty": "Iniciante",
      "name": "Utilizando o AWS CLI para recuperar EIP excluído acidentalmente",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "alias": "utilizando-o-aws-cli-para-recuperar-eip-excluido-acidentalmente-advc",
      "level": "Associate",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#b38de9b9-6feb-40ac-a965-8e747ba7c7f9",
      "id": "b38de9b9-6feb-40ac-a965-8e747ba7c7f9",
      "duration": 3600,
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS CLI - enviando arquivos pro Amazon S3",
      "alias": "enviando-arquivos-pro-s3-atravez-do-aws-cli-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#8cf6bc75-4ddf-4081-a215-4357c6c572b1",
      "id": "8cf6bc75-4ddf-4081-a215-4357c6c572b1",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.085.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Configurando perfis de acesso no AWS CLI",
      "alias": "configurando-perfis-de-acesso-no-aws-cli-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#fc00a8ce-cab9-41ff-aba6-588fbe193ebe",
      "id": "fc00a8ce-cab9-41ff-aba6-588fbe193ebe",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.086.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Criando minha primeira AMI de uma EC2",
      "alias": "criando-minha-primeira-ami-de-uma-ec2-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#b4919855-0cad-44a9-8ab9-6487289513e2",
      "id": "b4919855-0cad-44a9-8ab9-6487289513e2",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.088.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Subindo uma EC2 atravez de uma AMI criada",
      "alias": "subindo-uma-ec2-atravez-de-uma-ami-criada-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#090db2b4-9e2a-40a1-9edb-4539ad2f838c",
      "id": "090db2b4-9e2a-40a1-9edb-4539ad2f838c",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.089.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "statusCode": 0,
      "order": "4.090.0",
      "difficulty": "Iniciante",
      "name": "Aplicação Serverless com API Gateway + Lambda + SQS + DynamoDB + EventBridge",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "alias": "aplicacao-serverless-com-api-gateway-+-lambda-+-sqs-+-dynamodb-+-eventbridge-advc",
      "level": "Associate",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#511638e7-34ea-4126-b920-a31000edcd0b",
      "id": "511638e7-34ea-4126-b920-a31000edcd0b",
      "duration": 3600,
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediario",
      "name": "Amazon EC2 - Agendando ligar e desligar instancia com Amazon EventBridge",
      "alias": "ligando-e-desligando-ec2-com-amazon-eventbridge-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#b8355a07-01c1-492e-b610-dfd10f690689",
      "id": "b8355a07-01c1-492e-b610-dfd10f690689",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.091.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Criando uma imagem Docker e rodando no Lambda",
      "alias": "criando-uma-imagem-docker-e-rodando-no-lambda-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#46fa4009-34bb-41b6-aee3-a15352c9ac5e",
      "id": "46fa4009-34bb-41b6-aee3-a15352c9ac5e",
      "duration": 28800,
      "statusCode": 0,
      "order": "4.092.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EC2 - Provisionando minha primeira instância SPOT",
      "alias": "provisionando-minha-primeira-instancia-spot-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#096bd17b-ea43-460e-a19f-1fb2db5ae227",
      "id": "096bd17b-ea43-460e-a19f-1fb2db5ae227",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.093.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS KMS - Primeiros passos com o AWS KMS",
      "alias": "primeiros-passos-com-o-aws-kms-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#2f8f46d0-f60f-4639-8fd0-6ba934a07001",
      "id": "2f8f46d0-f60f-4639-8fd0-6ba934a07001",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.095.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "AWS KMS - Simulando a criação e importação de uma chave externa de criptografia",
      "alias": "importando-uma-chave-para-o-kms-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#51a0dfac-d2f5-4c17-816d-9ef328352f46",
      "id": "51a0dfac-d2f5-4c17-816d-9ef328352f46",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.096.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "name": "Amazon EC2 - Acessando uma EC2 Linux via System Manager",
      "alias": "acessando-uma-ec2-linux-via-system-manager-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#222c5f52-3e25-4c76-a792-3c5247ea9e33",
      "id": "222c5f52-3e25-4c76-a792-3c5247ea9e33",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.098.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediario",
      "name": "AWS System Manager -  Acessando uma EC2 Linux via Fleet Access",
      "alias": "acessando-uma-ec2-linux-via-fleet-access-do-system-manager-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#d9e2bd0f-d67e-483f-8532-02686f33f877",
      "id": "d9e2bd0f-d67e-483f-8532-02686f33f877",
      "duration": 5400,
      "statusCode": 0,
      "order": "4.099.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Avançado",
      "name": "Criando um RDS em Alta Disponibilidade (Multi-AZ) e habilitando a Réplica de Leitura",
      "alias": "configurando-rds-com-alta-disponiblidade-multi-az-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#e8a93ec8-c636-4c16-973a-7f503f314615",
      "id": "e8a93ec8-c636-4c16-973a-7f503f314615",
      "duration": 5400,
      "statusCode": 0,
      "order": "4.101.0",
      "imgs": {
        "logoMini": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png",
        "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/de01ab00-19cf-11ee-9d07-3726b9a4c449/logo.png"
      },
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "name": "Amazon VPC - Criando um VPC Peering entre 2 VPC’s diferentes",
      "alias": "criando-um-vpc-peering-entre-2-vpcs-diferentes-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#25813f41-2de3-48f0-94a5-14f456dd509f",
      "id": "25813f41-2de3-48f0-94a5-14f456dd509f",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.102.0",
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "name": "Amazon EC2 - Montando um Bucket do S3 em uma instância EC2 Linux",
      "alias": "montando-um-s3-na-ec2-linux-advc",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#9fff7d73-578d-4e61-a86c-ceb06b048e0b",
      "id": "9fff7d73-578d-4e61-a86c-ceb06b048e0b",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.103.0",
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "alias": "armazenando-e-acessando-uma-imagem-com-url-assinada-no-s3-advc",
      "name": "Amazon S3 - Armazenando e acessando uma imagem com URL assinada no S3",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#00e0c7f4-64a9-4624-9aa2-67fa01ecc4c2",
      "sketch": false,
      "id": "00e0c7f4-64a9-4624-9aa2-67fa01ecc4c2",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.104.0",
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "alias": "provisionando-ec2-com-docker-e-imagens-linux-advc",
      "name": "Provisionando EC2 com Docker e imagens Linux",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#958e9ecf-4415-4f74-b8b6-eb340be27684",
      "sketch": false,
      "id": "958e9ecf-4415-4f74-b8b6-eb340be27684",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.105.0",
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediario",
      "alias": "crie-e-implante-aplicativos-usando-codedeploy-e-codepipeline-advc",
      "name": "Crie e implante aplicativos usando CodeDeploy e CodePipeline",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#6efdc3da-1faf-41df-ac57-662ee2e4c6a7",
      "sketch": false,
      "id": "6efdc3da-1faf-41df-ac57-662ee2e4c6a7",
      "duration": 5400,
      "statusCode": 0,
      "order": "4.107.0",
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Iniciante",
      "alias": "criando-uma-subnet-privada-advc",
      "name": "Criando uma Subnet Privada",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#03726e68-f483-49da-a414-87b0bb05534e",
      "sketch": false,
      "id": "03726e68-f483-49da-a414-87b0bb05534e",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.108.0",
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediario",
      "alias": "configurando-openvpn-server-em-uma-ec2-linux-advc",
      "name": "Configurando OpenVPN Server em uma EC2 Linux",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#812d2387-7a4c-4c38-9cc2-fc33196bf434",
      "sketch": true,
      "id": "812d2387-7a4c-4c38-9cc2-fc33196bf434",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.109.0",
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Avançado",
      "alias": "provisionando-um-bucket-s3-com-terraform-advc",
      "name": "Provisionando um Bucket S3 com Terraform",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#90c87b4f-ffa9-464c-b94d-8fc29302b5a5",
      "sketch": false,
      "id": "90c87b4f-ffa9-464c-b94d-8fc29302b5a5",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.110.0",
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Avançado",
      "alias": "provisionando-um-dynamodb-com-terraform-advc",
      "name": "Provisionando um DynamoDB com Terraform",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#d2193e09-3c03-4258-85fa-6a67c4d420de",
      "sketch": false,
      "id": "d2193e09-3c03-4258-85fa-6a67c4d420de",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.111.0",
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Avançado",
      "alias": "provisionando-um-api-gateway-com-terraform-advc",
      "name": "Provisionando um API Gateway com Terraform",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#26c3f933-9a73-4db3-8fb6-6a86de5677be",
      "sketch": false,
      "id": "26c3f933-9a73-4db3-8fb6-6a86de5677be",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.112.0",
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Avançado",
      "alias": "criando-meu-cofre-de-senhas-com-aws-lambda-kms-dynamodb-advc",
      "name": "Criando meu cofre de senha com AWS Lambda e KMS ",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#df815caa-7332-4f01-8484-d59da9d773d4",
      "sketch": false,
      "id": "df815caa-7332-4f01-8484-d59da9d773d4",
      "duration": 5400,
      "statusCode": 0,
      "order": "4.113.0",
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediario",
      "alias": "provisionando-um-usuario-do-iam-com-terraform-advc",
      "name": "Provisionando um usuário IAM com terraform",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#6179cff9-fadf-41ff-91fc-98837f2f808e",
      "sketch": false,
      "id": "6179cff9-fadf-41ff-91fc-98837f2f808e",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.114.0",
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
    new Lab({
      "courseTitle": "Método ADVC",
      "difficulty": "Intermediario",
      "alias": "aws-cloudshell-rodando-containers-docker-advc",
      "name": "Rodando Containers Docker",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "sk": "d41ec420-3625-11ee-8485-d7bfd083d3e6#dde5cf90-0bc1-4572-ab5c-b252e256d3d2",
      "sketch": false,
      "id": "dde5cf90-0bc1-4572-ab5c-b252e256d3d2",
      "duration": 3600,
      "statusCode": 0,
      "order": "4.115.0",
      "level": "Associate",
      "owned": 1,
      "totalTries": 0
    }),
  ]

  /** SIMULADOS mokados do "desafio" */
  public practice_mock: Practice[] = [
    new Practice({
      "instructions": "<p>Responda as questões, revise o que for necessário e finalize o exame para verificar a pontuação alcançada.</p>\n<p>Recomendamos marcar o exame se você alcançar 80% ou mais.</p>\n<p>Ao finalizar, você pode acessar dicas e links de documentações que te apoiam no processo de aprendizado.</p>\n<p>Caso encontre algum erro, por favor nos reporte. Tenha um bom simulado.</p>",
      "timetoFinish": 90,
      "statusCode": 0,
      "order": "1.0.0",
      "name": "Simulado AWS Cloud Practitioner",
      "imgs": [
        {
          "logoMini": "https://assets.cloudfaster.academy/danrezende/tests/ccp/logoMini.png",
          "badge": "https://assets.cloudfaster.academy/danrezende/img/courses/b7b5a970-16bd-11ee-a76f-01a579548ce3/badge.png",
          "logo": "https://assets.cloudfaster.academy/danrezende/img/courses/04a905b9-0f31-4814-a9a6-004ba9ede16e/logo.png"
        }
      ],
      "alias": "simulado-practitioner-advc",
      "level": "Pratictioner",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "qtdQuestions": 65,
      "dateIncluded": "2023-07-07 16:36:47",
      "description": "Simulado - AWS Cloud Practitioner",
      "id": "f20c7939-0ad7-456c-b8f0-ebc33761d0ec",
      "hide": 0,
      "title": "Simulado practitioner",
      "courseTitle": "Método ADVC",
      "courseAlias": "advc"
    }),
    new Practice({
      "instructions": "<p>Responda as questões, revise o que for necessário e finalize o exame para verificar a pontuação alcançada.</p>\n<p>Recomendamos marcar o exame se você alcançar 80% ou mais.</p>\n<p>Ao finalizar, você pode acessar dicas e links de documentações que te apoiam no processo de aprendizado.</p>\n<p>Caso encontre algum erro, por favor nos reporte. Tenha um bom simulado.</p>",
      "timetoFinish": 130,
      "statusCode": 0,
      "order": "1.1.0",
      "name": "Simulado AWS Solutions Architect Associate",
      "imgs": [
        {
          "logoMini": "https://assets.cloudfaster.academy/danrezende/tests/saa/logoMini.png",
          "badge": "https://assets.cloudfaster.academy/danrezende/img/courses/b7b5a970-16bd-11ee-a76f-01a579548ce3/badge.png",
          "logo": "https://assets.cloudfaster.academy/danRezende/testes/8ab4b540-9ec8-4f62-8c0a-7c293cd32c24/logo.png"
        }
      ],
      "alias": "simulado-solutions-advc",
      "level": "Associate",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "qtdQuestions": 65,
      "dateIncluded": "2023-07-07 16:36:47",
      "description": "Simulado - AWS Solutions Architect Associate",
      "id": "313f683d-5d11-4e86-b8ef-4809f80bb4b9",
      "title": "Simulado AWS Solutions Architect Associate",
      "courseTitle": "Método ADVC",
      "courseAlias": "advc"
    }),
    new Practice({
      "instructions": "<div>Responda as questões, revise o que for necessário e finalize o exame para verificar a pontuação alcançada.<div/><div><br></div><div>Recomendamos marcar o exame se você alcançar 80% ou mais.<br></div><div>Ao finalizar, você pode acessar dicas e links de documentações que te apoiam no processo de aprendizado.<div/><div><br></div><div>Caso encontre algum erro, por favor nos reporte.Tenha um bom simulado.<div/>",
      "timetoFinish": 130,
      "statusCode": 0,
      "order": "1.2.0",
      "name": "Simulado AWS Developer Associate",
      "imgs": [
        {
          "logoMini": "https://assets.cloudfaster.academy/danrezende/tests/cda/logoMini.png",
          "badge": "https://assets.cloudfaster.academy/danrezende/img/courses/b7b5a970-16bd-11ee-a76f-01a579548ce3/badge.png",
          "logo": "https://assets.cloudfaster.academy/danRezende/testes/8fb32b1e-52e1-40dd-9199-fd2a78bade5c/logo.png"
        }
      ],
      "alias": "simulado-developer-advc",
      "level": "Associate",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "qtdQuestions": 65,
      "dateIncluded": "2023-07-07 16:36:47",
      "description": "Simulado - AWS Developer Associate",
      "id": "8fb32b1e-52e1-40dd-9199-fd2a78bade5c",
      "title": "Simulado AWS Developer Associate",
      "courseTitle": "Método ADVC",
      "courseAlias": "advc"
    }),
    new Practice({
      "instructions": "<div>Responda as questões, revise o que for necessário e finalize o exame para verificar a pontuação alcançada.<div/><div><br></div><div>Recomendamos marcar o exame se você alcançar 80% ou mais.<br></div><div>Ao finalizar, você pode acessar dicas e links de documentações que te apoiam no processo de aprendizado.<div/><div><br></div><div>Caso encontre algum erro, por favor nos reporte.Tenha um bom simulado.<div/>",
      "timetoFinish": 130,
      "statusCode": 0,
      "order": "1.3.0",
      "name": "Simulado AWS SysOps Administrator Associate",
      "imgs": [
        {
          "logoMini": "https://assets.cloudfaster.academy/danrezende/tests/advc/logoMini.png",
          "badge": "https://assets.cloudfaster.academy/danrezende/img/courses/b7b5a970-16bd-11ee-a76f-01a579548ce3/badge.png",
          "logo": "https://assets.cloudfaster.academy/danrezende/tests/advc/logo.png"
        }
      ],
      "alias": "simulado-sysops-advc",
      "level": "Associate",
      "courseId": "d41ec420-3625-11ee-8485-d7bfd083d3e6",
      "qtdQuestions": 65,
      "dateIncluded": "2023-07-07 16:36:47",
      "description": "Simulado - SysOps Administrator Associate",
      "id": "8c51d38e-d375-4572-8c19-ca5fec1c9206",
      "title": "Simulado SysOps Administrator Associate",
      "courseTitle": "Método ADVC",
      "courseAlias": "advc"
    }),
  ]

  /** ID - MODULO DE RECURSOS do "desafio" */
  public modules_resource_mock_ids: string[] = ['9dd24137-6776-41c2-9d28-ecfe5b57fb83', '0c31b8d7-58ab-49df-a956-d39d08c4aeb4', 'ea826dc7-387d-4a82-982a-fe97239a5e2d', '5f61350a-a2a8-4c09-b24b-0f3ee2232c3e', '1b509564-57ff-460a-bd02-7f8252ca9299', '9193dcf2-a070-4456-8472-edc997d51836', 'c8220d5b-55f6-4670-9a2f-aa874d7feb33', 'ec39e9d8-518e-4451-b9aa-50169eac9ba9', '7133ff28-8204-4c27-9fcd-55b7a43e3ab2', '15b44ff1-bab2-4b27-b0d5-d877707e3743', 'f0219578-8006-44c3-8d78-a9d2e2dc9150', '15b44ff1-bab2-4b27-b0d5-d877707e3743', '4a99efbb-7e14-4e8e-932a-935cbd5ab508', '323e00c5-4da2-442f-b915-f911e0ba932e', 'dab6cbef-a287-4f0c-9c7e-2e68ea03717e']

  /** ID - MODULO 01 do "desafio" */
  public module_initial_mock_ids: string[] = ['b0e54228-3d83-43de-b92c-d795f6dd82eb', 'e7a30bed-06c8-4487-9820-f39ef72e1142', '33db1652-dfa0-4c7d-b134-4b52712cd7c1', '8055f4ae-b42c-468b-ad65-0cd316026ac1', '4d295f06-d2cd-4a9f-979c-5f3c92c1c40a']

  /** ID - PRIMERIA AULA do "desafio" */
  public classe_open_challenge_mock_ids: string[] = ["6e409a03-f3e9-4a73-8c99-ee7da987e08f", "9dee8b7f-d0af-41bb-b9ca-3457217122f9", "b409aa37-2c6f-498c-ae29-804c21dd3147", "29ee999c-0f72-4f76-b926-f63d60279ea0", "9955343e-f577-43c3-a3b3-34672b8b27b7"]

  /** ID - AULA DE DESAFIO do "desafio" */
  public classe_challenge_mock_ids: string[] = ['dbfa0ed8-2e6f-48d3-9a27-e9acc8acad39', '5558a09b-8b42-40df-9136-97ed8b17c0ab', '8ef358f9-c821-4287-bab8-c7518542e00e', '0cfc083f-f283-45e9-bf23-f51044f1e43b', '90f5beab-813a-4c01-ae04-61779898b422']

  /** ID - MODULO DO DESAFIO do "desafio" */
  public module_challenge_mock_ids: string[] = ['9ee9a861-b64e-481b-8d38-941d272e13cf', '0b1aea65-fb04-4eff-8465-b204e9982b1f', '431476a2-9e21-4aa4-82be-385d5273b38e', '417e17b2-8ecb-4586-9173-fea8d304ce68', '5c44ca73-0c82-46d5-bc21-e4588ad9ec09']

  /** ID - AULA LIBERADA APÓS O DESAFIO do "desafio" */
  public classe_after_challenge_mock_ids: string[] = ['f37b1680-f493-45c4-ad43-75e93425b27c', 'edb0fdc0-1c2f-4934-97b0-44462868b745', '985649f4-9f8b-4061-8427-6c1db94ca3f1', '18ff9f70-fe01-48dd-afa4-c2437e943d88', '4c164d0d-d613-4935-bd8d-f244a9418141']

}
