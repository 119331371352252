import { IconName } from '@fortawesome/free-solid-svg-icons'

export class Marker {
    public name?:string;
    public size?:  "sm" | "md" | "lg" = "md";
    public position?: number;
    public icon?:IconName
    constructor(config:any) {
        this.name = (config.name !== undefined) ? config.name : this.name;
        this.size = (config.size !== undefined) ? config.size : this.size;
        this.position = (config.position !== undefined) ? config.position : this.position;
        this.icon = (config.icon !== undefined) ? config.icon : this.icon;
    }
}