<div [class]="'practice-card d-flex align-items-center shadow-sm rounded-3 px-4 w-100 overflow-hidden bg-altern '+classes" #practiceRef [ngClass]="{'locked bg-opacity-75':practice.locked}" (click)="!practice.locked ? goRoute() : ''">
    <div class="w-100">
        <div class="row practice-details align-items-center h-100">
            <div class="col-7">
                <div class="d-flex align-items-center gap-3">
                    <div class="practice-image position-relative">
                        <ng-container *ngIf="practice.imgs![0]?.logoMini">
                            <div class="img w-100 h-100" [style]="'background-image:url('+practice.imgs![0].logoMini+')'"></div>
                        </ng-container>
                        <div class="locked-icon d-flex justify-content-center align-items-center rounded-circle w-50px h-50px">
                            <fa-icon class="text-white fa-2x" [icon]="['fas','lock']"></fa-icon>
                        </div>
                    </div>
                    <div class="practice-title py-2">
                        <span class="d-block fs-15px fw-bold lh-sm" [ngClass]="{'text-gray-400':practice.locked}" [innerHTML]="practice.name | noSanitize"></span>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="practice-hour py-2 h-100">
                    <div class="d-flex align-items-center gap-1 lh-1 h-100">
                        <fa-icon class="fa-sm d-flex text-inverse text-opacity-50" [ngClass]="{'text-gray-400':practice.locked}" [icon]="['fas','clock']" [fixedWidth]="true"></fa-icon>
                        <span class="text-inverse text-opacity-60" [ngClass]="{'text-gray-400':practice.locked}">{{getHr(practice.timetoFinish!)}}</span>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="d-flex align-items-center justify-content-center gap-1 h-100" [ngClass]="{'text-gray-400':practice.locked}">
                    <span class="text-inverse text-opacity-60" [innerHTML]="practice.level | noSanitize"></span>
                </div>
            </div>
            <div class="col-1">
                <div class="d-flex justify-content-center align-items-center gap-1 h-100" >
                    <div class="d-flex align-items-center gap-1">
                        <fa-icon  [ngClass]="{'text-gray-400':practice.locked}" class="fa-sm d-flex text-inverse text-opacity-50" [ngClass]="{'text-gray-400':practice.locked}" [icon]="['fas','clipboard']"></fa-icon>
                        <span [ngClass]="{'text-gray-400':practice.locked}" class="text-inverse text-opacity-60" [ngClass]="{'text-gray-400':practice.locked}" [innerHTML]="practice.qtdQuestions?.toString() | noSanitize"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>