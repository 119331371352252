import * as moment from "moment-timezone"
import { CourseImage } from "./course"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faFile, faFileAudio, faFileCode, faFileExcel, faFileImage, faFileLines, faFilePdf, faFileZipper } from "@fortawesome/free-regular-svg-icons"
import { faFileCsv, faFileVideo } from "@fortawesome/free-solid-svg-icons"
import { HttpClient, HttpHandler } from "@angular/common/http"
export class Lab {
    max_provision?: number
    howto?: string
    howtofile?: string
    howtoType?: 'MD' | 'HTML' | 'SUKA' = 'MD'
    courseId?: string
    courseTitle?: string
    order?: string
    description?: string
    id?: string
    sk?: string
    alias?: string
    shareHtml?: string
    statusHtml?: boolean = false
    shareAttachment?: ShareAttachment[] = []
    name?: string
    duration?: number = 0
    owned?: number
    statusCode?: number = 0
    onProgress?: boolean = false
    onInialing?: boolean = false
    isGetId?: boolean = false
    imgs?: CourseImage
    level?: string = 'Practice'
    difficulty?: string = 'Inciante'
    hided?: boolean = false;
    ended: boolean = false;
    totalTries: number = 0


    constructor(config?: any) {
        if (config) {
            this.max_provision = (config.max_provision !== undefined) ? config.max_provision : this.max_provision
            this.howtofile = (config.howtofile !== undefined) ? config.howtofile : this.howtofile
            this.howto = (config.howto !== undefined) ? config.howto : this.howto
            this.howtoType = (config.howtoType !== undefined) ? config.howtoType : this.howtoType
            this.courseId = (config.courseId !== undefined) ? config.courseId : this.courseId
            this.courseTitle = (config.courseTitle !== undefined) ? config.courseTitle : this.courseTitle
            this.order = (config.order !== undefined) ? config.order : this.order
            this.description = (config.description !== undefined) ? config.description : this.description
            this.id = (config.id !== undefined) ? config.id : this.id
            this.sk = (config.sk !== undefined) ? config.sk : this.sk
            this.alias = (config.alias !== undefined) ? config.alias : this.alias
            this.shareHtml = (config.shareHtml !== undefined) ? config.shareHtml : this.shareHtml
            this.statusHtml = (config.statusHtml !== undefined) ? !!config.statusHtml : this.statusHtml
            this.name = (config.name !== undefined) ? config.name : this.name
            this.owned = (config.owned !== undefined) ? config.owned : this.owned
            this.statusCode = (config.statusCode !== undefined) ? config.statusCode : this.statusCode
            this.duration = (config.duration !== undefined) ? (config.duration * 1000) : this.duration
            this.imgs = (config.imgs !== undefined) ? config.imgs : this.imgs
            this.level = (config.level !== undefined) ? config.level : this.level
            this.difficulty = (config.difficulty !== undefined) ? config.difficulty : this.difficulty
            this.hided = (config.hide !== undefined) ? !!config.hide : this.hided;
            this.ended = (config.ended !== undefined) ? config.ended : this.ended
            this.totalTries = (config.totalTries !== undefined) ? config.totalTries : this.totalTries
            this.shareAttachment = (config.shareAttachment !== undefined) ? config.shareAttachment.map((x: any) => new ShareAttachment(x)) : this.shareAttachment

        }
    }
    public get locked(): boolean {
        if (this.owned !== undefined) {
            return !this.statusCode || !this.owned
        } else {
            return !this.statusCode
        }
    }
    public get hide(): boolean {
        if (this.hided) {
            return this.hided;
        } else {
            if (this.ended) {
                return !this.owned;
            } else {
                return this.ended;
            }
        }
    }
}

export type LabAtt = keyof Lab;

export class LabAgrouping {
    title!: string;
    lockedCount: number = 0;
    labs: Lab[] = []
    take: number = 10
    takeLab: number = 10
    totalLabs?: LabsTotalsOptions
    loadingLabs: boolean = false
    constructor(config?: any) {
        if (config) {
            this.title = (config.title !== undefined) ? config.title : this.title
            this.lockedCount = (config.lockedCount !== undefined) ? config.lockedCount : this.lockedCount
            this.labs = (config.labs !== undefined) ? config.labs : this.labs
            this.totalLabs = (config.totalLabs !== undefined) ? config.totalLabs : this.totalLabs
        }
    }
    public load() {
        this.loadingLabs = true;
        setTimeout(() => {
            this.takeLab += this.take;
            this.loadingLabs = false;
        }, Math.ceil(Math.random() * 3000))
    }
    public get labsLoaded(): Lab[] {
        return this.labs.slice(0, this.takeLab);
    }
    public get labsTotal(): LabsTotalsOptions {
        if (this.totalLabs) {
            return this.totalLabs
        } else {
            let labsTotal: LabsTotalsOptions = {
                total: this.labs.length,
                totalActive: 0
            }
            this.labs.forEach((lab) => {
                if (!lab.onProgress && !lab.locked && !lab.onInialing) {
                    labsTotal.totalActive++
                }
            })
            return labsTotal;
        }
    }
}
export interface LabAtributesAgrouping {
    name: string;
    order?: string[]
    attribute: LabAtt
}
export class LabFilter {
    options: LabFilterOptions[] = []
    constructor(config?: any) {
        if (config) {
            this.options = (config.options !== undefined) ? config.options.map((x: any) => new LabFilterOptions(x)) : this.options
        }
    }
    public get isActive(): boolean {
        let active = false;
        this.options.forEach((opt) => {
            opt.values?.forEach((v) => {
                if (v.active) {
                    active = true;
                }
            })
        })
        return active;
    }
    public clearFilter() {
        this.options.forEach((opt) => {
            opt.values?.forEach((v) => {
                v.active = false;
            })
        })
    }

}
export class LabFilterOptions {
    title?: string
    keyValue?: LabAtt
    values?: LabFilterOptionsValues[]

    constructor(config?: any) {
        if (config) {
            this.title = (config !== undefined) ? config.title : this.title;
            this.keyValue = (config !== undefined) ? config.keyValue : this.keyValue;
            this.values = (config !== undefined) ? config.values : this.values;
        }
    }

    public verify(lab: Lab): boolean {
        /**
         ** Verificar se o lab informado passa por esse opção filtro
         */
        const VALUES_ACTIVE = this.values?.filter((v) => v.active);
        if (VALUES_ACTIVE!.length > 0) {
            const LAB_ITEM = lab[this.keyValue!];
            VALUES_ACTIVE?.find(v => {
                return v.identify == LAB_ITEM
            })
            return !!(VALUES_ACTIVE?.find(v => v.identify == LAB_ITEM))
        } else {
            return true;
        }
    }
}
export interface LabFilterOptionsValues {
    name: string,
    identify: string,
    active: boolean
}


export class LabInitCache {
    profileId?: string;
    labId?: string;
    labName?: string;
    dateInit?: moment.Moment;
    labAlias?: string;
    sessionId?: string;
    labSk?: string;
    credentials?: LabInitCacheCredetials;

    constructor(config?: any) {
        if (config) {
            this.profileId = (config.profileId !== undefined) ? config.profileId : this.profileId
            this.labId = (config.labId !== undefined) ? config.labId : this.labId
            this.labName = (config.labName !== undefined) ? config.labName : this.labName
            this.dateInit = (config.dateInit !== undefined) ? moment(config.dateInit).locale('pt-br') : this.dateInit
            this.labAlias = (config.labAlias !== undefined) ? config.labAlias : this.labAlias
            this.sessionId = (config.sessionId !== undefined) ? config.sessionId : this.sessionId
            this.labSk = (config.labSk !== undefined) ? config.labSk : this.labSk
            this.credentials = (config.credentials !== undefined) ? config.credentials : this.credentials

        }
    }
}
export class LabInitialingCache {
    profileId?: string;
    labId?: string;
    labName?: string;
    labAlias?: string;
    labSk?: string;
    sessionId?: string;

    constructor(config?: any) {
        if (config) {
            this.profileId = (config.profileId !== undefined) ? config.profileId : this.profileId
            this.labId = (config.labId !== undefined) ? config.labId : this.labId
            this.labName = (config.labName !== undefined) ? config.labName : this.labName
            this.labAlias = (config.labAlias !== undefined) ? config.labAlias : this.labAlias
            this.labSk = (config.labSk !== undefined) ? config.labSk : this.labSk
            this.sessionId = (config.sessionId !== undefined) ? config.sessionId : this.sessionId
        }
    }
}
export interface LabInitCacheCredetials {
    url?: string
    user?: string
    pwd?: string
    accountId?: string
    region?: string
    accessKey?: string
    secretKey?: string
}
export class LabSession {
    accountId?: string;
    student?: string;
    productor?: string;
    status?: 'Initialing' | 'Started' | 'Finished' | 'Timeout';
    userName?: string;
    sessionId?: string;
    labId?: string;
    startedAt?: moment.Moment;
    finishedAt?: moment.Moment;
    userId?: string;
    courseId?: string;
    id?: string;
    labSk?: string;
    credentials?: LabInitCacheCredetials

    constructor(config?: any) {
        if (config) {
            this.accountId = (config.accountId !== undefined) ? config.accountId : this.accountId
            this.student = (config.student !== undefined) ? config.student : this.student
            this.productor = (config.productor !== undefined) ? config.productor : this.productor
            this.status = (config.status !== undefined) ? config.status : this.status
            this.userName = (config.userName !== undefined) ? config.userName : this.userName
            this.sessionId = (config.sessionId !== undefined) ? config.sessionId : this.sessionId
            this.labId = (config.labId !== undefined) ? config.labId : this.labId
            this.startedAt = (config.startedAt !== undefined) ? moment(config.startedAt).locale('pt-br') : this.startedAt
            this.finishedAt = (config.finishedAt !== undefined) ? moment(config.finishedAt).locale('pt-br') : this.finishedAt
            this.userId = (config.userId !== undefined) ? config.userId : this.userId
            this.courseId = (config.courseId !== undefined) ? config.courseId : this.courseId
            this.id = (config.id !== undefined) ? config.id : this.id
            this.labSk = (config.labSk !== undefined) ? config.labSk : this.labSk
            this.credentials = (config.credentials !== undefined) ? config.credentials : this.credentials
        }
    }

    public get duration(): number {
        if (this.startedAt && this.finishedAt) {
            return this.finishedAt.diff(this.startedAt)
        } else {
            return 0
        }
    }
}

export interface LabsTotals {
    [key: string]: LabsTotalsOptions
}
export interface LabsTotalsOptions {
    total: number,
    totalActive: number,
}


export interface LabCourseInit {
    labId?: string
    labAlias?: string
    labName?: string
    courseId?: string
    courseAlias?: string
    courseTitle?: string
    classeId?: string
    classeAlias?: string
    classeName?: string
    moduleId?: string
    moduleAlias?: string
    moduleName?: string
    watched?: boolean
    collectionAlias?: string
}

export class ShareAttachment {
    id?: string;
    name?: string;
    sizeByte?: number;
    downloadUrl?: string
    isDownloaded?: boolean = false
    type?: string;
    url?: string;
    uri?: string;
    constructor(config?: any) {
        if (config) {
            this.id = (config.id !== undefined) ? config.id : this.id
            this.name = (config.name !== undefined) ? config.name : this.name
            this.sizeByte = (config.sizeByte !== undefined) ? config.sizeByte : this.sizeByte
            this.url = (config.url !== undefined) ? encodeURI(config.url) : this.url
            this.uri = (config.uri !== undefined) ? config.uri : this.uri
            this.type = (config.type !== undefined) ? config.type : this.type
        }
    }
    public get isImage(): boolean {
        return !!this.type?.match(/image.*/)
    }

    public get icon(): IconDefinition {
        const icon: any = {
            'image': faFileImage,
            'video': faFileVideo,
            'audio': faFileAudio,
            'application': faFilePdf,
            'code': faFileCode,
            'csv': faFileCsv,
            'zip': faFileZipper,
            'excel': faFileExcel,
            'text': faFileLines,
            'default': faFile
        }
        const TYPE_FILE_PREFIX = this.type?.split('/')[0];
        if (this.type?.match(/image.*/)) return icon['image'];
        else if (this.type?.match(/video.*/)) return icon['video']
        else if (this.type?.match(/audio.*/)) return icon['audio']
        else if (this.type?.match(/json/)) return icon['code']
        else if (TYPE_FILE_PREFIX === 'application') return icon['application']
        else if (this.type?.match(/csv/)) return icon['csv']
        else if (this.type?.match(/text.*/)) return icon['text']
        else return icon['default']
    }

    public get size(): string {
        if (this.sizeByte) {
            const KB = Math.floor(this.sizeByte / 1000);
            const MB = Math.floor(KB / 1000);
            if (MB > 0) {
                return `${MB}MB`;
            } else if (KB > 0) {
                return `${KB}KB`;
            } else {
                return `${this.sizeByte}byte`;
            }
        } else {
            return "0byte";
        }
    }

}