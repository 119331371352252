import { Component, Input, OnInit } from '@angular/core';
import { Marker } from '@classes';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() progress: number = 0;
  @Input() height: 'sm' | 'md' | 'lg' = 'md';
  @Input() markers: Marker[] = []
  @Input() barBg: string = 'theme'
  public progressStyle: string[] = [];

  public get getHeight(): number {
    switch (this.height) {
      case 'sm':
        return 5;
      case 'md':
        return 10;
      case 'lg':
        return 15;
    }
  }

  ngOnInit(): void {
    this.progressStyle = [
      'height:' + this.getHeight + 'px',
    ]
  }

}
