export interface RouteSession{
    text: string;
    path:string
}

export class DashboardOverview {
    title!:string;
    description?:string;
    route?:string ;
    image!:string;

    constructor(config:any){
        if(config){
            this.title = (config.title !== undefined) ? config.title : this.title;
            this.route = (config.route !== undefined) ? config.route : this.route;
            this.image = (config.image !== undefined) ? config.image : this.image;
            this.image = (config.image !== undefined) ? config.image : this.image;
        }
    }
}

export interface PressignUrlResponse{
    object:string;
    presignedUrl:string;
    uri:string;
    willOverwrite:string;
}