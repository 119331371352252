import { HttpRequestsService } from './http-requests.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map, switchMap } from 'rxjs';
import { S3Upload } from '../classes';

@Injectable()
export class AwsRequestService {
    constructor(
        private http: HttpClient,
        private HttpRequestsService: HttpRequestsService
    ) { }

    public uploadS3(preSignedURL: string, contentType: string, file: File): Observable<any> {
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': contentType,
            'ngsw-bypass': ''
        });
        const request: HttpRequest<any> = new HttpRequest('PUT', preSignedURL, file, { headers: headers, reportProgress: true });
        return this.http.request(request);
    }

    public getS3Url(upload: S3Upload[], responseComplete: boolean = false): Observable<any> {
        const PAYLOAD = {
            files: upload.map((up: S3Upload) => {
                return {
                    path: up.path,
                    objectName: up.objectName,
                    private: up.private,
                }
            })
        }
        const S3Function = (presigned: any, idx: number): Observable<any> => {
            return this.uploadS3(presigned.presignedUrl, upload[idx].file!.type, upload[idx].file!).pipe(
                map(
                    (res: any) => {
                        if (res.type === 4) {
                            if(responseComplete){
                                return presigned;
                            } else {
                                return `https://${presigned.absoluteUrl}`;
                            }
                        } else if (res.type === 3 || res.type === 5) {
                            throw Error(res)
                        }
                        else {
                            return null;
                        }
                    },
                    (err: any) => {
                        throw Error(err)
                    }
                )
            )
        }
        return this.HttpRequestsService.requestData('POST', ':productor/customer/tools/s3File', true, PAYLOAD).pipe(
            map(
                (res: any) => {
                    return res.data.results
                },
                (err: any) => {
                    throw Error(err)
                }
            ),
            switchMap((res: any[]) => {
                const S3FunctionArray: Observable<any>[] = res.map((x, idx) => S3Function(x, idx))
                return forkJoin(S3FunctionArray);
            })
        )

    }
}
