import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent {

  /**Atributo placeholder do input */
  @Input() placeholder?: string = "Pesquise..."

  /**
* Atributo disabled do input
* @default false
**/
  @Input() disabled?: boolean = false

  /**
   * Atributo readOnly do input
   * @default false
   *  */
  @Input() readonly?: boolean = false

  /**
 * adicionar clases no input
 **/
  @Input() classes?: string

  /**
 * Limite máxmimo de caracteres no input 
 * @default 100
 * */
  @Input() maxlength?: number = 100

  /**
   * Limite mínimo de caracteres no input 
   * @default 3
   * */
  @Input() minlength?: number = 3

  /** Valor do input  */
  @Input() value?: string

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  /**Evento para quando o campo do input for alterado */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  /**Evento para quadno o campo do input desfocado */
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();

  /**Evento para quadno o campo do input focado */
  @Output() focus: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  public onInputChange() {
    this.valueChange.emit(this.value);
  }
}
