import { Component } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { IconName } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'app-toastr',
    templateUrl: './app-toastr.component.html',
    styleUrls: ['app-toastr.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('inactive', style({
                opacity: 0,
            })),
            transition('inactive => active', animate('400ms ease-out', keyframes([
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
                    opacity: 0,
                }),
                style({
                    transform: 'skewX(20deg)',
                    opacity: 1,
                }),
                style({
                    transform: 'skewX(-5deg)',
                    opacity: 1,
                }),
                style({
                    transform: 'none',
                    opacity: 1,
                }),
            ]))),
            transition('active => removed', animate('400ms ease-out', keyframes([
                style({
                    opacity: 1,
                }),
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(30deg)',
                    opacity: 0,
                }),
            ]))),
        ]),
    ],
    preserveWhitespaces: false,
})
export class AppToastr extends Toast {
    public icon: any = 'bell';
    constructor(
        protected override toastrService: ToastrService,
        public override toastPackage: ToastPackage,
    ) {
        super(toastrService, toastPackage);
        switch (toastPackage.toastType) {
            case 'toast-success':
                this.icon = 'check';
                break;
            case 'toast-warning':
                this.icon = 'triangle-exclamation';
                break;
            case 'toast-info':
                this.icon = 'circle-info';
                break;
            case 'toast-error':
                this.icon = 'circle-exclamation';
                break;
        }
    }

    action(event: Event) {
        event.stopPropagation();
        this.toastPackage.triggerAction();
        return false;
    }
}
