import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppMenuService } from '../../services/app-menus.service'
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { AppService, CommonService, HttpRequestsService } from '../../services';
import { AppSettings } from '../../services/app-settings.service';
import { Profile } from '@modules/app-common/classes';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  host: {
    class: 'app-sidebar'
  }
})
export class SidenavComponent implements OnDestroy, OnInit {
  private subscriptions: Subscription[] = []
  public carregando: boolean = false;
  menus: any[] = [];
  public profile: any = {};
  @Input() route: string = '/';
  public is_logged: boolean = false;

  constructor(
    private appMenuService: AppMenuService,
    private Router: Router,
    private AppService: AppService,
    public AppSettings: AppSettings,
    private CommonService: CommonService,
    private HttpRequestsService: HttpRequestsService,
    private ToastrService: ToastrService
  ) { }


  public verifyRoute(route: string): boolean {
    return this.route.indexOf(route) == 0;
  }
  public onLogout() {
    this.subscriptions.push(
      this.HttpRequestsService.requestData('POST', ':productor/customer/auth/logout/', true).subscribe({
        next: (res: any) => { }, error: (err: any) => {
          console.error(err);
        }
      })
    )
    setTimeout(() => {
      this.CommonService.delSessionStorage('user-data');
      this.CommonService.delLocalStorage('user-data');
      this.CommonService.delSessionStorage('auth-data');
      this.CommonService.delLocalStorage('auth-data');
      this.CommonService.delLocalStorage('lab-init');
			this.CommonService.delLocalStorage('lab-initialing');
      this.CommonService.delSessionStorage('path-redirect-after-login');
      this.CommonService.delLocalStorage('path-redirect-after-login');
      this.Router.navigate(['/auth']);
    }, 1000)
  }
  private getProfile() {
    this.carregando = true
    let sub: Subscription = new Subscription();
    sub = this.AppService.profile.subscribe(
      (profile) => {
        if (profile !== null) {
          this.profile = profile;
          this.carregando = false;
          sub.unsubscribe();
        }
      }
    )
  }
  public getinitials(): string {
    let initials: string = '';
    const aux = this.profile?.name?.split(' ');
    for (let index = 0; (aux) ? index < aux.length : 0; index++) {
      const element = (aux) ? aux[index] : undefined;
      initials += (element && element.length > 0) ? element.charAt(0).toUpperCase() : ''
      if (initials.length >= 2) {
        index = (aux) ? aux.length : 0;
      }
    }
    return initials;
  }

  isChildActive(menus: any) {
    var active = false;
    if (menus.length > 0) {
      for (let menu of menus) {
        if (this.Router.url === menu.path) {
          active = true;
        }
      }
    }
    return active;
  }

  handleExpandSubmenu(event: MouseEvent) {
    event.preventDefault();
    var targetMenuLinkElm = event.target as HTMLElement;
    var targetMenuItemElm = targetMenuLinkElm.closest('.menu-item');

    if (targetMenuItemElm) {
      var targetSubmenu = targetMenuItemElm.querySelector('.menu-submenu');
      var targetSubmenuElm = targetSubmenu as HTMLElement;
      var hassubMenuLinkList = [].slice.call(document.querySelectorAll('.app-sidebar .menu > .menu-item.has-sub > .menu-link'));

      if (hassubMenuLinkList) {
        hassubMenuLinkList.map(function (menuLink) {
          var menuLinkElm = menuLink as HTMLElement;
          var menuItemElm = menuLinkElm.closest('.menu-item');

          if (menuItemElm) {
            var submenu = menuItemElm.querySelector('.menu-submenu');
            var submenuElm = submenu as HTMLElement;

            if (submenuElm != targetSubmenuElm) {
              submenuElm.style.display = 'none';
              menuItemElm?.classList.remove('expand');
            }
          }
        });
      }

      if (targetMenuItemElm?.classList.contains('expand') || (targetMenuItemElm?.classList.contains('active') && !targetSubmenuElm.style.display)) {
        targetMenuItemElm?.classList.remove('expand');
        targetSubmenuElm.style.display = 'none';
      } else {
        targetMenuItemElm?.classList.add('expand');
        targetSubmenuElm.style.display = 'block';
      }
    }
  }
  public toggleSidenav(event: MouseEvent, className: string) {
    event.preventDefault();

    var elm = document.getElementById('app');
    if (elm) {
      elm.classList.toggle(className);
    }
  }
  public toggleProfileSide(event: MouseEvent, className: string) {
    event.preventDefault();
    var elm = document.querySelector('.app-sidebar-content');
    if (elm) {
      elm.classList.toggle(className);
    }
  }

  ngOnInit() {
    let auth = this.CommonService.getLocalStorage('auth-data');
    if (!auth) auth = this.CommonService.getSessionStorage('auth-data');
    this.is_logged = !!auth
    if (this.is_logged) {
      this.appMenuService.getAppMenus().subscribe(
        (menu) => {
          this.menus = menu;
        }
      )
      this.getProfile();
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
