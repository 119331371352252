import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, delay, filter, map, Observable, of, switchMap, take } from 'rxjs';
import { CommonService } from './common.service';
import { environment } from '@environments/environment';
import { AuthData, UserData } from '@classes';
import { RollbarService } from './rollbar.service';
import * as Rollbar from 'rollbar';

@Injectable()
export class HttpRequestsService {

    private productor: string = environment.productorTable;
    private isSendToast: boolean = false;
    private isGettingToken: BehaviorSubject<boolean> = new BehaviorSubject(false) //? Token está sendo buscado
    constructor(
        private http: HttpClient,
        private common: CommonService,
        private route: Router,
        private ToastrService: ToastrService,
        @Inject(RollbarService) private Rollbar: Rollbar
    ) { }

    public refreshToken(authData: AuthData): Observable<any> {
        const user: UserData = this.common.getSessionStorage('user-data') ? this.common.getSessionStorage('user-data') : this.common.getLocalStorage('user-data');
        const device_id: string = this.common.getDeviceId();
        if (user) {
            let urlApi = `${environment.apiUrl}${environment.productor}/customer/auth/refresh`;

            const httpHeaders = new HttpHeaders({
                'Content-Type': 'application/json',
                'x-api-key': environment.apiKey
            });
            const options: any = { headers: httpHeaders, observe: 'response' };
            const body: any = {
                user: user.email,
                refreshToken: authData.refreshToken,
                // deviceId: this.common.getLocalStorage('device-id')
                deviceId: device_id
            };
            return this.http.post(urlApi, body, options)
        } else {
            this.route.navigate(['/auth']);
            return of(false);
        }
    }
    private getToken(auth: AuthData, path?: string): Observable<any> {
        // verificar se o token ja esta sendo pego, para não fazer a requisição mais de 1 vez
        if (!this.isGettingToken.value) {
            this.isGettingToken.next(true);
            return this.refreshToken(auth).pipe(
                map(
                    (resp: any) => {
                        let res = resp.body;
                        // tudo ok com o refresh, grava novos dados de autenticacao
                        console.log('Token de autenticação renovado...');
                        const nAuth: AuthData = new AuthData(res.data.tokens);
                        nAuth.refreshToken = auth.refreshToken;
                        this.common.setSessionStorage('auth-data', nAuth);
                        if (resp.status === 206) {
                            //? Refresh retornou um 206, que signifa que o usuário ainda não terminou o cadastro
                            const USERDATA_LOCAL: UserData = this.common.getLocalStorage('user-data');
                            const USERDATA_SESSION: UserData = this.common.getSessionStorage('user-data');
                            if (USERDATA_LOCAL) {
                                USERDATA_LOCAL.incompleted = true;
                                this.common.setLocalStorage('user-data', USERDATA_LOCAL);
                            } else {
                                USERDATA_SESSION.incompleted = true;
                                this.common.setSessionStorage('user-data', USERDATA_SESSION);
                            }
                        }
                        this.isGettingToken.next(false);
                        return true;
                    },
                    (err: any) => {
                        console.error(err);
                        // redireciona para area de login
                        this.route.navigate(['/auth']);
                        throw new Error('Erro ao renovar token de autenticação');
                    },
                )
            );
        } else {
            return this.isGettingToken.pipe(
                filter(res => !res),
                map((res) => {
                    return !res;
                })
            )
        }

    }

    public requestData(method: string, path?: string, needsAuthorization?: boolean, body?: any, productor?: string, observe?: string): Observable<any> {
        try {
            if (path) {
                path = path.replace(':productor', productor ?? this.productor);
            }
            const url = `${environment.apiUrl}${path ?? ''}`;
            let httpHeaders: HttpHeaders;
            if (needsAuthorization) {
                // obtem dados de autenticacao
                let auth: AuthData = new AuthData((this.common.getSessionStorage('auth-data') ? this.common.getSessionStorage('auth-data') : this.common.getLocalStorage('auth-data')));
                // verifica se token ainda é válido
                if (auth.is_valid) {
                    httpHeaders = new HttpHeaders({
                        'Content-Type': 'application/json',
                        'x-api-key': environment.apiKey,
                        Authorization: `${auth.token_type} ${auth.accessToken}`
                    });
                    const options: any = { headers: httpHeaders };
                    if (body) {
                        body.deviceId = this.common.getDeviceId();
                        options.body = body;
                    }

                    return this.http.request(method, url, options).pipe(
                        catchError((err) => {
                            this.Rollbar.error(err.error.message)
                            throw err
                        })
                    )
                } else {
                    // tenta fazer o refresh do token
                    return this.getToken(auth, path).pipe(
                        filter(res => res),
                        switchMap(() => {
                            return this.requestData(method, path, needsAuthorization, body)
                        }));
                }
            } else {
                httpHeaders = new HttpHeaders({
                    'Content-Type': 'application/json',
                    'x-api-key': environment.apiKey,
                });
                const options: any = { headers: httpHeaders };
                if (observe) {
                    options.observe = observe
                }
                if (body) {
                    options.body = body;
                }
                return this.http.request(method, url, options)
            }
        } catch (err: any) {
            console.error(err);
            this.route.navigate(['/auth']);
            return of(false);
        }
    }
}
