import { Router } from '@angular/router';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Profile, UserRank } from '@classes';

@Component({
  selector: 'app-rank-card',
  templateUrl: './rank-card.component.html',
  styleUrls: ['./rank-card.component.scss']
})

export class RankCardComponent implements OnInit {
  @ViewChild('rankRef') _rankRef!: ElementRef
  @Input() user!: UserRank
  @Input() classes: string = '';
  @Input() position: number = 0;
  @Input() disabledRoute:boolean = false
  @Input() byIndex:boolean = false

  constructor(
    private Router: Router
  ) { }

  public getinitials(): string {
		let initials: string = '';
		const aux = this.user?.nameStudent?.split(' ');
		for (let index = 0; (aux) ? index < aux.length : 0; index++) {
			const element = (aux) ? aux[index] : undefined;
			initials += (element && element.length > 0) ? element.charAt(0).toUpperCase() : ''
			if (initials.length >= 2) {
				index = (aux) ? aux.length : 0;
			}
		}
		return initials;
	}

  public goTo(){
    if(!this.disabledRoute) {
      this.Router.navigate(['/rank/', this.user.username])
    }
  }
  ngOnInit(): void {
    setTimeout(() => {
      if (this._rankRef.nativeElement) {
        this._rankRef.nativeElement.addEventListener('mousemove', (ev: any) => {
          const X = ev.layerX + 20;
          const Y = ev.layerY;

          this._rankRef.nativeElement.style.setProperty('--mouse-x', `${X}px`);
          this._rankRef.nativeElement.style.setProperty('--mouse-y', `${Y}px`);
        })
        this._rankRef.nativeElement.addEventListener('mouseenter', (ev: any) => {
          this._rankRef.nativeElement.style.setProperty('--opacity', 1);
        })
        this._rankRef.nativeElement.addEventListener('mouseleave', (ev: any) => {
          this._rankRef.nativeElement.style.setProperty('--opacity', 0);
        })
      }
    }, 1)
  }
}
