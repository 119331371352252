<div class="header-layout overflow-hidden">
    <div class="position-relative">
        <ng-container *ngIf="!AppSettings.appSidebarNone">  
            <app-sidenav class="d-block d-md-none" [route]="route"></app-sidenav>
        </ng-container>
        <ng-container *ngIf="!AppSettings.appHeaderNone">
            <app-header [route]="route"></app-header>
        </ng-container>
        <ng-container *ngIf="route.indexOf('/laboratorio') != 0 && route.indexOf('/curso/simulado') != 0 && route.indexOf('/desafio/iniciar') != 0">
            <div class="lab-redirect d-flex overflow-hidden " role="button" [ngClass]="{'active':active_lab_popup,'d-none':!show_lab_popup}" (click)="goToLab()">
                <div class="lab-text bg-warning bg-opacity-75 ps-3 pe-2 w-100">
                    <div class="d-flex flex-column justify-content-center lh-sm text-white h-100">
                        <span class="fs-13px fw-bold">Laboratorio em andamento</span>
                        <div class="limit-line-1" style="padding-right: .75rem;">
                            <small class="opacity-75">{{lab_init?.labName!}}</small>
                        </div>
                    </div>
                </div>
                <div class="icon d-flex justify-content-center align-items-center bg-warning h-100">
                    <fa-icon class="text-white fa-xl d-flex" [icon]="['fas','flask']" [fixedWidth]="true"></fa-icon>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="layout-content scrollable">
        <ng-container *ngIf="CommonService.route_session.length > 2 && !AppSettings.appHeaderSessionNone">
            <div class="routes-session container mt-3">
                <div class="d-none d-md-flex">
                    <div class="d-flex align-items-center rounded-3 overflow-hidden">
                        <ng-container *ngFor="let item of CommonService.route_session;let last = last;let index = index">
                            <ng-container *ngIf="item">
                                <a class="router-item text-decoration-none" [routerLink]="item.path" [ngClass]="{'last-link':last}" [style]="'--zdx:'+ getZIndex(index)">
                                    <div class=" d-flex align-items-center w-100 h-100"> 
                                        <span class="route-link d-block limit-line-1  px-3 h-22px"   [innerHTML]="item.text | noSanitize"></span>
                                    </div>
                                </a>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="d-flex flex-column justify-content-between content">
            <div class="w-100 ">
                <ng-content></ng-content>
            </div>
            <ng-container *ngIf="!AppSettings.appFooterNone">
                <app-footer></app-footer>
            </ng-container>
        </div>
    </div>
</div>
<app-privacidade-card></app-privacidade-card>
