<div class="challenge-row d-flex align-items-center shadow-sm rounded-3 px-4 overflow-hidden bg-altern w-100" #trailRef [ngClass]="{'locked bg-opacity-75':trial.locked,'bg-opacity-100':!trial.locked}" (click)="goTo()">
    <div class="w-100" style="z-index: 10;">
        <div class="row lab-details align-items-center h-100">
            <div class="col-10">
                <div class="d-flex gap-2">
                    <div class="challenge-image d-flex justify-content-center align-items-center rounded-4 overflow-hidden py-1" >
                        <div class="img" [style]="'background-image:url('+trial.imgs?.logoMini+')'"></div>
                        <ng-container *ngIf="trial.locked">
                            <div class="locked-icon d-flex justify-content-center align-items-center rounded-circle w-50px h-50px">
                                <fa-icon class="text-inverse fa-2x" [icon]="['fas','lock']"></fa-icon>
                            </div>
                        </ng-container>
                    </div>
                    <div class="challenge-details d-flex align-items-center py-1 " >
                        <span class="challenge-title lh-sm fw-bold fs-15px fw-400" [ngClass]="{'text-gray-400':trial.locked}" [innerHTML]="trial.title | noSanitize"></span>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="d-flex justify-content-center align-items-center gap-1 h-100" >
                    <ng-container *ngIf="!trial.locked">
                        <div class="w-100">
                            <div class="text-center">
                                <span class="fw-semibold">INICIAR TRILHA</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="trial.locked">
                        <div class="d-flex align-items-center gap-1">
                            <fa-icon class="text-gray-400 text-opacity-80" [icon]="['fas','lock']"></fa-icon>
                            <span class="text-opacity-70 text-gray-400 fw-400">Bloqueado</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>