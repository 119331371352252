<div class="d-flex flex-column w-100 rounded-3 overflow-hidden">
    <div [class]="'module-card d-flex align-items-center shadow-sm rounded-3 px-4 w-100 overflow-hidden bg-altern '+classes" #moduleRef (click)="collapse = !collapse">
        <div class="w-100">
            <div class="row module-details align-items-center h-100">
                <div class="col-8">
                    <div class="d-flex align-items-center gap-3">
                        <!-- <div class="module-image position-relative">
                            <ng-container *ngIf="module.imgs![0]?.logoMini">
                                <div class="img w-100 h-100" [style]="'background-image:url('+module.imgs![0].logoMini+')'"></div>
                            </ng-container>
                            <div class="locked-icon d-flex justify-content-center align-items-center rounded-circle w-50px h-50px">
                                <fa-icon class="text-white fa-2x" [icon]="['fas','lock']"></fa-icon>
                            </div>
                        </div> -->
                        <div class="module-title py-2">
                            <span class="d-block fs-15px fw-bold lh-sm" [innerHTML]="module.name | noSanitize"></span>
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <div class="module-hour py-2 h-100">
                        <div class="d-flex align-items-center gap-1 lh-1 h-100">
                            <div class="d-flex justify-content-end w-20px">
                                <span class="text-inverse text-opacity-60">{{module.classes!.length}}</span>
                            </div>
                            <fa-icon class="fa-sm d-flex text-inverse text-opacity-50"  [icon]="['fas','film']" [fixedWidth]="true"></fa-icon>
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="module-hour d-flex justify-content-center py-2 h-100">
                        <div class="d-flex align-items-center gap-1 lh-1 h-100">
                            <ng-container *ngIf="total_ms">
                                <fa-icon class="fa-sm d-flex text-inverse text-opacity-50"  [icon]="['fas','clock']" [fixedWidth]="true"></fa-icon>
                                <span class="text-inverse text-opacity-60" >{{total_hr}}</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <div class="d-flex justify-content-center w-100 ms-1">
                        <div class="progress-module d-flex justify-content-center align-items-center rounded-circle opacity-0" [ngClass]="{'opacity-0':!show_progress}">
                            <div class="counter d-flex justify-content-center align-items-center bg-gray-900 rounded-circle">
                                <small class="text-white" [innerHTML]="counterPorcent()"></small >
                            </div>
                            <svg class="circle-svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="46px" height="46px" [style]="'--st:'+(130 * counterPorcentNumber() - 130)">
                                <circle cx="24" cy="24" r="20" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ps-3 bg-altern-glow-20 bg-opacity-40 mb-2" [ngbCollapse]="collapse">
        <div class="d-flex flex-column py-3 px-1 w-100">
            <div class="px-3">
                <div class="row mb-1">
                    <div class="col-7">
                        <div class="d-flex justify-content-start ">
                            <small class="opacity-50">Nome</small>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="d-flex justify-content-center">
                            <small class="opacity-50">Duração</small>
                        </div>
                    </div>
                    <div class="col-1">
                        <div class="d-flex justify-content-center">
                            <small class="opacity-50">Arquivos</small>
                        </div>
                    </div>
                    <div class="col-1">
                        <div class="d-flex justify-content-center ">
                            <small class="opacity-50">Tipo</small>
                        </div>
                    </div>
                    <div class="col-1">
                        <div class="d-flex justify-content-center ">
                            <small class="opacity-50">Situação</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column gap-2">
                <ng-container *ngFor="let item of module?.classes;let last = last">
                    <app-classe-card [classe]="item"></app-classe-card>
                    <ng-container *ngIf="!last">
                        <!-- <div class="transluced-border-top w-100 h-1px"></div> -->
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>