<div class="w-100 border-0 rounded-2 lesson overflow-hidden">
    <div class="border-0 p-0 bg-altern" role="button" [ngClass]="{'bg-gray-900 bg-opacity-50':!module.statusCode}" (click)="moduleCollapse = !moduleCollapse">
        <div class="d-flex justify-content-between lesson-header p-3">
            <div>
                <div class="d-flex align-items-center gap-3">
                    <div class="">
                        <div class="progress-module d-flex justify-content-center align-items-center rounded-circle">
                            <div class="counter d-flex justify-content-center align-items-center bg-gray-900 rounded-circle">
                                <ng-container *ngIf="module.statusCode">
                                    <small class="text-white" [innerHTML]="counterPorcent()"></small >
                                </ng-container>
                                <ng-container *ngIf="!module.statusCode">
                                    <fa-icon class="text-gray-400" [icon]="['fas','lock']"></fa-icon>
                                </ng-container>
                            </div>
                            <svg class="circle-svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="46px" height="46px" [style]="'--st:'+(130 * counterPorcentNumber() - 130)">
                                <circle cx="24" cy="24" r="20" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                    <div class="">
                        <div class="d-flex flex-column ">
                            <span class="text-inverse">{{module.name}}</span>
                            <div class="d-flex">
                                <div>
                                    <small>{{module.classes!.length}} recurso{{module.classes!.length > 1 ? 's' : ''}}</small>
                                </div>
                                <ng-container *ngIf="total_class_ms">
                                    <div class="text-muted mx-2">
                                        <small>~</small>
                                    </div>
                                    <div>
                                        <small>{{total_class_time}}</small>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center">
                <div class="">
                    <fa-icon classe="text-inverse fa-2x"  [icon]="['fas',moduleCollapse ? 'chevron-down' : 'chevron-up']"></fa-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="p-0" >
        <div #collapse="ngbCollapse" [(ngbCollapse)]="moduleCollapse">
            <div class="py-3 ps-4 ms-2 pe-3 transluced-border-top">
                 <div class="classes-container d-flex flex-column gap-4">
                    <ng-container *ngIf="module?.classes">
                        <ng-container *ngFor="let item of module.classes;let index = index">
                            <app-lesson-class [classe]="item" [module]="module" [index]="index" [classActive]="activeId" (clickClass)="onClickClass($event)" (clickWatched)="onClickWatched($event)"></app-lesson-class>
                        </ng-container>
                    </ng-container>
                 </div>
            </div>
        </div>
    </div>
</div>
