import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
/*Modules */
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NG_SCROLLBAR_OPTIONS, NgScrollbarModule } from 'ngx-scrollbar';
import { NgxMasonryModule } from 'ngx-masonry';
import { NgbDatepickerModule, NgbTimepickerModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ColorSketchModule } from 'ngx-color/sketch';
import { CountdownModule } from 'ngx-countdown';
import { TagInputModule } from 'ngx-chips';
import { QuillModule } from 'ngx-quill';
import { IconsModule } from '../icons/icons.module'

const modules = [NgbModule, FormsModule, IconsModule, ReactiveFormsModule, HttpClientModule, JsonPipe, NgScrollbarModule, NgxMasonryModule, NgbDatepickerModule, NgbTimepickerModule, NgbTypeaheadModule, NgxMaskDirective, NgxMaskPipe, NgSelectModule, NgChartsModule, NgApexchartsModule, HighlightModule, FullCalendarModule, ColorSketchModule, CountdownModule, TagInputModule,]

import * as Components from './components';
import * as Pipes from './pipes';
import * as Services from './services';
import * as Guards from './guards';
import * as Layouts from './layouts';

@NgModule({
  declarations: [
    ...Components.component,
    ...Pipes.pipes,
    ...Layouts.layouts
  ],
  imports: [
    CommonModule,
    RouterModule,
    QuillModule.forRoot(),
    ...modules,

  ],
  exports: [
    ...Components.component,
    ...Pipes.pipes,
    ...Layouts.layouts,
    ...modules,
  ],
  providers: [
    provideNgxMask(),
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          css: () => import('highlight.js/lib/languages/css'),
          xml: () => import('highlight.js/lib/languages/xml')
        }
      }
    },
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        visibility: 'hover'
      }
    },
  ]

})
export class AppCommonModule {
  static forRoot(): ModuleWithProviders<AppCommonModule> {
    return {
      ngModule: AppCommonModule,
      providers: [
        ...Services.services,
        ...Guards.guards,
        { provide: 'window', useValue: window },
      ],
    };
  }
}