export interface ImageAttachmentQuote {
    objectKey:string,
    absoluteUrl:string,
    file:File
}

export class SupportFaq {
    title?:string;
    id?:string;
    alias?:string;
    text?:string;
    attachments?:SupportFaqAttachment[]
    tag?:SupportFaqTag
    collapse?:boolean = true
    isGetAttachemnts?:boolean = false

    constructor(config?:any){
        if(config){
            this.title = (config.title !== undefined) ? config.title : this.title;
            this.alias = (config.alias !== undefined) ? config.alias : this.alias;
            this.id = (config.id !== undefined) ? config.id : this.id;
            this.text = (config.text !== undefined) ? config.text : this.text;
            this.attachments = (config.attachments !== undefined) ? config.attachments?.map((x:any)=> new SupportFaqAttachment(x)) : this.attachments;
            this.tag = (config.tag !== undefined) ? config.tag : this.tag;
        }
    }
}

export class SupportFaqAttachment{
    objectKey?:string
    objectUri:string
    objectUrl?:string
    objectDownload?:string
    object:any

    constructor(objectUri:string){
        this.objectUri = objectUri;
    }
}

export interface SupportFaqTag {
    color:string,
    name:string
}