import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@modules/app-common/guards';
const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('../modules/home/home-routing.module').then((m) => m.HomeRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('../modules/auth/auth-routing.module').then((m) => m.AuthRoutingModule),
    canActivate: []
  },
  {
    path: 'perfil',
    loadChildren: () => import('../modules/profile/profile-routing.module').then((m) => m.ProfileRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'curso',
    loadChildren: () => import('../modules/presentation/presentation-routing.module').then((m) => m.PresentationRoutingModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'certified',
  //   loadChildren: () => import('../modules/certified/certified-routing.module').then((m) => m.CertifiedRoutingModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'desafio',
    loadChildren: () => import('../modules/challenge/challenge-routing.module').then((m) => m.ChallengeRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'laboratorio',
    loadChildren: () => import('../modules/labs/labs-routing.module').then((m) => m.LabsRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'suporte',
    loadChildren: () => import('../modules/support/support-routing.module').then((m) => m.SupportRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trilha',
    loadChildren: () => import('../modules/trail/trail-routing.module').then((m) => m.TrailRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rank',
    loadChildren: () => import('../modules/rank/rank-routing.module').then((m) => m.RankRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'simulado',
    loadChildren: () => import('../modules/practice/practice-routing.module').then((m) => m.PracticeRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule],
  declarations: []
})


export class AppRoutingModule {
}
