import { CommonService } from './common.service';
import { Injectable, Inject, InjectionToken } from '@angular/core';
import { environment } from '@environments/environment';

import * as Rollbar from 'rollbar'

let rollbar_config: any = environment.rollbar

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler {
  constructor(
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}
  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbar_config);
}

