import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Course } from '@classes';

@Component({
  selector: 'app-course-card-row',
  templateUrl: './course-card-row.component.html',
  styleUrls: ['./course-card-row.component.scss']
})
export class CourseCardRowComponent implements OnInit{

  @ViewChild('courseRef') __courseRef!: ElementRef
  @Input() course!: Course;

  constructor(
    private Router: Router
  ) { }

  public goTo() {
    if (!this.course.locked) {
      this.Router.navigate([`/curso/video/mentoria/${this.course.alias}`])
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.__courseRef.nativeElement) {
        this.__courseRef.nativeElement.addEventListener('mousemove', (ev: any) => {
          const X = ev.layerX + 20;
          const Y = ev.layerY;

          this.__courseRef.nativeElement.style.setProperty('--mouse-x', `${X}px`);
          this.__courseRef.nativeElement.style.setProperty('--mouse-y', `${Y}px`);
        })
        this.__courseRef.nativeElement.addEventListener('mouseenter', (ev: any) => {
          this.__courseRef.nativeElement.style.setProperty('--opacity', 1);
        })
        this.__courseRef.nativeElement.addEventListener('mouseleave', (ev: any) => {
          this.__courseRef.nativeElement.style.setProperty('--opacity', 0);
        })
      }
    }, 1)
  }
}
