<div class="d-flex">
    <ng-container *ngFor="let item of [].constructor(int_rate)">
        <div class="rate-icon text-center">
            <fa-icon [class]="'text-'+ color+' fa-'+size" [icon]="['fas',icon]"></fa-icon>
        </div>
    </ng-container>
    <ng-container *ngIf="decimal_rate">
        <div class="rate-icon text-center" [style]="'--rev:'+(1 - decimal_rate)+'rem'">
            <fa-icon [class]="'text-'+ color+' fa-'+size" [icon]="['fas',icon]" ></fa-icon>
        </div>
    </ng-container>
</div>