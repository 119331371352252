import * as moment from 'moment';
import { CourseImage } from './course';
import { overrides } from 'chart.js/dist/core/core.defaults';

export class PracticeConfig {
    timetoFinish?: number = 0
    domains?: string[]
    name?: string
    level?: string
    percentageForApproval?: string
    courseId?: string
    qtdQuestions?: string
    dateIncluded?: string
    description?: string
    changedIn?: string
    instructions?: string
    constructor(config?: any) {
        if (config) {
            this.timetoFinish = (config.timetoFinish !== undefined) ? (config.timetoFinish * 60 * 1000) : this.timetoFinish;
            this.domains = (config.domains !== undefined) ? config.domains : this.domains;
            this.name = (config.name !== undefined) ? config.name : this.name;
            this.level = (config.level !== undefined) ? config.level : this.level;
            this.percentageForApproval = (config.percentageForApproval !== undefined) ? config.percentageForApproval : this.percentageForApproval;
            this.courseId = (config.courseId !== undefined) ? config.courseId : this.courseId;
            this.qtdQuestions = (config.qtdQuestions !== undefined) ? config.qtdQuestions : this.qtdQuestions;
            this.description = (config.description !== undefined) ? config.description : this.description;
            this.instructions = (config.instructions !== undefined) ? config.instructions : this.instructions;
            this.changedIn = (config.changedIn !== undefined) ? config.changedIn : this.changedIn;
        }
    }
}
export class Practice {
    questions?: PracticeQuestion[] = []
    dateIncluded?: moment.Moment
    name?: string
    instructions?: string = '';
    timeOut?: number = 0
    timeResult?: number = 0
    level?: string
    percentageForApproval?: number = 0
    codeStatus?: number = 1
    numberRight?: number = 0
    numberUnanswered?: number = 0
    numberWrong?: number = 0
    imgs?: CourseImage[] = []
    courseId?: string
    courseTitle?: string
    courseAlias?: string
    qtdQuestions?: number
    description?: string
    approved?: number = 0
    statusCode?: number = 0
    timePassed?: number = 0
    type?: 'started' | 'finished' | 'stopped' | 'ongoing'
    id?: string
    configId?: string
    alias?: string
    lastAnswered?: number = 0
    timetoFinish?: number = 0
    domains?: string[]
    title?: string
    order?: string
    hided?: boolean = false;
    ended: boolean = false;
    isGetId: boolean = false;
    owned?: number
    totalQuestions?: number = 0

    constructor(config?: any) {
        if (config) {
            this.questions = (config.questions !== undefined) ? config.questions.map((x: any) => new PracticeQuestion(x)) : this.questions;
            this.dateIncluded = (config.dateIncluded !== undefined) ? moment(config.dateIncluded).locale('pt-br') : this.dateIncluded;
            this.name = (config.name !== undefined) ? config.name : this.name;
            this.instructions = (config.instructions !== undefined) ? config.instructions : this.instructions;
            this.timeOut = (config.timeOut !== undefined) ? (config.timeOut * 60 * 1000) : this.timeOut;
            this.timeResult = (config.timeOut !== undefined && config.timePassed !== undefined) ? (this.timeOut! - this.timePassed!) : this.timeResult;
            this.level = (config.level !== undefined) ? config.level : this.level;
            this.percentageForApproval = (config.percentageForApproval !== undefined) ? config.percentageForApproval : this.percentageForApproval;
            this.codeStatus = (config.codeStatus !== undefined) ? config.codeStatus : this.codeStatus;
            this.numberRight = (config.numberRight !== undefined) ? config.numberRight : this.numberRight;
            this.numberUnanswered = (config.numberUnanswered !== undefined) ? config.numberUnanswered : this.numberUnanswered;
            this.numberWrong = (config.numberWrong !== undefined) ? config.numberWrong : this.numberWrong;
            this.imgs = (config.imgs !== undefined) ? config.imgs : this.imgs;
            this.courseId = (config.courseId !== undefined) ? config.courseId : this.courseId;
            this.courseTitle = (config.courseTitle !== undefined) ? config.courseTitle : this.courseTitle;
            this.courseAlias = (config.courseAlias !== undefined) ? config.courseAlias : this.courseAlias;
            this.qtdQuestions = (config.qtdQuestions !== undefined) ? config.qtdQuestions : this.questions?.length;
            this.approved = (config.approved !== undefined) ? config.approved : this.approved;
            this.statusCode = (config.statusCode !== undefined) ? config.statusCode : this.statusCode;
            this.timePassed = (config.timePassed !== undefined) ? (config.timePassed * 1000) : this.timePassed;
            this.description = (config.description !== undefined) ? config.description : this.description;
            this.type = (config.type !== undefined) ? config.type : this.type;
            this.id = (config.id !== undefined) ? config.id : this.id;
            this.configId = (config.configId !== undefined) ? config.configId : this.configId;
            this.alias = (config.alias !== undefined) ? config.alias : this.alias;
            this.lastAnswered = (config.lastAnswered !== undefined) ? config.lastAnswered : this.lastAnswered;
            this.timetoFinish = (config.timetoFinish !== undefined) ? (config.timetoFinish * 60 * 1000) : this.timetoFinish;
            this.domains = (config.domains !== undefined) ? config.domains : this.domains;
            this.title = (config.title !== undefined) ? config.title : this.title;
            this.hided = (config.hide !== undefined) ? !!config.hide : this.hided;
            this.owned = (config.owned !== undefined) ? config.owned : this.owned
            this.order = (config.order !== undefined) ? config.order : this.order
            this.ended = (config.ended !== undefined) ? config.ended : this.ended
            this.totalQuestions = (config.totalQuestions !== undefined) ? config.totalQuestions : this.totalQuestions
            if (this.totalQuestions) {
                this.instructions += `<br>Hoje nosso banco de dados conta com <b>${this.totalQuestions}</b> perguntas, que são visualizadas no simulado de forma randômica.`
            }
        }
    }
    public get locked(): boolean {
        if (this.owned !== undefined) {
            return !this.statusCode || !this.owned
        } else {
            return !this.statusCode
        }
    }
    public get hide(): boolean {
        if (this.hided) {
            return this.hided;
        } else {
            if (this.ended) {
                return !this.statusCode || !this.owned;
            } else {
                return this.ended;
            }
        }
    }
}
export interface PracticeAtributesAgrouping {
    name: string;
    order?: string[]
    attribute: PracticeAtt
}

export class PracticeQuestion {
    incomplete: boolean = true;
    review: boolean = false;
    multiple: boolean = false;
    collapse: boolean = false;
    correctAnswers: number = 1;
    right?: number;
    domainName?: string;
    question?: string;
    id?: string;
    description?: string;
    domainId?: string;
    answers?: string[] = [];
    response?: any;
    correct?: PracticeQuestionCorrection[] = [];
    incorrect?: PracticeQuestionCorrection[] = [];
    constructor(config?: any) {
        if (config) {
            this.incomplete = (config.incomplete !== undefined) ? !!config.incomplete : this.incomplete;
            this.multiple = (config.multiple !== undefined) ? !!config.multiple : this.multiple;
            this.correctAnswers = (config.correctAnswers !== undefined) ? config.correctAnswers : this.correctAnswers;
            this.right = (config.right !== undefined) ? config.right : this.right;
            this.domainName = (config.domainName !== undefined) ? config.domainName : this.domainName;
            this.question = (config.question !== undefined) ? config.question : this.question;
            this.id = (config.id !== undefined) ? config.id : this.id;
            this.description = (config.description !== undefined) ? config.description : this.description;
            this.domainId = (config.domainId !== undefined) ? config.domainId : this.domainId;
            this.answers = (config.answers !== undefined) ? config.answers : this.answers;
            this.response = (config.response !== undefined && config.response.length) ? this.reponseFortatted(config.response) : (this.multiple ? this.answers?.map(x => false) : undefined);
            this.review = (config.review !== undefined) ? !!config.review : this.review;
            this.correct = (config.correct !== undefined) ? config.correct : this.correct;
            this.incorrect = (config.incorrect !== undefined) ? config.incorrect : this.incorrect;
            if (this.incomplete) {
                this.right = undefined;
            }
        }
    }

    private reponseFortatted(resp: string[]): any {
        if (this.multiple) {
            let returnValue: boolean[] = [];
            this.answers?.forEach((x: string) => {
                returnValue.push(resp.includes(x));
            })
            return returnValue;
        } else {
            return this.answers!.indexOf(resp[0])! + 1;
        }
    }
}

export interface PracticeQuestionCorrection {
    answer: string;
    description: string;
}

export type PracticeAtt = keyof Practice;
export type PracticeQuestionAtt = keyof PracticeQuestion;

export class PracticeAgrouping {
    title!: string;
    lockedCount: number = 0;
    practices: Practice[] = []
    take: number = 10
    takePractice: number = 10
    totalPractices?: PracticeTotalsOptions
    loadingPractice: boolean = false
    constructor(config?: any) {
        if (config) {
            this.title = (config.title !== undefined) ? config.title : this.title
            this.lockedCount = (config.lockedCount !== undefined) ? config.lockedCount : this.lockedCount
            this.practices = (config.practices !== undefined) ? config.practices : this.practices
        }
    }
    public load() {
        this.loadingPractice = true;
        setTimeout(() => {
            this.takePractice += this.take;
            this.loadingPractice = false;
        }, Math.ceil(Math.random() * 3000))
    }
    public get practiceLoaded(): Practice[] {
        return this.practices.slice(0, this.takePractice);
    }
    public get labsTotal(): PracticeTotalsOptions {
        if (this.totalPractices) {
            return this.totalPractices
        } else {
            let labsTotal: PracticeTotalsOptions = {
                total: this.practices.length,
                totalActive: 0
            }
            this.practices.forEach((practice) => {
                if (!practice.locked) {
                    labsTotal.totalActive++
                }
            })
            return labsTotal;
        }
    }
}
export class PracticeFilterOptions {
    title?: string
    keyValue?: PracticeAtt
    values?: PracticeFilterOptionsValues[]

    constructor(config?: any) {
        if (config) {
            this.title = (config !== undefined) ? config.title : this.title;
            this.keyValue = (config !== undefined) ? config.keyValue : this.keyValue;
            this.values = (config !== undefined) ? config.values : this.values;
        }
    }

    public verify(practice: Practice): boolean {
        /**
         ** Verificar se o simulado informado passa por esse opção filtro
         */
        const VALUES_ACTIVE = this.values?.filter((v) => v.active);
        if (VALUES_ACTIVE!.length > 0) {
            const PRACTICE_ITEM = practice[this.keyValue!];
            VALUES_ACTIVE?.find(v => {
                return v.identify == PRACTICE_ITEM
            })
            return !!(VALUES_ACTIVE?.find(v => v.identify == PRACTICE_ITEM))
        } else {
            return true;
        }
    }
}

export interface PracticeFilterOptionsValues {
    name: string,
    identify: any,
    active: boolean
}

export class PracticeFilter {
    options: PracticeFilterOptions[] = []
    constructor(config?: any) {
        if (config) {
            this.options = (config.options !== undefined) ? config.options.map((x: any) => new PracticeFilterOptions(x)) : this.options
        }
    }
    public get isActive(): boolean {
        let active = false;
        this.options.forEach((opt) => {
            opt.values?.forEach((v) => {
                if (v.active) {
                    active = true;
                }
            })
        })
        return active;
    }

    public clearFilter() {
        this.options.forEach((opt) => {
            opt.values?.forEach((v) => {
                v.active = false;
            })
        })
    }

}
export interface PracticeTotalsOptions {
    total: number,
    totalActive: number,
}

export class PracticeQuestionFilter {
    options: PracticeQuestionFilterOptions[] = []
    constructor(config?: any) {
        if (config) {
            this.options = (config.options !== undefined) ? config.options.map((x: any) => new PracticeQuestionFilterOptions(x)) : this.options
        }
    }
    public get isActive(): boolean {
        let active = false;
        this.options.forEach((opt) => {
            opt.values?.forEach((v) => {
                if (v.active) {
                    active = true;
                }
            })
        })
        return active;
    }

    public clearFilter() {
        this.options.forEach((opt) => {
            opt.values?.forEach((v) => {
                v.active = false;
            })
        })
    }
}

export class PracticeQuestionFilterOptions {
    title?: string
    keyValue?: PracticeQuestionAtt
    values?: PracticeFilterOptionsValues[]

    constructor(config?: any) {
        if (config) {
            this.title = (config !== undefined) ? config.title : this.title;
            this.keyValue = (config !== undefined) ? config.keyValue : this.keyValue;
            this.values = (config !== undefined) ? config.values : this.values;
        }
    }

    public verify(practice: PracticeQuestion): boolean {
        /**
         ** Verificar se o simulado informado passa por esse opção filtro
         */
        const VALUES_ACTIVE = this.values?.filter((v) => v.active);
        if (VALUES_ACTIVE!.length > 0) {
            const PRACTICE_ITEM = practice[this.keyValue!];
            VALUES_ACTIVE?.find(v => {
                return v.identify == PRACTICE_ITEM
            })
            return !!(VALUES_ACTIVE?.find(v => v.identify == PRACTICE_ITEM))
        } else {
            return true;
        }
    }


}