import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppMenuService, AppService, CommonService, HttpRequestsService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { AuthData, Profile } from '@modules/app-common/classes';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	host: {
		class: 'app-header'
	}
})
export class HeaderComponent implements OnInit, OnDestroy {
	@Input() route: string = '/';
	private subscriptions: Subscription[] = [];
	public carregando: boolean = false;
	public profile: Profile = new Profile();
	public const_profile: Profile = new Profile();
	notificationData: any[] = [
		{
			icon: 'bi bi-gear text-theme',
			title: 'SETUP COMPLETO',
			time: '3 MINUTOS ATRÁS'
		}
	];
	public menus: any[] = []
	public notification: any = {
		notification: "Notificação de teste",
		bg: "danger",
		active: false,
		animation: true,
	}
	public is_logged: boolean = false;

	constructor(
		private Router: Router,
		private AppMenuService: AppMenuService,
		private CommonService: CommonService,
		private HttpRequestsService: HttpRequestsService,
		private ToastrService: ToastrService,
		private AppService: AppService
	) { }

	public verifyRoute(route: string): boolean {
		return this.route.indexOf(route) == 0;
	}
	public onLogout() {
		this.subscriptions.push(
			this.HttpRequestsService.requestData('POST', ':productor/customer/auth/logout/', true).subscribe({
				next: (res: any) => {
				}, error: (err: any) => {
					console.error(err);
				}
			})
		)
		setTimeout(() => {
			this.CommonService.delSessionStorage('user-data');
			this.CommonService.delLocalStorage('auth-data');
			this.CommonService.delSessionStorage('auth-data');
			this.CommonService.delLocalStorage('user-data');
			this.CommonService.delLocalStorage('lab-init');
			this.CommonService.delLocalStorage('lab-initialing');
			this.CommonService.delSessionStorage('path-redirect-after-login');
			this.CommonService.delLocalStorage('path-redirect-after-login');
			this.Router.navigate(['/auth']);
		}, 1000)

	}
	private getProfile() {
		this.carregando = true
		this.subscriptions.push(
			this.AppService.profile.subscribe(
			   (profile) => {
				   if (profile) {
					   this.profile = new Profile(profile);
					   this.const_profile = new Profile(profile);
					   this.carregando = false;
				   }
			   }
		   )
		)
	}
	public getinitials(): string {
		let initials: string = '';
		const aux = this.profile?.name?.split(' ');
		for (let index = 0; (aux) ? index < aux.length : 0; index++) {
			const element = (aux) ? aux[index] : undefined;
			initials += (element && element.length > 0) ? element.charAt(0).toUpperCase() : ''
			if (initials.length >= 2) {
				index = (aux) ? aux.length : 0;
			}
		}
		return initials;
	}
	public onSubmitDiscord(f: NgForm, modal: string) {
		if (f.valid) {
			this.carregando = true;
			const PAYLOAD = {
				email_discord: this.profile.email_discord,
			}
			this.subscriptions.push(
				this.HttpRequestsService.requestData('PUT', ':productor/customer/user/profile', true, PAYLOAD)
					.subscribe({
						next: (res: any) => {
							if (res) {
								this.carregando = false;
								this.closeModal(modal)
								window.open('https://verify-discord.cloudfaster.academy/')
								this.const_profile = new Profile({ ...this.profile });
								this.profile = { ...this.const_profile }
								this.AppService.profile.next(this.profile);
							}
						}, error: (err: any) => {
							if (err.status >= 400 && err.status < 500) {
								this.ToastrService.error(err.error.message, "Erro");
							} else {
								console.error(err);
								this.ToastrService.error(`Ocorre um erro ao alterar "Email do Discord" do usuário`, "Erro");
							}
							this.carregando = false;
						}
					})
			)
		}
	}
	private closeModal(id: string) {
		const MODAL = document.querySelector(`#${id}`);
		if (MODAL) {
			const CLOSE_BTN: any = MODAL.querySelector(".btn-close")
			if (CLOSE_BTN) {
				setTimeout(() => {
					CLOSE_BTN.click();
				}, 1)
			}
		}
	}
	public openModal(content: any) {
		this.CommonService.openModal(content, () => { }, { size: 'md' })
	}
	public profileConst() {
		this.profile = new Profile({ ...this.const_profile });
	}
	handleAppToggleClass(event: MouseEvent, className: string) {
		event.preventDefault();

		var elm = document.getElementById('app');
		if (elm) {
			elm.classList.toggle(className);
		}
	}
	ngOnInit(): void {
		let auth = this.CommonService.getLocalStorage('auth-data');
		if (!auth) auth = this.CommonService.getSessionStorage('auth-data');
		this.is_logged = !!auth
		if (this.is_logged) {
			this.getProfile();
			this.AppMenuService.getAppMenus().subscribe({
				next: (menus: any) => {
					this.menus = menus;
				}
			})
			if (this.notification.active) {
				const ELM = document.getElementById('app');
				if (ELM) {
					ELM.classList.add('nav-notification');
				}
			}
		}
	}
	ngOnDestroy(): void {
		this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
	}
}
