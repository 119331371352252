import { InputComponent } from './input/input.component';
import { AppToastr } from './app-toastr/app-toastr.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { NavScrollComponent } from './nav-scroll/nav-scroll.component'
import { DifficultyComponent } from './difficulty/difficulty.component'
import { RateComponent } from './rate/rate.component'
import { InputSearchComponent } from './input-search/input-search.component';
import { LessonComponent } from './lesson/lesson.component';
import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component'
import { BadgeAmountComponent } from './badge-amount/badge-amount.component';
import { BadgeComponent } from './badge/badge.component';
import { CourseCardComponent } from './course-card/course-card.component';
import { FooterComponent } from './footer/footer.component';
import { CardComponent, CardBodyComponent, CardFooterComponent, CardHeaderComponent } from './card/card.component';
import { LoadingComponent } from './loading/loading.component';
import { LabCardComponent } from './lab-card/lab-card.component';
import { PracticeCardComponent } from './practice-card/practice-card.component';
import { ModulesCardComponent } from './modules-card/modules-card.component';
import {InputSelectComponent} from './input-select/input-select.component';
import { ClasseCardComponent } from './classe-card/classe-card.component';
import { InputDateComponent } from './input-date/input-date.component';
import { ChallengeCardComponent } from './challenge-card/challenge-card.component';
import { CourseCardRowComponent} from './course-card-row/course-card-row.component';
import { TrailCardRowComponent } from './trail-card-row/trail-card-row.component';
import { LessonClassComponent } from './lesson-class/lesson-class.component';
import { RankCardComponent } from './rank-card/rank-card.component';
import { PrivacidadeCardComponent } from './privacidade-card/privacidade-card.component';

export const component = [
    InputComponent,
    AppToastr,
    ProgressBarComponent,
    NavScrollComponent,
    DifficultyComponent,
    RateComponent,
    InputSearchComponent,
    LessonComponent,
    HeaderComponent,
    SidenavComponent,
    BadgeAmountComponent,
    BadgeComponent,
    CourseCardComponent,
    FooterComponent,
    CardComponent,
    CardBodyComponent,
    CardFooterComponent,
    CardHeaderComponent,
    LoadingComponent,
    LabCardComponent,
    PracticeCardComponent,
    ModulesCardComponent,
    InputSelectComponent,
    ClasseCardComponent,
    InputDateComponent,
    ChallengeCardComponent,
    CourseCardRowComponent,
    TrailCardRowComponent,
    LessonClassComponent,
    RankCardComponent,
    PrivacidadeCardComponent
]

export * from './input/input.component';
export * from './app-toastr/app-toastr.component';
export * from './progress-bar/progress-bar.component';
export * from './nav-scroll/nav-scroll.component';
export * from './difficulty/difficulty.component';
export * from './rate/rate.component';
export * from './input-search/input-search.component';
export * from './lesson/lesson.component';
export * from './header/header.component';
export * from './sidenav/sidenav.component';
export * from './badge-amount/badge-amount.component';
export * from './course-card/course-card.component';
export * from './badge/badge.component';
export * from './footer/footer.component';
export * from './card/card.component';
export * from './loading/loading.component';
export * from './lab-card/lab-card.component';
export * from './practice-card/practice-card.component';
export * from './modules-card/modules-card.component';
export * from './input-select/input-select.component';
export * from './classe-card/classe-card.component';
export * from './input-date/input-date.component';
export * from './challenge-card/challenge-card.component';
export * from './course-card-row/course-card-row.component';
export * from './trail-card-row/trail-card-row.component';
export * from './lesson-class/lesson-class.component';
export * from './rank-card/rank-card.component';
// export * from './privacidade-card/privacidade-card.component';