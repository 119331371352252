import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  @ViewChild('circle') _circle!: ElementRef;

  @Input() color = 'theme';
  @Input() size:'sm' | 'md' | 'lg' = 'lg';

  public size_value = 22
  public spacing:number = 27
  public width:string = '7px'
  ngOnInit(): void {
    switch(this.size){
      case 'sm':
        this.size_value = 10;
        this.spacing = 12;
        this.width = '4px';
        break;
      case 'md':
        this.size_value = 15;
        this.spacing = 19;
        this.width = '5px';
        break;
      case 'lg':
        this.size_value = 22;
        this.spacing = 27;
        this.width = '7px';
        break;
    }
    setTimeout(()=>{
      this._circle.nativeElement.setAttribute('r',this.size_value);
    },10)
  }
}
