import { MockService } from './../../services/mock.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CourseClasse, CourseModule } from '@classes';

@Component({
  selector: 'app-lesson-class',
  templateUrl: './lesson-class.component.html',
  styleUrls: ['./lesson-class.component.scss']
})
export class LessonClassComponent implements OnInit {
  @Input() classe!:CourseClasse
  @Input() module!:CourseModule
  @Input() index!:number
  @Input() classActive?:string

  @Output() clickClass:EventEmitter<any> = new EventEmitter<any> ();
  @Output() clickWatched:EventEmitter<any> = new EventEmitter<any> ();

  constructor(
    private MockService:MockService
  ){}

  private _block_change_whatched = false;
  public onClickClass(item: CourseClasse) {
    if (!item.locked) {
      this.clickClass.emit(item);
    }
  }
  public onClickWatched(item: any) {
    if (!item.locked && !this._block_change_whatched) {
      this.clickWatched.emit(item);
    }
  }

  public getTime(ms: number): string {
    const SEC = Math.floor(ms / 1000);
    const MIN = Math.floor(SEC / 60);
    const HR = Math.floor(MIN / 60);
    if (HR > 0) {
      return `${HR}h ${(MIN % 60) > 0 ? (MIN % 60) + 'min' : ''}`;
    }
    else if (MIN > 0) {
      return `${MIN}min`;
    } else {
      return `${SEC}s`;
    }
  }
  ngOnInit(): void {
    /**
     *TODO: REMOVER MOKING
     */
      // if(this.MockService.classe_challenge_mock_ids.includes(this.classe.id!)){ //? ID - AULA DE DESAFIO do "desafio"
      //   if(!this.module.classes[this.index-1].watched){
      //     this.classe!.statusCode = false;
      //   }
      //   if(this.classe.watched){
      //     this._block_change_whatched = true
      //   }
      // }
  }
}
